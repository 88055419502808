
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import danishLang from './locales/da_DK'
import englishLang from './locales/en_US'
import germanLang from './locales/de_DE'

Vue.use(VueI18n)

// export i18n instance
export default new VueI18n({
  locale: 'da',
  fallbackLocale: 'en',
  messages: {
      da : danishLang,
      en : englishLang,
      de : germanLang
  }
})
