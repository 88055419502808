import axios from 'axios'

export default {

    async addMembers({ group_id, members }) {
        return await axios.post('/members/add',{
            group_id : group_id,
            members: members
        })
    },

    async archiveGroup({ group_id, for_all }) {
        return await axios.put(`/groups/archive/${group_id}`, {
            archive : 1,
            all : for_all
        })
    },

    async checkExamMode() {
        return await axios.get(`/groups/exam-mode/user`)
    },

    async createGroup(name) {
        return await axios.post('/groups', {
            name : name
        })
    },

    async demoteMember(group_id, user_id) {
        return await axios.post('/members/demote',{
            group_id : group_id,
            user_id: user_id
        })
    },

    async fetchDropoutMembers({ group_id }) {
        return await axios.get(`/groups/dropouts/${group_id}`)
    },

    async fetchGroupClassNotebookLink({ group_id }) {
        return await axios.get(`/groups/classnotebook/${group_id}`)
    },

    async promoteMember(group_id, user_id) {
        return await axios.post('/members/promote',{
            group_id : group_id,
            user_id: user_id
        })
    },

    async pullGroupApps(group_id) {
        return await axios.get(`/groups/info/${group_id}`)
    },

    async pullUserGroups() {
        return await axios.get('/groups/user')
    },

    async pullDashboardWidgets({ group_id }) {
        return await axios.get(`/groups/dashboard/${group_id}`)
    },

    async pullGroupInformation(group_id) {
        return await axios.get(`/groups/information/${group_id}`)
    },

    async pullGroupMembers(group_id) {
        return await axios.get(`/members/${group_id}`)
    },

    async pullSubGroups(group_id) {
        return await axios.get(`/groups/subgroups/${group_id}`)
    },

    async removeMember(group_id, user_id){
        return await axios.delete(`/members/${group_id}/${user_id}`)
    },

    async removeSubGroupFromGroup({ group_id, sub_group_id }){
        return await axios.delete(`/groups/${group_id}/subgroup/${sub_group_id}`)
    },

    async resetUserDeadlineCompletion({ group_id }){
        return await axios.post(`/groups/${group_id}/user-completion-deadlines/reset`)
    },

    async saveApps({ group_id, apps }) {
        return await axios.put(`/groups/apps/${group_id}`, {
            apps : apps
        })
    },

    async saveClassNotebook({ group_id, link }) {
        return await axios.put(`/groups/classnotebook/${group_id}`, {
            link : link
        })
    },

    async saveDashboardWidgets({ group_id, widgets }) {
        return await axios.put(`/groups/dashboard/widgets/${group_id}`, {
            widgets : widgets
        })
    },

    /**
     * Add new group information
     * @param group_id
     * @param title
     * @param text
     * @returns {Promise<any>}
     */
    async addGroupInformation(group_id, title, text) {
        return await axios.post(`/groups/${group_id}/information`, {
            title : title,
            text : text
        })
    },

    /**
     * Update group information
     * @param group_id
     * @param group_information_id
     * @param title
     * @param text
     * @returns {Promise<any>}
     */
    async updateGroupInformation(group_id, group_information_id, title, text) {
        return await axios.put(`/groups/${group_id}/information/${group_information_id}`, {
            title : title,
            text : text
        })
    },

    /**
     * Delete group information
     * @param group_id
     * @param group_information_id
     * @returns {Promise<void>}
     */
    async removeGroupInformation(group_id, group_information_id) {
        return await axios.delete(`/groups/${group_id}/information/${group_information_id}`)
    },

    async saveGroupClassAndLanguage({ group_id, class_id, default_language_id }) {
        return await axios.put(`/groups/class-and-language/${group_id}`, {
            class_id : class_id,
            default_language_id : default_language_id
        })
    },

    async saveGroupName({ group_id, name }) {
        return await axios.put(`/groups/name/${group_id}`, {
            name : name
        })
    },

    async searchGroup({ institution_id, txt }) {
        return await axios.post('/groups/search', {
            institution_id : institution_id,
            txt : txt
        })
    },

    async saveGroupSetting({ group_id, setting, value }) {
        return await axios.put(`/groups/setting/${group_id}`, {
            setting : setting,
            value : value
        })
    },

    async unarchiveGroup(group_id) {
        return await axios.put(`/groups/archive/${group_id}`, {
            archive : 0
        })
    },

    async saveGroupInformationSequence(group_id, information_ids) {
        return await axios.put(`/groups/${group_id}/information/sequence`, {
            information_ids: information_ids
        })
    }


}
