import axios from 'axios'

export default {

    createBankItem({ data }){
        return axios.post('/bank/items', data)
    },

    getAllAdaptiveObjectTypes() {
        return axios.get(`/bank/object-types`)
    },

    getBankItemInfo({ entity_id }) {
        return axios.get(`/bank/items/${entity_id}`)
    },

    saveBankItem({ item_id, data }){
        return axios.put(`/bank/items/${item_id}`, data)
    },

    searchBank({ search_query }) {
        return axios.post('/bank/items/search', {
            search_query : search_query
        })
    },

}
