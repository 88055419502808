
/*
    VIEWS
 */
const groupSettingsApps = () => import('@/views/groups/groupsettings/groupSettingsApps')
const groupSettingsArchive = () => import('@/views/groups/groupsettings/groupSettingsArchive')
const groupSettingsAssignments = () => import('@/views/groups/groupsettings/groupSettingsAssignments')
const groupSettingsCopy = () => import('@/views/groups/groupsettings/groupSettingsCopy')
const groupSettingsExamMode = () => import('@/views/groups/groupsettings/groupSettingsExamMode')
const groupSettingGeneralSubView = () => import('@/views/groups/groupsettings/groupSettingGeneralSubView')
const groupSettingsNews = () => import('@/views/groups/groupsettings/groupSettingsNews')
const groupSettingsSubgroups = () => import('@/views/groups/groupsettings/groupSettingsSubgroups')

export default  [
    {
        path: 'apps',
        component: groupSettingsApps,
        meta: { requiresAuth: true, view : 'group-settings-apps' }
    },
    {
        path: 'archive',
        component: groupSettingsArchive,
        meta: { requiresAuth: true, view : 'group-settings-archive' }
    },
    {
        path: 'assignments',
        component: groupSettingsAssignments,
        meta: { requiresAuth: true, view : 'group-settings-assignments' }
    },
    {
        path: 'copy',
        component: groupSettingsCopy,
        meta: { requiresAuth: true, view : 'group-settings-copy' }
    },
    {
        path: 'exam-mode',
        component: groupSettingsExamMode,
        meta: { requiresAuth: true, view : 'group-settings-exam' }
    },
    {
        path: 'general',
        component: groupSettingGeneralSubView,
        meta: { requiresAuth: true, view : 'group-settings-general' }
    },
    {
        path: 'news',
        component: groupSettingsNews,
        meta: { requiresAuth: true, view : 'group-settings-news' }
    },
    {
        path: 'subgroups',
        component: groupSettingsSubgroups,
        meta: { requiresAuth: true, view : 'group-settings-subgroups' }
    },
]
