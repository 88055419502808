/*
 *  SUBROUTES
*/
import groupActivityRoutes from '@/router/subroutes/groupActivityRoutes'
import groupSettingsRoutes from '@/router/subroutes/groupSettingsRoutes'
import groupStatisticsRoutes from '@/router/subroutes/groupStatisticsRoutes'

/*
 *   VIEWS
*/
const assignmentsOverviewView = () => import(/* webpackPrefetch: true */'@/views/assignments/assignmentsOverviewView')
const assignmentView = () => import(/* webpackPrefetch: true */'@/views/assignments/assignmentView')
const classNotebookView = () => import('@/views/classnotebook/classNotebookView')
const conferenceOverview = () => import('@/views/conference/conferenceOverview')
const conferencePostView = () => import('@/views/conference/conferencePostView')
const courseMaterialsView = () => import(/* webpackPrefetch: true */'@/views/courses/courseMaterialsView')
const coursesOverview = () => import(/* webpackPrefetch: true */'@/views/courses/courseOverview')
const createEditAssignmentView = () => import('@/views/assignments/createEditAssignmentView')
const createEditFlashcardCollectionView = () => import('@/views/flashcards/createEditFlashcardCollectionView')
const createEditQuestionView = () => import(/* webpackPrefetch: true */'@/views/quizzes/createEditQuestionView')
const createEditQuizView = () => import(/* webpackPrefetch: true */'@/views/quizzes/createEditQuizView')
const createEditQuestionnaireView = () => import('@/views/questionnaires/createEditQuestionnaireView')
const groupDashboardView = () => import('@/views/groups/groupDashboardView')
const flashcardsCollectionsOverviewView = () => import('@/views/flashcards/flashcardsCollectionsOverviewView')
const flashcardsCollectionView = () => import('@/views/flashcards/flashcardsCollectionView')
const filesMainView = () => import('@/views/files/filesMainView')
const geogebraOverview = () => import('@/views/geogebra/geogebraOverview')
const geogebraView = () => import('@/views/geogebra/geogebraView')
const geogebraCreateEditView = () => import('@/views/geogebra/geogebraCreateEdit')
const groupChatView = () => import('@/views/chat/groupChatView')
const groupInformation = () => import('@/views/groups/groupInformation')
const groupMembers = () => import('@/views/groups/groupMembers')
const groupSettings = () => import('@/views/groups/groupSettings')
const groupStatistics = () => import('@/views/groups/groupStatistics')
const materialView = () => import(/* webpackPrefetch: true */'@/views/materials/materialView')
const newsOverview = () => import('@/views/news/newsOverview')
const questionnaireView = () => import('@/views/questionnaires/questionnaireView')
const questionnairesOverview = () => import('@/views/questionnaires/questionnairesOverview')
const questionsOverview = () => import('@/views/quizzes/questionsOverview')
const questionsView = () => import(/* webpackPrefetch: true */'@/views/quizzes/questionView')
const quizOverview = () => import('@/views/quizzes/quizzesOverview')
const quizView = () => import(/* webpackPrefetch: true */'@/views/quizzes/quizView')
const videoConference = () => import('@/views/videoConference/videoConferenceOverview')
const videos = () => import('@/views/videos/videosOverview')
const consultationsList = () => import('@/views/consultation/consultationsList')
const webinarView = () => import('@/views/webinar/webinar')

export default [
    {
        path: 'activity',
        component: groupStatistics,
        meta: { requiresAuth: true, view : 'groups-activity-main' },
        children: groupActivityRoutes
    },
    {
        path: 'assignments',
        component: assignmentsOverviewView,
        meta: { requiresAuth: true, view : 'groups-assignments' },
    },
    {
        path: 'assignments/new',
        component: createEditAssignmentView,
        props: { assignment_id : 0 },
        meta: { requiresAuth: true, view : 'groups-assignment-create' },
    },
    {
        path: 'assignment/:assignment_id/grade/:user_id',
        component: assignmentView,
        props: { is_correcting : true },
        meta: { requiresAuth: true, view : 'groups-assignment-grade' },
    },
    {
        path: 'assignment/:assignment_id',
        component: assignmentView,
        meta: { requiresAuth: true, view : 'groups-assignment' },
    },
    {
        path: 'assignment/edit/:assignment_id',
        component: createEditAssignmentView,
        props: route => ({ assignment_id : parseInt(route.params.assignment_id) }),
        meta: { requiresAuth: true, view : 'groups-assignment-edit' },
    },
    {
        path: 'assignments/:assignment_id/duplicate',
        component: createEditAssignmentView,
        props: route => ({ assignment_id : parseInt(route.params.assignment_id), duplicate : true }),
        meta: { requiresAuth: true, view : 'groups-assignment-edit-duplicate' },
    },
    {
        path: 'chat/:channel?',
        component: groupChatView,
        meta: { requiresAuth: true, view : 'groups-chat-view' },
    },
    {
        path: 'classnotebook',
        component: classNotebookView,
        meta: { requiresAuth: true, view : 'groups-classnotebook' },
    },
    {
        path: 'conference',
        component: conferenceOverview,
        meta: { requiresAuth: true, view : 'groups-conferences' },
    },
    {
        path: 'conference/:post_id',
        component: conferencePostView,
        meta: { requiresAuth: true, view : 'groups-conference' },
    },
    {
        path: 'courses',
        component: coursesOverview,
        meta: { requiresAuth: true, view : 'groups-courses' },
    },
    {
        path: 'courses/:course_id',
        component: courseMaterialsView,
        meta: { requiresAuth: true, view : 'groups-course' },
    },
    {
        path: 'courses/:course_id/lesson/:material_id/:material_element_id?',
        component: materialView,
        meta: { requiresAuth: true, view : 'groups-material-element' }
    },
    {
        path: 'courses/:course_id/material/:material_id/:material_element_id?',
        component: materialView,
        meta: { requiresAuth: true, view : 'groups-material-element' }
    },
    {
        path: 'dashboard',
        component: groupDashboardView,
        meta: { requiresAuth: true, view : 'groups-dashboard' }
    },
    {
        path: 'flashcards/collections/edit/:collection_id',
        component: createEditFlashcardCollectionView,
        meta: { requiresAuth: true, view : 'groups-flashcard-collection-edit' },
    },
    {
        path: 'flashcards/collections/create',
        component: createEditFlashcardCollectionView,
        meta: { requiresAuth: true, view : 'groups-flashcard-collection-create' },
    },
    {
        path: 'flashcards/collections/:collection_id',
        component: flashcardsCollectionView,
        meta: { requiresAuth: true, view : 'groups-flashcard-collection-view' },
    },
    {
        path: 'flashcards/collections',
        component: flashcardsCollectionsOverviewView,
        meta: { requiresAuth: true, view : 'groups-flashcard-collections' },
    },
    {
        path: 'files/:folder_id',
        component: filesMainView,
        meta: { requiresAuth: true, view : 'groups-folder' },
    },
    {
        path: 'files',
        component: filesMainView,
        meta: { requiresAuth: true, view : 'groups-files' },
    },
    {
        path: 'geogebra',
        component: geogebraOverview,
        meta: { requiresAuth: true, view : 'groups-geogebra' }
    },
    {
        path: 'geogebra/new',
        component: geogebraCreateEditView,
        meta: { requiresAuth: true, view : 'groups-geogebra-create' }
    },
    {
        path: 'geogebra/:interactive_id',
        component: geogebraView,
        meta: { requiresAuth: true, view : 'groups-geogebra-view' }
    },
    {
        path: 'geogebra/edit/:interactive_id',
        component: geogebraCreateEditView,
        meta: { requiresAuth: true, view : 'groups-geogebra-edit' },
    },
    {
        path: 'information/:group_information_id?',
        component: groupInformation,
        meta: { requiresAuth: true, view : 'groups-information' }
    },
    {
        path: 'lessons',
        redirect: 'courses',
        meta: { requiresAuth: true },
    },
    {
        path: 'lessons/:material_id',
        component: materialView,
        meta: { requiresAuth: true, view : 'groups-material' }
    },
    {
        path: 'materials',
        redirect: 'courses',
        meta: { requiresAuth: true },
    },
    {
        path: 'materials/:material_id',
        component: materialView,
        meta: { requiresAuth: true, view : 'groups-material' }
    },
    {
        path: 'members',
        component: groupMembers,
        meta: { requiresAuth: true, view : 'groups-members' }
    },
    {
        path: 'news',
        component: newsOverview,
        meta: { requiresAuth: true, view : 'groups-news' }
    },
    {
        path: 'questionnaires',
        component: questionnairesOverview,
        meta: { requiresAuth: true, view : 'groups-questionnaires' }
    },
    {
        path: 'questionnaires/edit/:questionnaire_id',
        component: createEditQuestionnaireView,
        meta: { requiresAuth: true, view : 'groups-questionnaire-edit' }
    },
    {
        path: 'questionnaires/new',
        component: createEditQuestionnaireView,
        meta: { requiresAuth: true, view : 'groups-questionnaire-new' }
    },
    {
        path: 'questionnaires/:questionnaire_id',
        component: questionnaireView,
        meta: { requiresAuth: true, view : 'groups-questionnaire-new' }
    },
    {
        path: 'questions',
        component: questionsOverview,
        meta: { requiresAuth: true, view : 'groups-questions' }
    },
    {
        path: 'questions/new',
        component: createEditQuestionView,
        meta: { requiresAuth: true, view : 'groups-question-create' },
        props: { 'question_id' : 0 }
    },
    {
        path: 'questions/:question_id',
        component: questionsView,
        meta: { requiresAuth: true, view : 'groups-question-view' }
    },
    {
        path: 'questions/edit/:question_id/duplicate',
        component: createEditQuestionView,
        meta: { requiresAuth: true, view : 'groups-question-edit-duplicate' },
        props: { duplicate : true }
    },
    {
        path: 'questions/edit/:question_id',
        component: createEditQuestionView,
        meta: { requiresAuth: true, view : 'groups-question-edit' }
    },
    {
        path: 'quizzes',
        component: quizOverview,
        meta: { requiresAuth: true, view : 'groups-quizzes' }
    },
    {
        path: 'quizzes/edit/:quiz_id',
        component: createEditQuizView,
        meta: { requiresAuth: true, view : 'groups-quiz-edit' }
    },
    {
        path: 'quizzes/new',
        component: createEditQuizView,
        meta: { requiresAuth: true, view : 'groups-quiz-create' }
    },
    {
        path: 'quizzes/:quiz_id',
        component: quizView,
        meta: { requiresAuth: true, view : 'groups-quiz-view' }
    },
    {
        path: 'settings',
        component: groupSettings,
        meta: { requiresAuth: true, view : 'groups-settings-main' },
        children: groupSettingsRoutes
    },
    {
        path: 'statistics',
        component: groupStatistics,
        meta: { requiresAuth: true, view : 'groups-statistics-main' },
        children: groupStatisticsRoutes
    },
    {
        path: 'video-conference',
        component: videoConference,
        meta: { requiresAuth: true, view : 'groups-video-conference' }
    },
    {
        path: 'videos',
        component: videos,
        meta: { requiresAuth: true, view : 'groups-videos' }
    },
    {
        path: 'consultations',
        component: consultationsList,
        meta: { requiresAuth: true, view : 'consultations-list' }
    },
    {
        path: 'webinars',
        component: webinarView,
        meta: { requiresAuth: true, view : 'webinar-view' }
    }
]
