import Vue from 'vue'
import axios from 'axios'
import userApi from '@/api/userAPI'
import groupApi from '@/api/groupAPI'

export const userStore = {
    // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,

    state: {
        isLoggedIn: 0,
        isLoggedInAdmin: 0,
        log_in_as_actor_id: 0,
        user: {
            user_id: 0,
            firstname: '',
            lastname: '',
            token: localStorage.getItem('id_token')
        },
        user_settings: {
            language: 'da'
        },
        permissions: {},
        admin_groups: {},
        profile_user_info: {},
        queriedUsers: [],
    },


    getters: {
        userIsGroupOwner: (state, getters, rootState) => (group_id) => {
            if (state.user.is_admin === 1) {
                return true
            }
            const group_institution_id = (rootState.groupStore.info[group_id] && rootState.groupStore.info[group_id].parent_group) ? +rootState.groupStore.info[group_id].parent_group : 0 || 0
            const user_institution_id = +state.user.institution_id || 0
            if (
                group_institution_id &&
                user_institution_id &&
                (group_institution_id === user_institution_id) &&
                (state.permissions['admin_groups_classes'] === 1)
            ) {
                return true
            }
            return (state.admin_groups[group_id] === 1)
        },
        userHasPermission: (state) => (permission_name) => {
            if (state.user.is_admin === 1) {
                return true
            }
            return (state.permissions[permission_name] === 1)
        }
    },


    actions: {

        /**
         * Function for creating a new user
         */
        async createUser({ commit }, info) {
            return await userApi.createUser(info)
        },

        async clearLoggedInUserInfo({ commit }) {

            // reset user obj and logged in
            commit('CLEAR_LOGGED_IN_USER')
            commit('SET_LOGGED_IN_USER', 0)
            return 1
        },

        async createCompanyUser({ commit, state },{ firstname, lastname, email, group_id }) {
            return await userApi.createCompanyUser({ firstname, lastname, email, group_id })
        },

        /**
         * Function for getting info about a user
         */
        async getUserInfo({ state, commit }, { user_id, force_refresh = false }) {

            if (!force_refresh && state.profile_user_info[user_id] && state.profile_user_info[user_id]['user_id']) {
                return state.profile_user_info[user_id]
            } else {

                const userInfoResponse = await userApi.getUserInfo(user_id)

                let user_data = userInfoResponse.data
                commit('SET_PROFILE_USER_DATA', {
                    user_id: user_id,
                    user_data: user_data
                })

                return user_data
            }
        },

        /**
         * Function for pulling all info about a user when logging in from token
         */
        async pullLoggedInUserInfo({ commit }, token) {

            const response = await userApi.pullLoggedInUserInfo(token)
            const user_data = response.data
            if (user_data && user_data.user && parseInt(user_data.user.user_id)) {
                // token can change after some time. Ask AP for clarification if needed
                const token = localStorage.getItem('id_token')
                if (token !== user_data['token']) {
                    localStorage.setItem('id_token', user_data['token'])
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + user_data['token']
                }
                commit('UPDATE_LOGGED_IN_USER', user_data.user)
                if (user_data.log_in_as_user_id) {
                    commit('UPDATE_LOG_IN_AS_USER', { user_id: user_data.log_in_as_user_id })
                }

                commit('UPDATE_LOGGED_IN_USER_PERMISSIONS', user_data.permissions)
                commit('UPDATE_LOGGED_IN_USER_ADMIN_GROUPS', user_data.admin_groups)
                if (user_data.user_settings && user_data.user_settings.length) {
                    for (let setting of user_data.user_settings) {
                        commit('UPDATE_USER_SETTING', {
                            setting: setting.type,
                            value: setting.value
                        })
                    }
                }

                commit('SET_LOGGED_IN_USER', 1)
            }

            return response
        },

        /**
         * Function for saving a user
         */
        async removeUser({ commit }, user_id) {
            return await userApi.removeUser(user_id)
        },

        /**
         * Function for saving a user
         */
        async saveUser({ commit }, { user_id, info }) {
            return await userApi.saveUser({
                user_id,
                info
            })
        },

        /**
         * @desc Search users
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Search user data
         * @return {Promise} response
         */
        async searchUsers(vx, data) {
            const { commit } = vx
            const {
                group_id,
                only_admins = false,
                only_students = false,
                txt,
            } = data

            const response = await userApi.searchUsers({
                group_id,
                only_admins,
                only_students,
                txt,
            })

            commit('SET_QUERIED_USERS', {
                users: response.data,
            })

            return response
        },

        async setLoggedInUserInfo({ commit }, user_data) {
            commit('UPDATE_LOGGED_IN_USER', user_data.user)
            commit('UPDATE_LOGGED_IN_USER_PERMISSIONS', user_data.permissions)
            commit('UPDATE_LOGGED_IN_USER_ADMIN_GROUPS', user_data.admin_groups)
            return 1
        },

        async setUserAdminLoggedIn({ commit }, flag) {
            commit('SET_LOGGED_IN_ADMIN', flag)
            return 1
        },

        async setUserLoggedIn({ commit }, flag) {
            commit('SET_LOGGED_IN_USER', flag)
            return 1
        },

        /**
         * Function for saving a user email
         */
        async setUserEmail({ commit }, { user_id, email }) {
            return await userApi.saveUserEmail({
                user_id,
                email
            })
        },

        /**
         * Function for saving a user language
         */
        async setUserLanguage({ commit }, { user_id, language }) {
            const response = userApi.saveUserLanguage({
                user_id,
                language
            })
            commit('UPDATE_USER_SETTING', {
                setting: 'language',
                value: language
            })
            return await response
        },

        async setProfileUserData({ commit }, { user_id, user_data }) {
            commit('SET_PROFILE_USER_DATA', {
                user_id: user_id,
                user_data: user_data
            })
            return 1
        },

        /**
         * Function for saving a user language
         */
        async setUserPassword({ commit }, { password_old, password_new }) {
            return await userApi.setUserPassword({
                password_old: password_old,
                password_new: password_new
            })
        },

        async setUserProfilePicture({ commit }, { media_data }) {
            return await userApi.setUserProfilePicture({
                media_data: media_data
            })
        }

    },


    mutations: {


        CLEAR_LOGGED_IN_USER: (state) => {
            Vue.set(state, 'user', {})
            Vue.set(state, 'permissions', [])
            Vue.set(state, 'admin_groups', [])
        },

        SET_LOGGED_IN_ADMIN: (state, flag) => {
            Vue.set(state, 'isLoggedInAdmin', flag)
        },

        SET_LOGGED_IN_USER: (state, flag) => {
            Vue.set(state, 'isLoggedIn', flag)
        },

        SET_PROFILE_USER_DATA: (state, { user_id, user_data }) => {
            Vue.set(state.profile_user_info, user_id, user_data)
        },

        SET_QUERIED_USERS: (state, data) => {
            const { users } = data

            Vue.set(state, 'queriedUsers', users)
        },

        UPDATE_LOGGED_IN_USER: (state, user) => {
            Vue.set(state, 'user', user)
        },

        UPDATE_LOGGED_IN_USER_PERMISSIONS: (state, permissions) => {
            // create object to speed up checks and make it easier
            let permObj = {}
            let length = permissions.length
            for (let i = 0; i < length; i++) {
                permObj[permissions[i]] = 1
            }
            Vue.set(state, 'permissions', permObj)
        },

        UPDATE_LOGGED_IN_USER_ADMIN_GROUPS: (state, groups) => {
            // create object to speed up checks and make it easier
            let groupObj = {}
            let length = groups.length
            for (let i = 0; i < length; i++) {
                groupObj[groups[i]] = 1
            }
            Vue.set(state, 'admin_groups', groupObj)
        },


        UPDATE_LOG_IN_AS_USER: (state, { user_id }) => {
            Vue.set(state, 'log_in_as_actor_id', user_id)
        },


        UPDATE_USER_SETTING: (state, { setting, value }) => {
            Vue.set(state.user_settings, setting, value)
        },

    }
}
