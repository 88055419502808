
/*
    VIEWS
 */
const adminAdaptiveTestsCopySubView = () => import('@/views/admin/adaptive-tests/adminAdaptiveTestsCopySubView')
const adminAdaptiveTestsQuestionsSubView = () => import('@/views/admin/adaptive-tests/adminAdaptiveTestsQuestionsSubView')
const adminAdaptiveTestsEditTestsSubView = () => import('@/views/admin/adaptive-tests/adminAdaptiveTestsEditTestsSubView')
const adminAdaptiveTestsLevelsSubView = () => import('@/views/admin/adaptive-tests/adminAdaptiveTestsLevelsSubView')
const adminAdaptiveTestsSubjectsSubView = () => import('@/views/admin/adaptive-tests/adminAdaptiveTestsSubjectsSubView')
const adminAdaptiveTestsTopicsSubView = () => import('@/views/admin/adaptive-tests/adminAdaptiveTestsTopicsSubView')

export default [
    {
        path: 'copy',
        component: adminAdaptiveTestsCopySubView,
        meta: { requiresAuth: true, view : 'admin-adaptive-tests-copy-sub-view' },
    },
    {
        path: 'levels',
        component: adminAdaptiveTestsLevelsSubView,
        meta: { requiresAuth: true, view : 'admin-adaptive-tests-levels-sub-view' },
    },
    {
        path: 'questions',
        component: adminAdaptiveTestsQuestionsSubView,
        meta: { requiresAuth: true, view : 'admin-adaptive-tests-questions-sub-view' },
    },
    {
        path: 'subjects',
        component: adminAdaptiveTestsSubjectsSubView,
        meta: { requiresAuth: true, view : 'admin-adaptive-tests-subjects-sub-view' },
    },
    {
        path: 'tests/edit',
        component: adminAdaptiveTestsEditTestsSubView,
        meta: { requiresAuth: true, view : 'admin-adaptive-tests-edit-tests-sub-view' },
    },
    {
        path: 'topics',
        component: adminAdaptiveTestsTopicsSubView,
        meta: { requiresAuth: true, view : 'admin-adaptive-tests-topics-sub-view' },
    },
]
