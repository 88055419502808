import Vue from 'vue'
import materialApi from '@/api/materialAPI'
import courseAPI from '@/api/courseAPI'

export const materialStore = {
    // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,

    state: {
        materials: {},
        material_elements: {},
        latest_reference_course_id: 0
    },


    getters: {
        getLessonById: (state) => (group_id, material_id) => {
            if (!state.materials[group_id]) {
                return {}
            }
            const materialIndex = state.materials[group_id] ? state.materials[group_id].findIndex(material => {
                return parseInt(material.group_material_id) === parseInt(material_id)
            }) : -1
            return (materialIndex === -1) ? {} : state.materials[group_id][materialIndex]
        },
    },


    actions: {
        /**
         * @desc Add material to course
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Material data
         * @param {number} data.group_id
         * @param {number} data.course_id
         * @param {number} data.material_id
         * @return {Promise} response
         */
        async addMaterialToCourse(vx, data) {
            const {commit} = vx
            const {group_id, course_id, material_id, sync_state = true} = data

            const response = await materialApi.addMaterialToCourse({
                group_id: parseInt(group_id),
                course_id: parseInt(course_id),
                material_id: parseInt(material_id),
            })

            if (sync_state) {
                commit('ADD_MATERIAL_TO_COURSE', {
                    group_id,
                    course_id,
                    material_id,
                })
            }

            return response
        },

        async addNewMaterialElement({commit, state}, {group_id, material_id, adaptive_object_type_id, adaptive_object_id}) {
            const api_response = await materialApi.addNewMaterialElement({
                group_id: group_id,
                material_id: material_id,
                adaptive_object_type_id: adaptive_object_type_id,
                adaptive_object_id: adaptive_object_id
            })

            let element = api_response.data
            commit('ADD_NEW_MATERIAL_ELEMENT', {
                group_id: group_id,
                material_id: material_id,
                element: element
            })

            return element

        },

        clearMaterialStore({commit, state}, {group_id}) {
            commit('CLEAR_STORE', {
                group_id: group_id
            })
            return 1
        },

        async createMaterial({commit, state}, {group_id, title, status = 'public'}) {

            const api_response = await materialApi.createMaterial({
                group_id: group_id,
                title: title,
                status: status
            })

            const new_material = api_response.data
            commit('ADD_NEW_MATERIAL', {
                group_id: group_id,
                material: new_material
            })

            return new_material
        },

        /**
         * @desc Get group material elements
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Material data
         * @param {number} data.group_id
         * @param {number} data.force_refresh
         * @param {number} data.reference_course_id
         * @return {Promise} response
         */
        async fetchAllGroupMaterialElements(vx, data) {
            const {commit, rootState, state} = vx
            const {group_id, force_refresh, reference_course_id = 0} = data

            // Make var mutable
            let forceRefresh = !!force_refresh

            // Set reference course id if present
            if ((reference_course_id > 0) && (parseInt(reference_course_id) !== state.latest_reference_course_id)) {
                forceRefresh = true
                commit('SET_REFERENCE_COURSE_ID', {
                    reference_course_id: parseInt(reference_course_id),
                })
            }

            // Check if user is a mock student
            const isMockStudent = rootState.groupStore.isMockStudent || false

            // Check if refresh in not needed / is a mock student
            if ((!forceRefresh || isMockStudent) && state.material_elements[group_id] && state.material_elements[group_id].length) {

                // Return stored elements
                return state.material_elements[group_id]
            } else {

                // Get elements
                const response = await materialApi.fetchAllGroupMaterialElements({
                    group_id,
                    reference_course_id,
                })

                const elements = response.data

                // Update elements state
                commit('SET_GROUP_MATERIAL_ELEMENTS', {
                    group_id,
                    reference_course_id,
                    elements,
                })

                return elements
            }

        },

        async fetchGroupMaterials({commit, state}, {group_id, force_refresh, reference_course_id = 0}) {
            // check if set
            force_refresh = !!force_refresh
            if ((parseInt(reference_course_id) !== state.latest_reference_course_id)) {
                force_refresh = true
                commit('SET_REFERENCE_COURSE_ID', {
                    reference_course_id: parseInt(reference_course_id)
                })
            }

            if (!force_refresh && state.materials[group_id] && state.materials[group_id].length) {
                return state.materials[group_id]
            } else {

                const api_response = await materialApi.fetchGroupMaterials({
                    group_id: group_id,
                    reference_course_id: reference_course_id
                })

                commit('SET_GROUP_MATERIALS', {
                    group_id: group_id,
                    reference_course_id: reference_course_id,
                    materials: api_response.data
                })

                return api_response.data
            }

        },

        async fetchGroupMaterialElements({commit, state}, {group_id, material_id, reference_course_id = 0, force_refresh}) {
            // check if set
            const api_response = await materialApi.fetchGroupMaterialElements({
                material_id: material_id
            })

            commit('SET_GROUP_MATERIAL_ELEMENTS_INFO', {
                group_id: group_id,
                reference_course_id: reference_course_id,
                elements: api_response.data.elements
            })

            const materialIndex = state.materials[group_id] ? state.materials[group_id].findIndex(material => {
                return parseInt(material.group_material_id) === parseInt(material_id)
            }) : -1

            const material_data = api_response.data.data
            if (!state.materials || (materialIndex === -1)) {
                commit('ADD_NEW_MATERIAL', {
                    group_id: group_id,
                    material: material_data
                })
            } else {
                commit('SAVE_MATERIAL', {
                    material_id: material_id,
                    group_id: group_id,
                    title: material_data.title,
                    status: material_data.status
                })
            }

            return api_response
        },

        /**
         * @desc Register material as seen by user
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Material data
         * @param {number} data.group_id
         * @param {number} data.material_element_id
         * @param {number} data.adaptive_object_type_id
         * @param {number} data.adaptive_object_id
         * @return {Promise} response
         */
        async registerMaterialElementSeen(vx, data) {
            const {commit, rootState} = vx
            const {
                group_id,
                material_element_id,
                adaptive_object_type_id,
                adaptive_object_id,
            } = data

            // Check if user is a mock student
            const isMockStudent = rootState.groupStore.isMockStudent || false

            // Register as seen
            const response = await materialApi.registerMaterialElementSeen({
                adaptive_object_type_id,
                adaptive_object_id,
            }, isMockStudent)

            const activity_data = response.data

            // Update state with seen element
            commit('REGISTER_ELEMENT_SEEN', {
                group_id,
                material_element_id,
                read_date: activity_data.read_date
            })

            return response
        },

        /**
         * @desc Register material as understood by user
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Material data
         * @param {number} data.group_id
         * @param {number} data.material_element_id
         * @param {number} data.adaptive_object_type_id
         * @param {number} data.adaptive_object_id
         * @return {Promise} response
         */
        async registerMaterialElementUnderstood(vx, data) {
            const {commit, rootState} = vx
            const {
                group_id,
                material_element_id,
                adaptive_object_type_id,
                adaptive_object_id
            } = data

            // Check if user is a mock student
            const isMockStudent = rootState.groupStore.isMockStudent || false

            // Register as understood
            const response = await materialApi.registerMaterialElementUnderstood({
                adaptive_object_type_id,
                adaptive_object_id,
            }, isMockStudent)

            const activity_data = response.data

            // Update state with understood element
            commit('REGISTER_ELEMENT_UNDERSTOOD', {
                group_id: group_id,
                material_element_id: material_element_id,
                understood_date: activity_data.understood_date
            })

            return response
        },

        async removeMaterial({commit, state}, {group_id, material_id}) {

            const api_response = await materialApi.removeMaterial(material_id)
            commit('REMOVE_MATERIAL', {
                group_id: group_id,
                material_id: material_id
            })

            return api_response

        },

        /**
         * @desc Remove material from course
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Material data
         * @param {number} data.group_id
         * @param {number} data.course_id
         * @param {number} data.material_id
         * @param {boolean} [data.sync_state]
         * @return {Promise} response
         */
        async removeMaterialFromCourse(vx, data) {
            const {commit} = vx
            const {group_id, course_id, material_id, sync_state = true} = data

            const response = await courseAPI.removeMaterialFromCourse({
                course_id: parseInt(course_id),
                material_id: parseInt(material_id)
            })

            if (sync_state) {
                commit('REMOVE_MATERIAL_FROM_COURSE', {
                    group_id,
                    course_id,
                    material_id,
                })
            }

            return response
        },

        async removeMaterialElement({commit, state}, {group_id, material_element_id}) {

            await materialApi.removeMaterialElement(material_element_id)

            commit('REMOVE_MATERIAL_ELEMENT', {
                group_id: group_id,
                material_element_id: material_element_id
            })

            return 1

        },

        async saveMaterial({commit, state}, {material_id, group_id, title, status}) {

            await materialApi.saveMaterial({
                group_id: group_id,
                material_id: material_id,
                title: title,
                status: status
            })

            commit('SAVE_MATERIAL', {
                material_id: material_id,
                group_id: group_id,
                title: title,
                status: status
            })

            return 1

        },

        /**
         * @desc Save material element
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Material element data
         * @param {number} data.element_id
         * @param {number} data.group_id
         * @param {number} data.material_id
         * @param {number} data.adaptive_object_type_id
         * @param {number} data.adaptive_object_id
         * @param {boolean} [data.sync_state]
         * @return {Object} new_element
         */
        async saveMaterialElement(vx, data) {
            const {commit} = vx
            const {
                element_id,
                group_id,
                material_id,
                adaptive_object_type_id,
                adaptive_object_id,
                sync_state = true,
            } = data

            const response = await materialApi.saveMaterialElement({
                element_id: element_id,
                group_id: group_id,
                material_id: material_id,
                adaptive_object_type_id: adaptive_object_type_id,
                adaptive_object_id: adaptive_object_id
            })

            const new_element = response.data

            if (sync_state) {
                commit('SAVE_MATERIAL_ELEMENT', {
                    group_id: group_id,
                    material_element_id: element_id,
                    new_element: new_element,
                })
            }

            return new_element

        },

        async saveMaterialElements({commit, state}, {group_id, material_id, elements}) {

            let formatted_elements = []
            elements.map((element, index) => {
                formatted_elements.push(element.group_material_element_id)
            })

            const response = materialApi.saveMaterialElements({
                material_id: material_id,
                elements: formatted_elements
            })

            commit('UPDATE_GROUP_MATERIAL_ELEMENTS', {
                group_id: group_id,
                material_id: material_id,
                elements: formatted_elements
            })

            commit('SET_GROUP_MATERIAL_ELEMENTS_INFO', {
                group_id: group_id,
                reference_course_id: 0,
                elements: elements
            })

            return await response
        },

        /**
         * @desc Save material elements sequence
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Material element data
         * @param {number} data.group_id
         * @param {number} data.material_id
         * @param {Array} data.elements
         * @return {Promise} response
         */
        async saveMaterialElementsSequence(vx, data) {
            const {commit} = vx
            const {group_id, material_id, elements} = data

            let formattedElements = []
            let sortedElements = []

            elements.forEach((element, index) => {
                formattedElements.push(parseInt(element.group_material_element_id))

                sortedElements.push({
                    ...element,
                    sequence: index + 1,
                })

                return 1
            })

            const response = await materialApi.saveMaterialElementsSequence({
                material_id: parseInt(material_id),
                elements: formattedElements,
            })

            commit('SAVE_MATERIAL_ELEMENTS_SEQUENCE', {
                group_id: group_id,
                material_id: material_id,
                elements: sortedElements,
                elementIds: formattedElements,
            })

            return response
        },

        /**
         * @desc Save material sequence
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Material data
         * @param {number} data.group_id
         * @param {number} data.course_id
         * @param {Array} data.materials
         * @return {Promise} response
         */
        async saveMaterialsSequence(vx, data) {
            const {commit} = vx
            const {group_id, course_id, materials} = data

            let formattedMaterials = []
            let sortedMaterials = []

            materials.forEach((material, index) => {
                formattedMaterials.push(parseInt(material.group_material_id))

                sortedMaterials.push({
                    ...material,
                    materials_sort_order: index,
                })

                return 1
            })

            const response = await materialApi.saveMaterialsSequence({
                group_id: parseInt(group_id),
                course_id: parseInt(course_id),
                materials: formattedMaterials,
            })

            commit('SAVE_MATERIALS_SEQUENCE', {
                group_id: group_id,
                course_id: course_id,
                materials: sortedMaterials,
                materialIds: formattedMaterials,
            })

            return response
        },

        /**
         * Function for setting an element as hidden
         * @param vx
         * @param data
         * @returns {Promise<*>}
         */
        async setElementHidden(vx, data) {
            const {commit} = vx
            const {group_id, hidden, element_id} = data

            const response = await materialApi.setElementHidden({
                hidden : hidden,
                element_id : element_id
            })

            commit('SET_ELEMENT_HIDDEN', {
                group_id : group_id,
                hidden : hidden,
                element_id : element_id
            })

            return response

        },

        async setElementHiddenState(vx, data) {
            const {commit} = vx
            const {group_id, hidden, element_id} = data

            commit('SET_ELEMENT_HIDDEN', {
                group_id : group_id,
                hidden : hidden,
                element_id : element_id
            })
            return 1

        }

    },


    mutations: {

        ADD_MATERIAL_TO_COURSE: (state, data) => {
            const {group_id, course_id, material_id} = data

            if (!state.materials[group_id]) return 0

            const materialIndex = state.materials[group_id] ? state.materials[group_id].findIndex(material => {
                return parseInt(material.group_material_id) === parseInt(material_id)
            }) : -1

            if (materialIndex === -1) return 0

            Vue.set(state.materials[group_id][materialIndex], 'group_course_id', parseInt(course_id))
        },

        ADD_NEW_MATERIAL: (state, {group_id, material}) => {

            if (!state.materials[group_id]) {
                state.materials[group_id] = []
            }
            state.materials[group_id].push(material)
        },

        ADD_NEW_MATERIAL_ELEMENT: (state, {group_id, material_id, element}) => {
            if (!state.material_elements[group_id]) {
                state.material_elements[group_id] = []
            }
            state.material_elements[group_id].push(element)
        },

        CLEAR_STORE: (state, {group_id}) => {
            Vue.set(state.materials, group_id, [])
            Vue.set(state.material_elements, group_id, [])
        },

        REGISTER_ELEMENT_SEEN: (state, {group_id, material_element_id, read_date}) => {
            if (!material_element_id) {
                return 0
            }
            state.material_elements[group_id].map((element, index) => {
                if (parseInt(element.group_material_element_id) === parseInt(material_element_id)) {
                    Vue.set(state.material_elements[group_id][index], 'read_date', read_date)
                }
            })

        },

        REGISTER_ELEMENT_UNDERSTOOD: (state, {group_id, material_element_id, understood_date}) => {
            if (!material_element_id) {
                return 0
            }
            state.material_elements[group_id].map((element, index) => {
                if (parseInt(element.group_material_element_id) === parseInt(material_element_id)) {
                    Vue.set(state.material_elements[group_id][index], 'understood_date', understood_date)
                }
            })
        },

        REMOVE_MATERIAL: (state, {group_id, material_id}) => {
            state.materials[group_id].map((material, index) => {
                if (parseInt(material.group_material_id) === parseInt(material_id)) {
                    Vue.delete(state.materials[group_id], index)
                }
            })
        },

        REMOVE_MATERIAL_FROM_COURSE: (state, data) => {
            const {course_id, group_id, material_id} = data

            if (!state.materials[group_id]) return 0

            const materialIndex = state.materials[group_id] ? state.materials[group_id].findIndex(material => {
                return (
                    parseInt(material.group_material_id) === parseInt(material_id)
                    && parseInt(material.group_course_id) === parseInt(course_id)
                )
            }) : -1

            if (materialIndex === -1) return 0

            Vue.set(state.materials[group_id][materialIndex], 'group_course_id', null)
        },

        SAVE_MATERIAL: (state, {material_id, group_id, title, status}) => {
            const materials = state.materials[group_id]
            if (materials) {
                let found = false
                materials.map((material, index) => {
                    if (parseInt(material.group_material_id) === parseInt(material_id)) {
                        found = true
                        Vue.set(state.materials[group_id][index], 'title', title)
                        Vue.set(state.materials[group_id][index], 'status', status)
                    }
                })
                if (!found) {
                    return 0
                }

            }
            return 1
        },

        REMOVE_MATERIAL_ELEMENT: (state, {group_id, material_element_id}) => {
            state.material_elements[group_id].map((element, index) => {
                if (parseInt(element.group_material_element_id) === parseInt(material_element_id)) {
                    Vue.delete(state.material_elements[group_id], index)
                }
            })
        },

        SAVE_MATERIAL_ELEMENT: (state, data) => {
            const {group_id, material_element_id, new_element} = data

            const groupElements = state.material_elements[group_id] || []

            const elementIndex = groupElements.findIndex(element => {
                return parseInt(element.group_material_element_id) === parseInt(material_element_id)
            })

            if (elementIndex === -1) return 0

            Vue.set(state.material_elements[group_id], elementIndex, new_element)
        },

        SAVE_MATERIAL_ELEMENTS_SEQUENCE: (state, data) => {
            const {group_id, material_id, elements, elementIds} = data

            if (!state.material_elements[group_id]) {
                Vue.set(state.material_elements, group_id, [])
            }

            let groupElements = []

            groupElements = state.material_elements[group_id].filter(element => {
                return !elementIds.includes(parseInt(element.group_material_element_id))
            })

            groupElements.push(...elements)

            Vue.set(state.material_elements, group_id, groupElements)
        },

        SAVE_MATERIALS_SEQUENCE: (state, data) => {
            const {group_id, course_id, materials, materialIds} = data

            if (!state.materials[group_id]) {
                Vue.set(state.materials, group_id, [])
            }

            let groupMaterials = []

            groupMaterials = state.materials[group_id].filter(material => {
                return !materialIds.includes(parseInt(material.group_material_id))
            })

            groupMaterials.push(...materials)

            Vue.set(state.materials, group_id, groupMaterials)
        },

        SET_ELEMENT_HIDDEN: (state, data) => {
            const { group_id, hidden, element_id } = data

            if (state.material_elements[group_id]) {
                state.material_elements[group_id].map((element, index) => {
                    if (parseInt(element.group_material_element_id) === element_id) {
                        Vue.set(state.material_elements[group_id][index], 'hidden', hidden)
                    }
                })
            }

        },

        SET_GROUP_MATERIAL_ELEMENTS: (state, {group_id, reference_course_id, elements}) => {
            if (elements && elements.length) {
                elements.map((elm, index) => {
                    elements[index]['reference_course_id'] = parseInt(reference_course_id)
                })
            }

            Vue.set(state.material_elements, group_id, elements)
        },

        SET_GROUP_MATERIAL_ELEMENTS_INFO: (state, {group_id, reference_course_id, elements}) => {
            // compose map
            let element_map = {}
            let elements_found_map = {}

            if (elements && elements.length) {
                elements.map((elm, index) => {
                    elm['reference_course_id'] = parseInt(reference_course_id)
                    element_map[parseInt(elm.group_material_element_id)] = elm
                    elements_found_map[parseInt(elm.group_material_element_id)] = 0
                    elements[index]['sequence'] = index
                })
            }

            const elements_reference_exist = state.material_elements[group_id] && state.material_elements[group_id].length
            if (elements_reference_exist) {
                state.material_elements[group_id].map((element, index) => {
                    if (element_map[parseInt(element.group_material_element_id)]) {
                        elements_found_map[parseInt(element.group_material_element_id)] = 1
                        Vue.set(state.material_elements[group_id][index], 'object_info', element_map[parseInt(element.group_material_element_id)]['object_info'])
                    }
                })
            } else {
                state.material_elements[group_id] = []
            }

            // if not found, loop over not found
            for (let key in elements_found_map) {
                if (elements_found_map.hasOwnProperty(key) && (elements_found_map[key] === 0)) {
                    state.material_elements[group_id].push(element_map[key])
                }
            }
        },

        SET_GROUP_MATERIALS: (state, {group_id, reference_course_id, materials}) => {

            materials.map((course, index) => {
                materials[index]['reference_course_id'] = parseInt(reference_course_id)
            })

            Vue.set(state.materials, group_id, materials)
        },

        SET_REFERENCE_COURSE_ID: (state, {reference_course_id}) => {
            Vue.set(state, 'latest_reference_course_id', reference_course_id)
        },

        UPDATE_GROUP_MATERIAL_ELEMENTS: (state, {group_id, material_id, elements}) => {

            let ref_object = {}
            if (elements && elements.length) {
                elements.map((group_material_element_id, index) => {
                    ref_object[parseInt(group_material_element_id)] = 1
                })
            }

            if (state.material_elements && state.material_elements[group_id]) {
                state.material_elements[group_id].map((elm, index) => {
                    if (ref_object[parseInt(elm.group_material_element_id)] === 1) {
                        Vue.set(state.material_elements[group_id][index], 'group_material_id', material_id)
                    }
                })
            }

        },
    }
}
