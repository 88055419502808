import Vue from 'vue'
import adaptiveObjectAPI from '@/api/adaptiveObjectAPI'
import bankAPI from '@/api/bankAPI'

export const bankStore = {
  // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
  namespaced: true,

  state: {
      entities : [],
      object_elements : {},
      object_types : []
  },

  getters: {

  },


  actions : {

        async createBankItem({ commit, state }, { data }) {
            return await bankAPI.createBankItem({
                data : data
            })
        },

        async getAdaptiveElementsOfType ({ commit, state }, { adaptive_object_type_id, group_id }) {

            if (state.object_elements[adaptive_object_type_id] && state.object_elements[adaptive_object_type_id][group_id] && state.object_elements[adaptive_object_type_id][group_id].length) {
                return state.object_elements[adaptive_object_type_id]
            }

            const response = await adaptiveObjectAPI.getAdaptiveElementsOfType({
                adaptive_object_type_id : adaptive_object_type_id,
                group_id : group_id
            })

            const elements = response.data
            commit('SET_OBJECT_ELEMENTS', {
                adaptive_object_type_id : adaptive_object_type_id,
                group_id : group_id,
                elements : elements
            })

            return state.object_elements[adaptive_object_type_id][group_id]

        },

        async getAllAdaptiveObjectTypes({ commit, state }) {
            if (state.object_types.length) {
                return Promise.resolve(state.object_types)
            }
            const response = await bankAPI.getAllAdaptiveObjectTypes()
            const types = response.data
            commit('SET_OBJECT_TYPES', {
                types : types
            })
            return state.object_types
        },

        async getBankItemInfo({ commit, state }, { entity_id }) {
            return await bankAPI.getBankItemInfo({
                entity_id : entity_id
            })
        },

        async saveBankItem({ commit, state }, { item_id, data }) {
          return await bankAPI.saveBankItem({
              item_id : item_id,
              data : data
          })
        },

        async searchBank({ commit, state }, { search_query }) {
            const response = await  bankAPI.searchBank({
                search_query : search_query
            })
            const entities = response.data
            commit('SET_BANK_ENTRIES', {
                entities : entities
            })
            return entities
        },

  },


    mutations: {

        SET_BANK_ENTRIES: (state, {entities}) => {
            Vue.set(state, 'entities', entities)
        },

        SET_OBJECT_ELEMENTS: (state, { adaptive_object_type_id, group_id, elements }) => {

            if (!state.object_elements[adaptive_object_type_id]) {
                Vue.set(state.object_elements, adaptive_object_type_id, {})
            }

            if (!state.object_elements[adaptive_object_type_id][group_id]) {
                Vue.set(state.object_elements[adaptive_object_type_id], group_id, {})
            }

            Vue.set(state.object_elements[adaptive_object_type_id], group_id, elements)
        },

        SET_OBJECT_TYPES: (state, { types }) => {
            Vue.set(state, 'object_types', types)
        }
    }
}
