import Vue from 'vue'
import adaptiveObjectAPI from '@/api/adaptiveObjectAPI'

export const adaptiveObjectStore = {
    // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,

    state: {
        icons: {
            1 : 'mdi-square-edit-outline',
            2 : 'mdi-checkbox-marked-outline',
            3 : 'mdi-book-open-page-variant',
            4 : 'fa-cubes',
            5 : {
                'audio' : 'mdi-headphones',
                'default' : 'mdi-file',
                'image' : 'mdi-image-outline',
                'vimeo' : 'mdi-vimeo',
                'video' : 'mdi-play',
                'youtube' : 'mdi-youtube'
            },
            6 : 'mdi-graphql',
            7: 'mdi-help-circle-outline',
            8 : 'mdi-folder',
            10 : 'mdi-view-list',
            11 : 'home',
            12 : 'mdi-link-variant',
            13 : 'mdi-bullhorn-outline',
            14 : 'mdi-card-bulleted-outline',
            15 : 'mdi-cards-outline',
            20 : 'mdi-timeline-text-outline',
            22 : 'mdi-view-dashboard-variant'
        },
        sharedAdaptiveObjects: [],
        titles: {
            1 : 'Assignment',
            2 : 'Quiz',
            3 : 'Text',
            4 : 'Lesson',
            5 : {
                'audio' : 'Audio',
                'default' : 'File',
                'image' : 'Image',
                'vimeo' : 'Vimeo',
                'video' : 'Video',
                'youtube' : 'Youtube'
            },
            6 : 'Geogebra',
            7 : 'Question',
            8 : 'Folder',
            10 : 'Course',
            11 : 'Group',
            12 : 'External link',
            13 : 'Questionnaire',
            14 : 'Flashcard',
            15 : 'Flashcards',
            20 : 'Timeline',
            22 : 'Board'
        }
    },


    getters: {
        getAdaptiveIconFromId: (state) => (adaptive_object_id) => {
            return state.icons[adaptive_object_id]
        },
        getAdaptiveTitleFromId: (state) => (adaptive_object_id) => {
            return state.titles[adaptive_object_id] || ''
        },
    },


    actions : {

        async checkAdaptiveObjectCopyLog({ commit }, { adaptive_object_type_id, adaptive_object_id, source_group_id, destination_group_id }) {
            return await adaptiveObjectAPI.checkAdaptiveObjectCopyLog({
                adaptive_object_type_id : adaptive_object_type_id,
                adaptive_object_id : adaptive_object_id,
                source_group_id : source_group_id,
                destination_group_id : destination_group_id
            })
        },

        async copyAdaptiveObject({ commit }, { adaptive_object_type_id, adaptive_object_id, source_group_id, destination_group_id, allow_sync=1 }) {
            return await adaptiveObjectAPI.copyAdaptiveObject({
                adaptive_object_type_id : adaptive_object_type_id,
                adaptive_object_id : adaptive_object_id,
                source_group_id : source_group_id,
                destination_group_id : destination_group_id,
                allow_sync : allow_sync
            })
        },

        async getAdaptiveTags({ commit }, { adaptive_object_type_id, adaptive_object_id }) {
            return await adaptiveObjectAPI.getAdaptiveTags({
                adaptive_object_type_id : adaptive_object_type_id,
                adaptive_object_id : adaptive_object_id
            })
        },

        /**
         * @desc Get shared adaptive objects for the current user
         * @return {Promise} response
         */
        async getSharedAdaptiveObjects(vx) {
            const { commit } = vx

            const response = await adaptiveObjectAPI.getSharedAdaptiveObjects()

            commit('SET_SHARED_ADAPTIVE_OBJECTS', {
                adaptiveObjects: response.data,
            })

            return response
        },

        async linkAdaptiveObject({ commit, state }, { adaptive_object_type_id, adaptive_object_id, destination_group_id, destination_course_id = 0, destination_material_id = 0 }) {
            return await adaptiveObjectAPI.linkAdaptiveObject({
                adaptive_object_type_id : adaptive_object_type_id,
                adaptive_object_id : adaptive_object_id,
                destination_group_id : destination_group_id,
                destination_course_id : destination_course_id,
                destination_material_id : destination_material_id
            })
        },

        /**
         * @desc Share adaptive object with a set of users
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Share data
         * @param {number} data.adaptiveObjectTypeId
         * @param {number} data.adaptiveObjectId
         * @param {number} data.sourceGroupId
         * @param {Array} data.users
         * @return {Promise} response
         */
        async shareAdaptiveObject(vx, data) {
            const {} = vx
            const {
                adaptiveObjectTypeId,
                adaptiveObjectId,
                sourceGroupId,
                users,
            } = data

            let userIds = []

            // Pull only user ids
            users.forEach(user => {
                userIds.push(parseInt(user.user_id))

                return 1
            })

            const response = await adaptiveObjectAPI.shareAdaptiveObject({
                adaptive_object_type_id: parseInt(adaptiveObjectTypeId),
                adaptive_object_id: parseInt(adaptiveObjectId),
                source_group_id: parseInt(sourceGroupId),
                users: userIds,
            })

            return response
        },
    },


    mutations: {
        SET_SHARED_ADAPTIVE_OBJECTS: (state, data) => {
            const { adaptiveObjects } = data

            Vue.set(state, 'sharedAdaptiveObjects', adaptiveObjects)

            return 1
        }
    }
}
