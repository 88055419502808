import Vue from 'vue'
import assignmentApi from '@/api/assignmentAPI'
import postApi from '@/api/postAPI'

export const assignmentStore = {
  // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
  namespaced: true,

  state: {
      all_student_assignments : [], // for widget
      assignments : {},
      assignment_obj : {},
      past_user_feedback : {},
      turnin_obj : {},
      total_turnin_data : {},
      user_turnins : {}
  },


  getters: {

      // getLoggedInUserInfo: state => {
      //   return state.user
      // }
  },


    actions : {

        clearAssignmentStore({ commit, state }, { group_id }) {
            commit('CLEAR_STORE', {
                group_id : group_id
            })
            return 1
        },

        /**
            Function for creating a new assignment
         */
        async correctAssignmentDate({ commit, state }, { assignment_id, date }) {

            const response = await assignmentApi.correctAssignmentDate({
                assignment_id : assignment_id,
                date : date
            })

            return response.data
        },

        async correctAssignmentPlanDate({ commit, state }, { assignment_id, user_id, date, calculate_after=0 }) {

            const response = await assignmentApi.correctAssignmentPlanDate({
                assignment_id : assignment_id,
                user_id : user_id,
                date : date,
                calculate_after : calculate_after
            })

            return response.data
        },

        async createAssignment({ commit, state }, { group_id, assignment_data }) {

            const response = await assignmentApi.createAssignment({ assignment_data })

            commit('CREATE_ASSIGNMENT', {
                group_id : group_id,
                assignment_data : response.data
            })

            return response
        },

        /**
            Function for creating a new feedback to an assignment
         */
        async createAssignmentFeedback({ commit, state }, { post_id, assignment_id, post_data }) {

            const response = await postApi.createNewPost(post_data)

            let feedback_post_data = response.data

            commit('SAVE_ASSIGNMENT_POST_FEEDBACK', {
                post_id : post_id,
                assignment_id : assignment_id,
                feedback_post_data : feedback_post_data,
                reject_assignment : !!post_data.reject_assignment
            })

            if (!!post_data.reject_assignment) {
                commit('SET_FEEDBACK_RELEASED', {
                    assignment_id : assignment_id,
                    feedback_post_id : feedback_post_data.post_id,
                    time : new Date().getTime()
                })
            }

            return response

        },

        async createExternalHandin({ commit, state }, { feedback_data }) {

            return await assignmentApi.createExternalHandin({
                feedback_data : feedback_data
            })

        },

        /**
            Function for saving an assignment
         */
        async editAssignment({ commit, state }, { assignment_id, group_id, assignment_data }) {

            const response = await assignmentApi.editAssignment({
                assignment_id : assignment_id,
                assignment_data : assignment_data
            })

            commit('EDIT_ASSIGNMENT', {
                assignment_id : assignment_id,
                group_id : group_id,
                assignment_data : response.data
            })

            return response

        },

        async editAssignmentAnswer(vx, data) {
          const { commit, rootState } = vx
          const { user_id, post_id, assignment_id, assignment_data } = data

          // Check if user is a mock student
          const isMockStudent = rootState.groupStore.isMockStudent || false

          await assignmentApi.editAssignmentAnswer({
            post_id,
            assignment_data,
          }, isMockStudent)

          const response = await assignmentApi.getAssignmentAnswer({
            assignment_id,
            user_id,
          }, isMockStudent)

          let assignment_user_posts = response.data

          if (isMockStudent) {
            // Use mock data
            assignment_user_posts = [
              {
                ...assignment_data,
                firstname: 'Student',
                lastname: 'Tester',
                edit_date: Date.now() / 1000,
                post_id,
                user_id,
              },
            ]
          }

          if (!!assignment_user_posts.length) {
            commit('SET_USER_ASSIGNMENT_POSTS', {
              user_id,
              assignment_id,
              assignment_user_posts,
            })
          }

          return response
        },

        /**
        * Function for fetching assignments for group
        */
        async fetchAllGroupAssignments({ commit, state }, { group_id, bypass_cache=false }) {

            if (!bypass_cache && state.assignments[group_id] && state.assignments[group_id].length) {
                return state.assignments[group_id]
            } else {

                const response = await assignmentApi.fetchAllGroupAssignments(group_id)

                commit('SET_GROUP_ASSIGNMENTS', {
                    group_id : group_id,
                    assignments : response.data
                })

                return response.data
            }

        },

        /**
         * Get student assignment plan data for widget on frontpage
         */
        async getAllUserAssignments({ commit, state }) {

            const response = await assignmentApi.getAllUserAssignments()
            commit('SET_ALL_USER_ASSIGNMENTS', {
                data : response.data
            })

            return response
        },

        async getAllPastFeedbackFromUserAssignments({ commit, state }, { group_id, user_id }) {

            // use cache if fetched
            if (state.assignments[group_id] && state.assignments[group_id][user_id]) {
                return state.assignments[group_id][user_id]
            }

            const response = await assignmentApi.getAllPastFeedbackFromUserAssignments({ group_id, user_id })

            commit('SET_PAST_USER_FEEDBACK', {
                group_id : group_id,
                user_id : user_id,
                data : response.data
            })

            return response
        },

        async getAssignmentAnswer({ commit, state }, { user_id, assignment_id }) {

            const response = await assignmentApi.getAssignmentAnswer({ user_id, assignment_id})
            const assignment_user_posts = response.data
            if (assignment_user_posts && assignment_user_posts.length) {
                commit('SET_USER_ASSIGNMENT_POSTS', {
                    user_id : user_id,
                    assignment_id : assignment_id,
                    assignment_user_posts : assignment_user_posts
                })
            }

            return response
        },

        /**
         * Function for getting data for a single assignment element
         */
        async getAssignmentData({ commit, state }, { assignment_id, group_id }) {

            const response = await assignmentApi.getAssignmentData({
                assignment_id : assignment_id
            })

            commit('EDIT_ASSIGNMENT', {
                assignment_id : assignment_id,
                group_id : group_id,
                assignment_data : response.data
            })

            return response

        },

        async getAssignmentsWithoutFeedback({ commit, state }) {

            const response = await assignmentApi.getAssignmentsWithoutFeedback()

            return response.data

        },

        async pullAllTurninData({ commit, state }, { assignment_id, group_id }) {

            const response = await assignmentApi.pullAllTurninData({ assignment_id, group_id })

            if (response.data) {
                commit('SAVE_ALL_ASSIGNMENT_TURN_IN_DATA', {
                    assignment_id : assignment_id,
                    assignment_turnin_data : response.data
                })
            }

            return response
        },

        async registerFeedbackSeen({ commit, state }, { feedback_id }) {

            return await assignmentApi.registerFeedbackSeen({
                feedback_id : feedback_id
            })

        },


        async removeAssignment({ commit, state }, { assignment_id, group_id }) {

            const response = assignmentApi.removeAssignment(assignment_id)

            commit('REMOVE_ASSIGNMENT', { assignment_id, group_id })

            return await response
        },

        /**
         * @desc Remove assigment answer
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Assignment data
         * @param {number} data.user_id
         * @param {number} data.group_id
         * @param {number} data.assignment_id
         * @param {number} data.post_id
         * @return {Promise} response
         */
        async removeAssignmentAnswer(vx, data) {
          const { commit, rootState } = vx
          const { user_id, group_id, assignment_id, post_id } = data

          // Check if user is a mock student
          const isMockStudent = rootState.groupStore.isMockStudent || false

          // Delete assignment answer
          const response = await assignmentApi.removeAssignmentAnswer({
            post_id,
          }, isMockStudent)

          // Remove assignment answer from state
          commit('REMOVE_USER_ASSIGNMENT_ANSWER', {
            post_id,
            group_id,
            assignment_id,
          })

          return response
        },

        async removeAssignmentFeedback({ commit, state }, { post_id , feedback_post_id, assignment_id }) {

            const response = postApi.removePost(feedback_post_id)

            const response2 = assignmentApi.removeFeedback(feedback_post_id)

            commit('REMOVE_ASSIGNMENT_FEEDBACK', { post_id, assignment_id })

            return await Promise.all([
                response,
                response2
            ])

        },

        /*
            Function for removing an attachment from the store if deleted
         */
        removeFeedbackAttachment({ commit, state }, { post_id, assignment_id, media_id }) {
            commit('REMOVE_FEEDBACK_ATTACHMENT', { post_id, assignment_id, media_id })
        },

        /*
           Function for creating a new feedback to an assignment
        */
        async saveAssignmentFeedback({ commit, state }, { post_id, feedback_post_id, assignment_id, post_data }) {

            const response = await postApi.editPost(feedback_post_id, post_data)

            let feedback_post_data = response.data
            commit('SAVE_ASSIGNMENT_POST_FEEDBACK', {
                post_id : post_id,
                assignment_id : assignment_id,
                feedback_post_data : feedback_post_data,
                reject_assignment : !!post_data.reject_assignment
            })

            if (!!post_data.reject_assignment) {
                commit('SET_FEEDBACK_RELEASED', {
                    assignment_id : assignment_id,
                    feedback_post_id : feedback_post_id,
                    time : new Date().getTime()
                })
            }

            return response

        },

        /*
            Function for saving the text in feedback when direct editing a document #hack
         */
        saveAssignmentFeedbackText({ commit, state }, { post_id, assignment_id, post_text }) {
            commit('SAVE_ASSIGNMENT_POST_FEEDBACK_TEXT', { post_id, assignment_id, post_text } )
        },

        /*
          Function for creating a new feedback to an assignment
       */
        async sendUnReleasedTurninFeedback({ commit, state }, { assignment_id, feedback_post_id }) {

            const response = await assignmentApi.sendUnReleasedTurninFeedback({
                assignment_id : assignment_id,
                feedback_post_id : feedback_post_id
            })

            const time = response.data
            commit('SET_FEEDBACK_RELEASED', { assignment_id, feedback_post_id, time })

            return response
        },

        /**
         * @desc Submit assignment answer
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Assignment data
         * @param {number} data.user_id
         * @param {number} data.group_id
         * @param {number} data.assignment_id
         * @param {Object} data.assignment_data
         * @return {Promise} response
         */
        async submitAssignmentAnswer(vx, data) {
          const { commit, rootState  } = vx
          const { user_id, group_id, assignment_id, assignment_data } = data

          // Check if user is a mock student
          const isMockStudent = rootState.groupStore.isMockStudent || false

          // Submit assignment
          await assignmentApi.submitAssignmentAnswer({
            assignment_data,
          }, isMockStudent)

          // Get assignment answer
          const response = await assignmentApi.getAssignmentAnswer({
            assignment_id,
            user_id,
          }, isMockStudent)

          let assignment_user_posts = response.data

          if (isMockStudent) {
            const currentDate = Date.now()
            // Use mock data
            assignment_user_posts = [
              {
                ...assignment_data,
                firstname: 'Student',
                lastname: 'Tester',
                post_id: currentDate,
                post_date: currentDate / 1000,
                mock_identifier: true,
                user_id,
              },
            ]
          }

          // Update assignment answer state
          if (!!assignment_user_posts.length) {
            commit('SET_USER_ASSIGNMENT_POSTS', {
              user_id,
              group_id,
              assignment_id,
              assignment_user_posts,
            })
          }

          return response
        },

    },


  mutations: {

        CLEAR_STORE: (state, { group_id }) => {
            Vue.set(state.assignments, group_id, [])
        },

        CREATE_ASSIGNMENT: (state, { group_id, assignment_data }) => {
            if (!state.assignments[group_id]) {
                state.assignments[group_id] = []
            }
            state.assignments[group_id].push(assignment_data)
            Vue.set(state.assignment_obj, assignment_data['assignment_id'], assignment_data)
        },

        EDIT_ASSIGNMENT: (state, { assignment_id, group_id, assignment_data }) => {

            // locate assignment
            if (state.assignments[group_id]) {
                state.assignments[group_id].map((assignment, index) => {
                    if (parseInt(assignment['assignment_id']) === parseInt(assignment_id)) {
                        // merge data to keep course title
                        const new_data = Object.assign(assignment, assignment_data)
                        Vue.set(state.assignments[group_id], index, new_data)
                    }
                })
            }

            Vue.set(state.assignment_obj, assignment_id, assignment_data)

        },

        REMOVE_ASSIGNMENT: (state, { assignment_id, group_id }) => {

            if (state.assignments[group_id]) {
                state.assignments[group_id].map( (assignment, assignment_index) => {
                    if (parseInt(assignment['assignment_id']) === parseInt(assignment_id)) {
                        Vue.delete(state.assignments[group_id], assignment_index)
                    }
                })
            }

            Vue.delete(state.assignment_obj, assignment_id)

        },


        REMOVE_ASSIGNMENT_FEEDBACK: (state, { post_id, assignment_id }) => {

            if (state.total_turnin_data[assignment_id]) {
                state.total_turnin_data[assignment_id].map((post, post_index) => {
                    if (parseInt(post['post_id']) === parseInt(post_id)) {

                        Vue.set(state.total_turnin_data[assignment_id][post_index], 'feedback', {})
                        Vue.set(state.total_turnin_data[assignment_id][post_index], 'feedback_post_id', null)
                        Vue.set(state.total_turnin_data[assignment_id][post_index], 'feedback_release_date', null)

                        // if delete feedback - make sure to undo rejection
                        Vue.set(state.total_turnin_data[assignment_id][post_index], 'post_status', 'public')
                    }
                })
            }

            if (state.user_turnins[assignment_id]) {
                state.user_turnins[assignment_id].map((post, post_index) => {
                    if (parseInt(post['post_id']) === parseInt(post_id)) {
                        Vue.set(state.user_turnins[assignment_id][post_index], 'feedback', {})
                        Vue.set(state.user_turnins[assignment_id][post_index], 'feedback_post_id', null)
                        Vue.set(state.user_turnins[assignment_id][post_index], 'feedback_release_date', null)

                        // if delete feedback - make sure to undo rejection
                        Vue.set(state.user_turnins[assignment_id][post_index], 'post_status', 'public')
                    }
                })
            }

        },

        REMOVE_FEEDBACK_ATTACHMENT: (state, { post_id, assignment_id, media_id }) => {

            if (state.user_turnins[assignment_id]) {
                state.user_turnins[assignment_id].map( (post, post_index) => {
                    if (parseInt(post['post_id']) === parseInt(post_id)) {
                        let attachments = state.user_turnins[assignment_id][post_index]['feedback']['attachments']
                        attachments.map( (att, att_index) => {
                            if (parseInt(att['media_id']) === parseInt(media_id)) {
                                Vue.delete(state.user_turnins[assignment_id][post_index]['feedback']['attachments'], att_index)
                            }
                        })
                    }
                })
            }

        },

        REMOVE_USER_ASSIGNMENT_ANSWER: (state, { post_id, group_id, assignment_id }) => {

            if (state.user_turnins[assignment_id]) {
                state.user_turnins[assignment_id].map((post, post_index) => {
                    if (parseInt(post['post_id']) === parseInt(post_id)) {
                        Vue.delete(state.user_turnins[assignment_id], post_index)
                    }
                })
            }

            // update overview
            if (state.assignments[group_id]) {
                state.assignments[group_id].map( (assignment, index) => {
                    if (parseInt(assignment.assignment_id) === parseInt(assignment_id)) {
                        Vue.set(state.assignments[group_id][index], 'post_id', 0)
                        Vue.set(state.assignments[group_id][index], 'tag_id', 0)
                    }
                })
            }

        },


        SAVE_ALL_ASSIGNMENT_TURN_IN_DATA: (state, { assignment_id, assignment_turnin_data }) => {

            Vue.set(state.total_turnin_data, assignment_id, assignment_turnin_data)

        },

        SAVE_ASSIGNMENT_POST_FEEDBACK: (state, { post_id, assignment_id, feedback_post_data, reject_assignment = false }) => {

            let turnins = state.total_turnin_data[assignment_id]
            for (let post of turnins) {
                if (parseInt(post['post_id']) === parseInt(post_id)) {
                    let post_index = state.total_turnin_data[assignment_id].indexOf(post)
                    Vue.set(state.total_turnin_data[assignment_id][post_index], 'feedback', feedback_post_data)
                    Vue.set(state.total_turnin_data[assignment_id][post_index], 'feedback_post_id', feedback_post_data.post_id)
                    if (!post.feedback_release_date && parseInt(!post.feedback_release_date)) {
                        Vue.set(state.total_turnin_data[assignment_id][post_index], 'feedback_release_date', 0)
                    }

                    let post_status = reject_assignment ? 'rejected' : 'public'
                    Vue.set(state.total_turnin_data[assignment_id][post_index], 'post_status', post_status)

                    break
                }
            }

            state.user_turnins[assignment_id].map( (post, post_index) => {
                if (parseInt(post['post_id']) === parseInt(post_id)) {
                    Vue.set(state.user_turnins[assignment_id][post_index], 'feedback', feedback_post_data)
                    Vue.set(state.user_turnins[assignment_id][post_index], 'feedback_post_id', feedback_post_data.post_id)
                    if (!post.feedback_release_date && parseInt(!post.feedback_release_date)) {
                        Vue.set(state.user_turnins[assignment_id][post_index], 'feedback_release_date', 0)
                    }

                    let post_status = reject_assignment ? 'rejected' : 'public'
                    Vue.set(state.user_turnins[assignment_id][post_index], 'post_status', post_status)
                }
            })

        },

        SAVE_ASSIGNMENT_POST_FEEDBACK_TEXT: (state, { post_id, assignment_id, post_text }) => {

            if (state.user_turnins[assignment_id]) {
                if (state.user_turnins[assignment_id]) {
                    state.user_turnins[assignment_id].map((post, post_index) => {
                        if (parseInt(post['post_id']) === parseInt(post_id)) {
                            Vue.set(state.user_turnins[assignment_id][post_index]['feedback'], 'post_text', post_text)
                        }
                    })
                }
            }

        },

        SET_ALL_USER_ASSIGNMENTS: (state, { data }) => {
            Vue.set(state, 'all_student_assignments', data)
        },

        SET_FEEDBACK_RELEASED: (state, { assignment_id, feedback_post_id, time }) => {

            if (state.total_turnin_data[assignment_id]) {
                state.total_turnin_data[assignment_id].map((post, post_index) => {
                    if (post && (parseInt(post.feedback_post_id) === parseInt(feedback_post_id))) {
                        Vue.set(state.total_turnin_data[assignment_id][post_index], 'feedback_release_date', time)
                    }
                })
            }

            if (state.user_turnins[assignment_id]) {
                state.user_turnins[assignment_id].map( (post, post_index) => {
                    if (post && post['feedback'] && post['feedback'].post_id && (parseInt(post['feedback']['post_id']) === parseInt(feedback_post_id))) {
                        Vue.set(state.user_turnins[assignment_id][post_index], 'feedback_release_date', time)
                    }
                })
            }

        },

        SET_GROUP_ASSIGNMENTS: (state, { group_id, assignments }) => {
            // set all assignments
            Vue.set(state.assignments, group_id, assignments)
            //create object for fast retrieval
            assignments.map(assignment => {
                Vue.set(state.assignment_obj, assignment['assignment_id'], assignment)
            })
        },

        SET_PAST_USER_FEEDBACK: (state, { user_id, group_id, data }) => {
            if (!state.past_user_feedback[user_id]) {
                Vue.set(state.past_user_feedback, user_id, {})
            }
            Vue.set(state.past_user_feedback[user_id], group_id, data)
        },

        SET_USER_ASSIGNMENT_POSTS: (state, { user_id, group_id, assignment_id, assignment_user_posts }) => {

            // set turnins
            Vue.set(state.user_turnins, assignment_id, assignment_user_posts)

            // update overview
            if (state.assignments[group_id]) {
                state.assignments[group_id].map( (assignment, index) => {
                    if (parseInt(assignment.assignment_id) === parseInt(assignment_id)) {
                        Vue.set(state.assignments[group_id][index], 'tag_id', user_id)
                    }
                })
            }

        }
    }
}
