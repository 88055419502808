
const profileAssignmentsOverview = () => import('@/views/profile/subviews/profileAssignmentsOverview')
const profileCoursesOverview = () => import('@/views/profile/subviews/profileCoursesOverview')
const profileInformationView = () => import('@/views/profile/subviews/profileInformationView')
const profileQuizzesOverview = () => import('@/views/profile/subviews/profileQuizzesOverview')
const profileStatisticsOverview = () => import('@/views/profile/subviews/profileStatisticsOverview')
const profileVideosOverview = () => import('@/views/profile/subviews/profileVideosOverview')

export default [
    {
        path: 'assignments',
        component: profileAssignmentsOverview,
        meta: { requiresAuth: true, view : 'profile-assignments' }
    },
    {
        path: 'assignments/:group_id',
        component: profileAssignmentsOverview,
        meta: { requiresAuth: true, view : 'profile-assignments-group' }
    },
    {
        path: 'assignments/:group_id/assignment/:assignment_id',
        component: profileAssignmentsOverview,
        meta: { requiresAuth: true, view : 'profile-assignment' }
    },
    {
        path: 'courses',
        component: profileCoursesOverview,
        meta: { requiresAuth: true, view : 'profile-courses' }
    },
    {
        path: 'courses/:group_id',
        component: profileCoursesOverview,
        meta: { requiresAuth: true, view : 'profile-courses-group' }
    },
    {
        path: 'courses/:group_id/course/:course_id',
        component: profileCoursesOverview,
        meta: { requiresAuth: true, view : 'profile-course' }
    },
    {
        path: 'information',
        component: profileInformationView,
        meta: { requiresAuth: true, view : 'profile-information' }
    },
    {
        path: 'quizzes',
        component: profileQuizzesOverview,
        meta: { requiresAuth: true, view : 'profile-quizzes' }
    },
    {
        path: 'quizzes/:group_id',
        component: profileQuizzesOverview,
        meta: { requiresAuth: true, view : 'profile-quizzes-group' }
    },
    {
        path: 'quizzes/:group_id/quiz/:quiz_id',
        component: profileQuizzesOverview,
        meta: { requiresAuth: true, view : 'profile-quiz' }
    },
    {
        path: 'statistics',
        component: profileStatisticsOverview,
        meta: { requiresAuth: true, view : 'profile-statistics' }
    },
    {
        path: 'videos',
        component: profileVideosOverview,
        meta: { requiresAuth: true, view : 'profile-videos' }
    },
    {
        path: 'videos/:group_id',
        component: profileVideosOverview,
        meta: { requiresAuth: true, view : 'profile-videos-group' }
    },

]
