import Vue from 'vue'
import filesAPI from '@/api/filesAPI'
import mediaAPI from '@/api/mediaAPI'


export const filesStore = {
    // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,

    state: {
        folders: {},
        folders_map: {}
    },


    getters: {

    },


    actions : {


        clearFilesStore({ commit, state }, { group_id }) {
            commit('CLEAR_STORE', {
                group_id : group_id
            })
            return 1
        },

        /**
         * Function for getting all group folders
         */
        async createFolder({ commit, state }, { group_id, parent_folder, name, status }) {

            const response = await filesAPI.createFolder({
                group_id : group_id,
                parent_folder : parent_folder,
                name : name,
                status : status
            })

            commit('ADD_NEW_FOLDER', {
                group_id : group_id,
                folder_info : response.data
            })

            return response

        },

        /**
         * Function for getting all group folders
         */
        async getFolders({ commit, state }, { group_id }) {

            if (state.folders[group_id] && state.folders[group_id].length) {
                return state.folders[group_id]
            }

            const response = await filesAPI.getFolders({
                group_id : group_id
            })

            commit('SET_FOLDERS', {
                group_id : group_id,
                folders : response.data
            })
            return response.data

        },

        /**
         * Function for saving the group apps and sort order
         */
        async fetchFolderFiles({ commit, state }, { folder_id, group_id }) {

            if (!isNaN(parseInt(folder_id)) && state.folders_map[folder_id] && state.folders_map[folder_id]['files'] && state.folders_map[folder_id]['files'].length) {
                return state.folders_map[folder_id]['files']
            }

            const response = await filesAPI.fetchFolderFiles({
                folder_id : folder_id,
                group_id : group_id
            })

            commit('SET_FOLDER_FILES', {
                folder_id : folder_id,
                files : response.data
            })

            return response.data

        },

        async moveFileToFolder({ commit, state }, { folder_id, file }) {
            const response = await mediaAPI.moveFileToFolder({
                folder_id : folder_id,
                file_id : file.media_id
            })
            commit('MOVE_FILE_TO_FOLDER', {
                folder_id : folder_id,
                file : file
            })
            return response
        },

        /**
         * @desc Move folder into a parent folder
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Folder data
         * @param {number} data.parent_folder
         * @param {Object} data.folder
         */
        async moveFolderToFolder(vx, data) {
            const { commit } = vx
            const { folder, group_id, parent_folder } = data

            const response = await filesAPI.moveFolder({
                folder_id: parseInt(folder.folder_id),
                group_id: parseInt(group_id),
                parent_folder: parseInt(parent_folder),
            })

            commit('MOVE_FOLDER_TO_FOLDER', {
                folder,
                group_id: parseInt(group_id),
                parent_folder: parseInt(parent_folder),
            })

            return response
        },

        /**
         * @desc Remove file
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - File data
         * @param {number} data.folder_id
         * @param {number} data.medai_id
         * @param {number} data.material_element_type - Material element adaptive_object_type_id
         */
        async removeFile(vx, data) {
            const { commit } = vx
            const { folder_id, media_id, material_element_type = 0 } = data
            
            const response = await mediaAPI.removeFile(
                parseInt(media_id),
                parseInt(material_element_type),
            )

            if (!isNaN(parseInt(folder_id))) {
                commit('REMOVE_FILE_FROM_FOLDER', {
                    folder_id : folder_id,
                    media_id : media_id
                })
            }
            
            return response
        },

        async removeFolder({ commit, state }, { folder_id, group_id }) {

            const response = await filesAPI.removeFolder(folder_id)
            commit('REMOVE_FOLDER_FROM_SYSTEM', {
                folder_id : folder_id,
                group_id : group_id
            })

            return response
        },

        async saveFile({ commit, state }, { folder_id, media_id, data }) {

            const response = await mediaAPI.saveMedia({
                media_id : media_id,
                data : data
            })

            commit('UPDATE_FILE_INFO', {
                folder_id : folder_id,
                media_id : media_id,
                data : data
            })

            return response
        },

        async saveFolder({ commit, state }, { group_id, folder_id, title, status }) {

            const response = await filesAPI.saveFolder({
                folder_id : folder_id,
                group_id : group_id,
                title : title,
                status : status
            })

            commit('SAVE_FOLDER', {
                folder_id : folder_id,
                group_id : group_id,
                title : title,
                status : status
            })

            return response
        },


        async uploadFileToFolder({ commit, state }, { folder_id, group_id, file_info }) {

            const response = await filesAPI.uploadFileToFolder({
                folder_id : folder_id,
                group_id : group_id,
                file_info : file_info
            })

            commit('ADD_FILE_TO_FOLDER', {
                folder_id : folder_id,
                media_info : response.data
            })

            return response
        }

    },


    mutations: {

        ADD_FILE_TO_FOLDER: (state, { folder_id, media_info }) => {
            if (!state.folders_map[folder_id]['files']) {
                Vue.set(state.folders_map[folder_id], 'files', [])
            }
            state.folders_map[folder_id]['files'].push(media_info)
        },

        ADD_NEW_FOLDER: (state, { group_id, folder_info }) => {
            folder_info['files'] = []
            Vue.set(state.folders_map, folder_info.folder_id, folder_info)
            state.folders[group_id].push(folder_info)
        },

        CLEAR_STORE: (state, { group_id }) => {
            Vue.set(state.folders, group_id, [])

            const folders_map = state.folders_map
            const new_folders_map = {}

            for (let folder_id in folders_map) {
              const folder = folders_map[folder_id]

              if (parseInt(folder.group_id) !== parseInt(group_id)) {
                Vue.set(new_folders_map, folder_id, folder)
              }
            }

            Vue.set(state, 'folders_map', new_folders_map)
        },

        MOVE_FILE_TO_FOLDER: (state, { folder_id, file }) => {

            const old_folder_id = file.parent_folder
            if (state.folders_map[old_folder_id] && state.folders_map[old_folder_id]['files']) {
                state.folders_map[old_folder_id]['files'].map( (folder_file, index) => {
                    if (folder_file.media_id === file.media_id) {
                        Vue.delete(state.folders_map[old_folder_id]['files'], index)
                    }
                })
            }

            if (!state.folders_map[folder_id]) {
                Vue.set(state.folders_map, folder_id, { 'files' : [] })
            }

            if (!state.folders_map[folder_id]['files']) {
                Vue.set(state.folders_map[folder_id], 'files' , [])
            }

            file.parent_folder = folder_id
            state.folders_map[folder_id]['files'].push(file)

        },

        MOVE_FOLDER_TO_FOLDER: (state, data) => {
          const { folder, group_id, parent_folder } = data

          const movedFolder = {
            ...folder,
            parent_folder,
          }

          if (!state.folders[group_id]) {
            Vue.set(state.folders, group_id, [])
          }

          const index = state.folders[group_id].findIndex(groupFolder => {
            return parseInt(groupFolder.folder_id) === parseInt(folder.folder_id)
          })

          if (index === -1) {
            state.folders[group_id].push(movedFolder)

            return 1
          }

          Vue.set(state.folders[group_id], index, movedFolder)

          return 1
        },

        REMOVE_FILE_FROM_FOLDER: (state, { folder_id, media_id }) => {
            for (let file of state.folders_map[folder_id]['files']) {
                if (parseInt(file.media_id) === parseInt(media_id)) {
                    const index = state.folders_map[folder_id]['files'].indexOf(file)
                    Vue.delete(state.folders_map[folder_id]['files'], index)
                }
            }
        },

        REMOVE_FOLDER_FROM_SYSTEM: (state, { folder_id, group_id}) => {
            for (let folder of state.folders[group_id]) {
                if (parseInt(folder.folder_id) === parseInt(folder_id)) {
                    let index = state.folders[group_id].indexOf(folder)
                    Vue.delete(state.folders[group_id], index)
                }
            }

            Vue.delete(state.folders_map, folder_id)
        },

        SET_FOLDER_FILES: (state, { folder_id, files }) => {
            if (!state.folders_map[folder_id]) {
                Vue.set(state.folders_map, folder_id, { 'files' : [] })
            }
            if (!state.folders_map[folder_id]['files']) {
                Vue.set(state.folders_map[folder_id], 'files', [])
            }
            Vue.set(state.folders_map[folder_id], 'files', files)
        },

        SET_FOLDERS: (state, { group_id, folders }) => {

            Vue.set(state.folders, group_id, folders)

            folders.map(folder => {
                Vue.set(state.folders_map, folder.folder_id, folder)
            })

            Vue.set(state.folders_map, 0, {
                'folder_id' : 0,
                'parent_folder' : 0,
                'files' : []
            })
        },

        SAVE_FOLDER: (state, { group_id, folder_id, title, status }) => {
            Vue.set(state.folders_map[folder_id], 'title', title)
            Vue.set(state.folders_map[folder_id], 'status', status)
            if (state.folders[group_id]) {
                folder_id = parseInt(folder_id)
                state.folders[group_id].map( (folder, index) => {
                    if (parseInt(folder.folder_id) === folder_id) {
                        Vue.set(state.folders[group_id][index], 'title', title)
                        Vue.set(state.folders[group_id][index], 'status', status)
                    }
                })
            }

        },

        UPDATE_FILE_INFO: (state, { folder_id, media_id, data }) => {

            if (state.folders_map && state.folders_map[folder_id] && state.folders_map[folder_id]['files']) {
                media_id = parseInt(media_id)
                state.folders_map[folder_id]['files'].map( (file, index) => {
                    if (parseInt(file.media_id) === media_id) {
                        Vue.set(state.folders_map[folder_id]['files'], index, data)
                    }
                })
            }


        },

    }
}
