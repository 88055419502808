import boardApi from '@/api/boardAPI'

//TODO: Remove unsetXXX methods
export const boardStore = {
    namespaced: true,

    state: {
        board: null,
        boardItems: [],
        lastCreatedBoard: null,
        lastUpdatedBoard: null,
        lastCreatedBoardItem: null,
        lastUpdatedBoardItem: null,
        lastOperationSucceed: null,
    },
    mutations: {
        setBoard(state, board) {
            state.board = board
        },
        setBoardItems(state, items) {
            state.boardItems = items
        },
        addBoardItem(state, item) {
            let itemIndex = null
            for (const index in state.boardItems) {
                if (state.boardItems[index].id === item.id) {
                    itemIndex = index
                }
            }

            if (itemIndex === null) {
                state.boardItems.push(item)
            }
        },
        replaceBoardItem(state, item) {
            let itemIndex = null
            for (const index in state.boardItems) {
                if (state.boardItems[index].id === item.id) {
                    itemIndex = index
                }
            }

            if (itemIndex !== null) {
                state.boardItems.splice(itemIndex, 1, item)
            }
        },
        addOrReplaceBoardItem(state, item) {
            let itemIndex = null
            for (const index in state.boardItems) {
                if (state.boardItems[index].id === item.id) {
                    itemIndex = index
                }
            }

            if (itemIndex !== null) {
                state.boardItems.splice(itemIndex, 1, item)
            } else {
                state.boardItems.push(item)
            }
        },
        removeBoardItem(state, item) {
            let itemIndex = null
            for (const index in state.boardItems) {
                if (state.boardItems[index].id === item.id) {
                    itemIndex = index
                }
            }

            if (itemIndex !== null) {
                state.boardItems.splice(itemIndex, 1)
            }
        },
        setLastCreatedBoard(state, board) {
            state.lastCreatedBoard = board
        },
        unsetLastCreatedBoard(state) {
            state.lastCreatedBoard = null
        },
        setLastUpdatedBoard(state, board) {
            state.lastUpdatedBoard = board
        },
        unsetLastUpdatedBoard(state) {
            state.lastUpdatedBoard = null
        },
        setLastCreatedBoardItem(state, boardItem) {
            state.lastCreatedBoardItem = boardItem
        },
        unsetLastCreatedBoardItem(state) {
            state.lastCreatedBoardItem = null
        },
        setLastUpdatedBoardItem(state, boardItem) {
            state.lastUpdatedBoardItem = boardItem
        },
        unsetLastUpdatedBoardItem(state) {
            state.lastUpdatedBoardItem = null
        },
        setLastOperationSucceed(state, succeed) {
            state.lastOperationSucceed = succeed
        },
        unsetLastOperationSucceed(state) {
            state.lastOperationSucceed = null
        }
    },
    actions: {
        async loadBoard({commit}, id) {
            commit('setBoard', null)
            commit('unsetLastOperationSucceed')

            try {
                const board = (await boardApi.getBoard(id)).data
                commit('setBoard', board)
                commit('setLastOperationSucceed', true)
            } catch (error) {
                commit('setLastOperationSucceed', false)
            }
        },
        async loadBoardItems({commit}, id) {
            commit('setBoardItems', null)
            commit('unsetLastOperationSucceed')

            try {
                const items = (await boardApi.getBoardItems(id)).data
                commit('setBoardItems', items)
                commit('setLastOperationSucceed', true)
            } catch (error) {
                commit('setLastOperationSucceed', false)
            }
        },
        async createBoard({commit}, data) {
            commit('unsetLastCreatedBoard')
            commit('unsetLastOperationSucceed')

            try {
                const board = (await boardApi.createBoard(data)).data
                commit('setLastCreatedBoard', board)
                commit('setLastOperationSucceed', true)
            } catch (error) {
                commit('unsetLastOperationSucceed', false)
            }
        },
        async updateBoard({commit}, data) {
            commit('unsetLastUpdatedBoard')
            commit('unsetLastOperationSucceed')

            if (typeof data.id === 'undefined') {
                commit('unsetLastOperationSucceed', false)
                return
            }

            try {
                const board = (await boardApi.updateBoard(data.id, data)).data
                commit('setLastUpdatedBoard', board)
                commit('setLastOperationSucceed', true)
            } catch (error) {
                commit('unsetLastOperationSucceed', false)
            }
        },
        async createBoardItem({commit}, data) {
            commit('unsetLastCreatedBoardItem')
            commit('unsetLastOperationSucceed')

            if (typeof data.board_id === 'undefined') {
                commit('unsetLastOperationSucceed', false)
                return
            }

            try {
                const item = (await boardApi.createBoardItem(data.board_id, data)).data
                commit('setLastCreatedBoardItem', item)
                commit('setLastOperationSucceed', true)
            } catch (error) {
                commit('unsetLastOperationSucceed', false)
            }
        },
        async updateBoardItem({commit}, data) {
            commit('unsetLastUpdatedBoardItem')
            commit('unsetLastOperationSucceed')

            if (typeof data.board_id === 'undefined' || typeof data.id === 'undefined') {
                commit('unsetLastOperationSucceed', false)
                return
            }

            try {
                const item = (await boardApi.updateBoardItem(data.board_id, data.id, data)).data
                commit('setLastUpdatedBoardItem', item)
                commit('setLastOperationSucceed', true)
            } catch (error) {
                commit('unsetLastOperationSucceed', false)
            }
        },
        async removeBoardItem({commit}, data) {
            commit('unsetLastOperationSucceed')

            if (typeof data.board_id === 'undefined' || typeof data.id === 'undefined') {
                commit('unsetLastOperationSucceed', false)
                return
            }

            try {
                await boardApi.removeBoardItem(data.board_id, data.id)
                commit('setLastOperationSucceed', true)
            } catch (error) {
                commit('unsetLastOperationSucceed', false)
            }
        },
    }
}
