import axios from 'axios'

export default {

    async createRawText({ text, title, group_id }) {
        return await axios.post('/texts', {
            group_id : group_id,
            text : text,
            title : title
        })
    },

    /**
     * @desc Get raw text data
     * @param {Object} data - Raw text data
     * @param {boolean} isMockStudent 
     * @return {Promise} response
     */
    async getRawTextData(data, isMockStudent = false) {
      const { raw_text_id } = data

      // Handle real user
      if (!isMockStudent) return await axios.get(`/texts/data/${raw_text_id}`)

      // Mock response
      return {
        data: {
          total_time_spent: 0
        }
      }
    },

    async saveRawText({ raw_text_id, text, title, group_id }) {
        return await axios.put(`/texts/${raw_text_id}`, {
            group_id : group_id,
            text : text,
            title : title
        })
    },

    /**
     * @desc Store raw text data point
     * @param {Object} data - Raw text data
     * @param {number} data.raw_text_id
     * @param {number} data.total_time_spent
     * @param {boolean} isMockStudent 
     * @return {Promise} response
     */
    async storeRawTextDataPoint(data, isMockStudent = false) {
      const { raw_text_id, total_time_spent } = data

      // Handle real user
      if (!isMockStudent) {
        return axios.put(`/texts/data/${raw_text_id}`, {
          total_time_spent
        })
      }

      // Mock response
      return {}
    }


}
