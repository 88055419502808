/*
  LANGUAGE FILE FOR DANISH LANG
 */

export default {
    'Abandon': 'Afbryd',
    'Abandon copying to this group': 'Afbryd kopiering til dette hold',
    'Account already linked': 'Konto eksisterer allerede i systemet',
    'Account linked': 'Konto linket',
    'Access Denied!': 'Adgang nægtet!',
    'Actions': 'Muligheder',
    'Active': 'Aktiv',
    'Activity': 'Aktivitet',
    'Adaptive settings': 'Adaptive indstillinger',
    'Adaptive test': 'Adaptiv test',
    'Adaptive tests': 'Adaptive tests',
    'Add': 'Tilføj',
    'Additional group': 'Sekundært hold',
    'Add additional group': 'Tilføj sekundært hold',
    'Add answer': 'Tilføj svar',
    'Add assignment': 'Tilføj opgave',
    'Add attachment': 'Tilføj fil',
    'Add audio': 'Tilføj lyd',
    'Add board': 'Tilføj opslagstavle',
    'Add board item': 'Tilføj opslag',
    'Add category': 'Tilføj Kategori',
    'Add collection': 'Tilføj kollektion',
    'Add course': 'Tilføj forløb',
    'Add description': 'Tilføj beskrivelse',
    'Add event': 'Tilføj event',
    'Add existing material': 'Tilføj eksisterende lektion',
    'Add expression': 'Tilføj udtryk',
    'Add external link': 'Tilføj eksternt link',
    'Add feedback': 'Tilføj feedback',
    'Add file': 'Tilføj fil',
    'Add files/folders': 'Tilføj filer/mapper',
    'Add flashcard': 'Tilføj flashkort',
    'Add folder': 'Tilføj mappe',
    'Add geogebra': 'Tilføj Geogebra',
    'Add image': 'Tilføj billede',
    'Add information': 'Tilføj information',
    'Add learning element': 'Tilføj læringselement',
    'Add image label': 'Tilføj billedetikette',
    'Add match set': 'Tilføj match sæt',
    'Add material': 'Tilføj lektion',
    'Add member': 'Tilføj medlem',
    'Add members': 'Tilføj medlemmer',
    'Add participants': "Tilføj medlemmer",
    'Add new course': 'Tilføj nyt forløb',
    'Add new dependency': 'Tilføj ny forudsætning',
    'Add new flashcard': 'Tilføj nyt flashkort',
    'Add new institution': 'Tilføj ny institution',
    'Add new item': 'Tilføj nyt element',
    'Add new learning element': 'Tilføj nyt læringselement',
    'Add new match option': 'Tilføj ny match mulighed',
    'Add new material': 'Tilføj ny lektion',
    'Add new question': 'Tilføj nyt spørgsmål',
    'Add new tag': 'Tilføj nyt emne',
    'Add numeric variable': 'Tilføj en numerisk variabel',
    'Add only tags that do not exist yet. Make sure you have tried to search for the tag before adding.': 'Tilføj venligst kun emner som endnu ikke eksisterer i systemet. Sørg for at prøve at søge grundigt før du tilføjer.',
    'Add question': 'Tilføj spørgsmål',
    'Add question from questionbank': 'Tilføj spørgsmål fra spørgsmålsbanken',
    'Add questionnaire': 'Tilføj spørgeskema',
    'Add quiz': 'Tilføj quiz',
    'Add text': 'Tilføj tekst',
    'Add text label': 'Tilføj tekstetikette',
    'Add timeline': 'Tilføj tidslinje',
    'Add to word list': 'Tilføj til ordliste',
    'Add video': 'Tilføj video',
    'Add Vimeo video': 'Tilføj Vimeo video',
    'Add youtube video': 'Tilføj youtube video',
    'Added': 'Tilføjet',
    'Add groups': 'Tilføj hold',
    'Add new answer possibility': 'Tilføj ny svarmulighed',
    'Add new expression': 'Tilføj nyt udtryk',
    'Add new nummeric variable': 'Tilføj ny nummerisk variabel',
    'Add vacation/holiday': 'Tilføj ferie/helligdag',
    'Add word': 'Tilføj ord',
    'Add words to the word list. Any number of words from the list can be chosen at random.': 'Tilføj ord til ordlisten. Ethvert ord fra listen kan blive tilfældigt udvalgt.',
    'Adjust your settings and start a call': "Tilpas dine indstillinger og start opkaldet",
    'Admin': 'Underviser',
    'Admins': 'Undervisere',
    'Administration': 'Administration',
    'Afrikaans': 'Afrikaans',
    'Albanian': 'Albansk',
    'All': 'Alle',
    'All classes, courses and content will be transferred, and nothing will be lost.': 'Alle hold, forløb og indhold vil blive overført, og intet vil gå tabt.',
    'All correct answers must be chosen': 'Alle korrekte svar skal vælges',
    'All correct answers must be selected': 'Alle korrekte svar skal vælges',
    'All file types': 'Alle filtyper',
    'All topics': 'Alle delemner',
    'All variables must have one hashtag in the beginning and lower threshhold must be lower than the upper threshhold': 'Alle variable skal have et hashtag i begyndelsen og den nedre værdi må ikke være højere end den øvre.',
    'Allow counsellors': 'Tillad vejledere',
    'Allow direct user creation in the group': 'Tillad direkte brugeroprettelse på holdet',
    'Allow groups': 'Tillad gruppeaflevering',
    'Allow late turnin': 'Tillad aflevering efter frist',
    'Allow questions on material elements': 'Tillad spørgsmål på læringselementer',
    'Allow groups code join': "Tillad holdtilmelding med link",
    'All participants from your class can access this call. If you want to invite others, send them a link.': "Alle medlemmer af dit hold kan tilgå dette opkald. Hvis du vil have andre ind, så send dem linket til opkaldet.",
    'Allow peers': 'Tillad at se andres afleveringer',
    'Allow students to see results': 'Tillad studerende at se resultater',
    'Already have an account?': 'Har du allerede en bruger?',
    'An error occurred': 'Det opstod en fejl',
    'An error occurred in the quiz. Please refresh your browser window, and if the error persists, contact the support.': 'Der opstod en fejl med at hente quizzen. Venligst genindlæs browservinduet, og hvis fejlen fortsætter så kontakt supporten.',
    'Ancient history': 'Oldtidskundskab',
    'Answer': 'Svar',
    'Answer date': 'Svar dato',
    'Answer {number} more': 'Svar på {number} mere',
    'Answer evaluation criteria': 'Evalueringskriterie på svar',
    'Answer possibilities': 'Svarmuligheder',
    'Answer possibility': 'Svarmulighed',
    'Answer the question to continue the video': 'Svar på spørgsmålet for at fortsætte videoen',
    'Answers': 'Svar',
    'Answers must have a unique match option assigned': 'Alle svarmuligheder skal have en unik match-mulighed tilknyttet',
    'Answers not in category': 'Svar uden for kategori',
    'Appears on': 'Optræder på',
    'Apps': 'Apps',
    'Arabic': 'Arabisk',
    'Archive': 'Arkivér',
    'Archive for all members': 'Arkivér for alle medlemmer',
    'Archive group': 'Arkivér hold',
    'Archived': 'Arkiveret',
    'Archived groups': 'Arkiverede hold',
    'Are you a student or a teacher?': 'Er du studerende eller en underviser?',
    'Are you sure?': 'Er du sikker?',
    'Are you sure you have checked the list thoroughly? Remember you can search it too!': 'Er du sikker på du har tjekket listen ordentligt igennem? Husk du også kan søge i den!',
    'Are you sure you want to archive this group for all members?': 'Er du sikker på du vil arkivere dette hold for alle medlemmer?',
    'Are you sure you want to delete this?': 'Er du sikker på du vil slette dette?',
    'Are you sure you want to delete this assignment?': 'Er du sikker på du vil slette denne opgave?',
    'Are you sure you want to delete this collection?': 'Er du sikker på du vil slette denne kollektion?',
    'Are you sure you want to delete this feedback?': 'Er du sikker på du vil slette dette feedback?',
    'Are you sure you want to delete this file?': 'Er du sikker på du vil slette denne fil?',
    'Are you sure you want to delete this folder?': 'Er du sikker på du vil slette denne mappe? Dette fjerner også alle undermapper og filer.',
    'Are you sure you want to delete this Geogebra element?': 'Er du sikker på du vil slette dette Geogebra element?',
    'Are you sure you want to delete this group?': 'Er du sikker på du vil slette dette hold?',
    'Are you sure you want to delete this group information?': 'Er du sikker på du vil fjerne denne information?',
    'Are you sure you want to delete this post?': 'Er du sikker på du vil slette dette opslag?',
    'Are you sure you want to delete this question?': 'Er du sikker på du vil slette dette spørgsmål?',
    'Are you sure you want to delete this quiz?': 'Er du sikker på du vil slette denne quiz?',
    'Are you sure you want to delete this user?': 'Er du sikker på du vil slette denne bruger?',
    'Are you sure you want to leave the meeting?': "Er du sikker på du vil forlade mødet?",
    'Are you sure you want to mark student as a self-study? This will move all assignments years into the future.': 'Er du sikker på du vil markere denne studerende som selvstuderende? Dette vil flytte alle opgaverne mange år frem i tiden.',
    'Are you sure you want to recalculate assignment plan?': 'Er du sikker på du vil genberegne opgaveplanen?',
    'Are you sure you want to remove this answer?': 'Er du sikker på du vil fjerne dette svar?',
    'Are you sure you want to remove this course?': 'Er du sikker på du vil fjerne dette forløb?',
    'Are you sure you want to remove this label?': 'Er du sikker på du vil fjerne denne etikette?',
    'Are you sure you want to remove this learning element?': 'Er du sikker på du vil fjerne dette læringselement?',
    'Are you sure you want to remove this member?': 'Er du sikker på du vil fjerne dette medlem?',
    'Are you sure you want to remove this match set?': 'Er du sikker på du vil fjerne dette match sæt?',
    'Are you sure you want to remove this material?': 'Er du sikker på du vil fjerne denne lektion?',
    'Are you sure you want to remove this question?': 'Er du sikker på du vil fjerne dette spørgsmål?',
    'Are you sure you want to remove this subject?': 'Er du sikker på du vil fjerne dette emne?',
    'Are you sure you want to remove this topic?': 'Er du sikker på du vil fjerne dette underemne?',
    'Are you sure you want to remove this variable?': 'Er du sikker på du vil fjerne denne variabel?',
    'Are you sure you want to remove this word?': 'Er du sikker på du vil fjerne dette ord?',
    'Are you sure you want to start a video call with this user?': 'Er du sikker på du vil starte et video kald med denne bruger?',
    'Are you sure you want to turn in this questionnaire?': 'Er du sikker på du vil aflevere dette spørgeskema?',
    'Are you sure you want to turn in this quiz?': 'Er du sikker på du vil aflevere denne quiz?',
    'Are you unsure? Click the button below to read a guide on this': 'Er du usikker? Klik på knappen nedenfor, for at læse en guide om emnet.',
    'Armenian': 'Armensk',
    'Arrows': 'Pile',
    'Ask to join': "Anmod om adgang",
    'Ask your students to go to': 'Bed dine studerende om at gå til',
    'Assign': 'Tildel',
    'Assign category': 'Tildel kategori',
    'Assign student': 'Tildel studerende',
    'Assign student to': 'Tildel studerende til',
    'Assignment': 'Opgave',
    'assignment_objects': 'Opgave',
    'assignments': 'Opgaver',
    'Assignments behind': 'Opgaver bagud',
    'Assignments completion percentage': 'Opgave fuldførelsesprocent',
    'Assignment answer': 'Afleveret opgave',
    'Assignment created': 'Opgave oprettet',
    'Assignment handin timeline': 'Tidslinje for opgaveaflevering',
    'Assignment handins/feedback statistics': 'Opgaveafleverings/feedback statistik',
    'Assignment plans': 'Opgaveplaner',
    'Assignment rejected': 'Opgave afvist',
    'Assignment removed': 'Opgave fjernet',
    'Assignment saved': 'Opgave gemt',
    'Assignment statistics': 'Opgavestatistik',
    'Assignment text': 'Opgavetekst',
    'Assignment title': 'Opgavetitel',
    'Assignment turnins missing feedback': 'Opgavebesvarelser uden feedback',
    'Assignment turnin overview': 'Opgaveafleveringsoverblik',
    'At least one correct answer chosen': 'Mindst ét af de korrekte svar er valgt',
    'Attach media': 'Vedhæft fil',
    'Attachments': 'Vedhæftede filer',
    'Attachment': 'Vedhæftet fil',
    'Average on teams': 'Gennemsnit på hold',
    'Average quiz answer percentage for group': 'Gennemsnitlig svarprocent for quizzer på holdet',
    'Average user completion': 'Gennemsnitlig brugerfuldførelse',
    'Audio': 'Lyd',
    'Audio is on': 'Lyd tændt',
    'Australian': 'Australsk',
    'Awaiting feedback': 'Afventer feedback',
    'Back': 'Tilbage',
    'Back to courses': 'Tilbage til forløb',
    'Back to dashboard': 'Tilbage til dashboard',
    'Back to groups': 'Tilbage til hold',
    'Back to overview': 'Tilbage til oversigt',
    'Back to quizzes': 'Tilbage til quizzer',
    'Bank': 'Bank',
    'Below are download links to the most popular browsers, in case you do not have them installed': 'Nedenfor er links til de mest populære browsere, i tilfælde af at du ikke har dem installeret endnu.',
    'Biology': 'Biologi',
    'Board': 'Opslagstavle',
    'Bosnian': 'Bosnisk',
    'Brazilian Portuguese': 'Portugisisk (Brazilien)',
    'Business economics': 'Erhvervsøkonomi',
    'Ca': 'Ca',
    'Calculate': 'Beregn',
    'Calculate this expression': 'Udregn dette udtryk',
    'Camera': 'Kamera',
    'Cancel': 'Annuller',
    'Cancel call': 'Afbryd opkald',
    'Case sensitive': 'Skal skelne imellem store og små bogstaver',
    'Catalan': 'Katalansk',
    'Categories': 'Kategorier',
    'Category': 'Kategori',
    'changed the feedback for the assignment': 'ændrede sit feedback til afleveringen',
    'Changelog': 'Changelog',
    'Change group': 'Udskift hold',
    'Change image': 'Udskift billede',
    'Change moodle relation': 'Skift moodle relation',
    'Change UMS relation': 'Skift UMS relation',
    'characters': 'tegn',
    'chat': 'Chat',
    'Chat': 'Chat',
    'Checkbox': 'Afkrydsningsfelt',
    'Check your inbox!': 'Tjek din indbakke!',
    'Chemestry': 'Kemi',
    'Change submission date for user': "Giv brugeren en ny afleveringsdato for denne opgave",
    'Chinese': 'Kinesisk',
    'Connect Facebook': 'Forbind Facebook',
    'Connect Google': 'Forbind Google',
    'Connect your account': 'Forbind din bruger',
    'Choose': 'Vælg',
    'Choose another element': 'Vælg et andet element',
    'Choose another group': 'Vælg et andet hold',
    'Choose assignment': 'Vælg opgave',
    'Choose assignment from group': 'Vælg opgave fra hold',
    'Choose between seeing quizzes the user has passed or completed.': 'Vælg imellem at se quizzer som en bruger har bestået eller fuldført.',
    'Choose class': 'Vælg fag',
    'Choose collection': 'Vælg kollektion',
    'Choose collection from group': 'Vælg kollektion fra hold',
    'Choose column representation': 'Vælg kolonne repræsentation',
    'Choose correct match option': 'Vælg den korrekte match mulighed',
    'Choose course': 'Vælg forløb',
    'Choose CSV file': 'Vælg CSV fil',
    'Choose date': 'Vælg dato',
    'Choose element': 'Vælg element',
    'Choose element type': 'Vælg element type',
    'Choose file from filesharing': 'Vælg fil fra fildeling',
    'Choose files': 'Vælg filer',
    'Choose from existing materials': 'Vælg fra eksisterende lektioner',
    'Choose gender': 'Vælg køn',
    'Choose Geogebra': 'Vælg Geogebra',
    'Choose Geogebra from group': 'Vælg Geogebra fra hold',
    'Choose grade': 'Vælg karakter',
    'Choose group': 'Vælg hold',
    'Choose institution': 'Vælg institution',
    'Choose material': 'Vælg lektion',
    'Choose members': 'Vælg medlemmer',
    'Choose language': 'Vælg sprog',
    'Choose learning element': 'Vælg læringselement',
    'Choose lesson': 'Vælg lektion',
    'Choose new file': 'Vælg ny fil',
    'Choose questionnaire': 'Vælg spørgeskema',
    'Choose questionnaire from group': 'Vælg spørgeskema fra hold',
    'Choose quiz': 'Vælg quiz',
    'Choose quiz from group': 'Vælg quiz fra hold',
    'Choose random words': 'Vælg tilfældige ord',
    'Choose subgroup': 'Vælg underhold',
    'Choose the default language of the group': 'Vælg standard sprog dette hold undervises i (bruges til at læse op)',
    'Choose type': 'Vælg type',
    'Choose user': 'Vælg bruger',
    'Choose weekday': 'Vælg ugeday',
    'Choose what this group is teaching': 'Vælg hvad dette hold underviser i',
    'Chosen': 'Valgt',
    'Chosen file': 'Valgt fil',
    'Chosen group': 'Valgt hold',
    'Chosen user': 'Valgt bruger',
    'Class': 'Hold',
    'Classes': 'Hold',
    'Classic': 'Klassisk',
    'Class notebook link': 'Link til klassenotesbog',
    'Clear': 'Ryd',
    'Click enter to submit': 'Klik enter for at tilføje',
    'Click here to add a new learning element or edit the selected element': 'Klik her for at tilføje nye læringselementer eller redigere i det valgte element',
    'Click here to add existing material, create a new material or change the current material order': 'Klik her for at tilføje eksisterende lektioner, oprette en ny lektion eller ændre rækkefølgen på de eksisterende lektioner',
    'Click here to create a new assignment': 'Klik her for at oprette en ny opgave',
    'Click here to create a new conference post': 'Klik her for at oprette en ny tråd eller et nyt spørgsmål',
    'Click here to create a new conversation with a student or a group': 'Klik her for at oprette en ny samtale med en bruger eller et hold',
    'Click here to create a new course or change the current course order': 'Klik her for at oprette et nyt forløb, eller ændre rækkefølgen på de eksisterende forløb',
    'Click here to create a new Geogebra element': 'Klik her for at oprette et nyt Geogebra element',
    'Click here to create a new material': 'Klik her for at oprette en ny lektion',
    'Click here to create a new news post': 'Klik her for at oprette en ny nyhed på holdet',
    'Click here to create a new quiz': 'Klik her for at oprette en ny quiz',
    'Click here to create a new question': 'Klik her for at oprette et nyt spørgsmål',
    'Click here to edit the group information text': 'Klik her for at redigere gruppe informationsteksten',
    'Click here to go to your class notebook': 'Klik her for at gå til din klassenotesbog',
    'Click left/right arrows to go to next/previous card': 'Klik på venstre/højre pilene for at gå til næste/forrige kort',
    'Click on the answer to the left, and then a match option to the right, in order to make a connection.': 'Klik på svaret til venstre, og derefter matchmuligheden til højre, for at oprette en forbindelse.',
    'Click on the card to see the back': 'Klik på kortet for at se bagsiden',
    'Click on the card to see the front': 'Klik på kortet for at se forsiden',
    'Click the add learning element button in the menu on the left': 'Klik på tilføj læringselement i menuen i venstre side',
    'Click the create button to get started': 'Klik på opret knappen for at komme igang',
    'Click the big button in the lower right corner to get started': 'Klik på den store knap i nedre højre hjørne for at komme igang',
    'Click the button above to begin': 'Klik på knappen ovenfor, for at begynde',
    'Click the button to add the first learning element': 'Klik på knappen for at tilføje det første læringselement',
    'Click the create button on the left side to get started': 'Klik på opret knappen i venstre side for at komme igang',
    'Click this button and click one of the sub-buttons to create a new folder or upload a new file': 'Klik denne knap, og så klik en af de mindre knapper for at oprette en ny mappe eller tilføje en ny fil.',
    'Click this button to open the menu with your groups on all pages': 'Klik på knappen for at åbne menuen med alle dine hold på alle sider',
    'Click to start edit': 'Klik for at redigere',
    'Click up/down arrows to flip card': 'Klik på op/ned pilene for at flippe kortet',
    'Close': 'Luk',
    'cloze': 'Fill in the blank (Cloze)',
    'code': 'kode',
    'Collapse': 'Fold sammen',
    'Color': 'Farve',
    'Color can\'t be empty.': 'Farve kan ikke være tom.',
    'Comment created': 'Kommentar oprettet',
    'Comment removed': 'Kommentar fjernet',
    'Completed': 'Fuldført',
    'Completed consultations': 'Afholdte konsultationer',
    'Confirm': 'Bekræft',
    'Contact support': 'Kontakt support',
    'Contact support@edaptio.com for more info': 'Kontakt support@edaptio.com for mere info',
    'Continue': 'Fortsæt',
    'Continue where you left off': 'Fortsæt hvor du kom fra',
    'conference': 'Forum',
    'Conference thread': 'Forumtråd',
    'consultations': 'Konsultationer',
    'Conversations': 'Samtaler',
    'Conversation title': 'Titel på samtale',
    'Collapse all': 'Fold alle sammen',
    'Collection': 'Kollektion',
    'Collections': 'Kollektioner',
    'Complete registration': 'Fuldfør registrering',
    'Copied': 'Kopieret',
    'Copied to clipboard': 'Kopieret til udklipsholder',
    'Copy': 'Kopier',
    'Copy answer to the feedback field': 'Kopier svar til feedback felt',
    'Copy course': 'Kopier forløb',
    'Copy course and bypass sync': 'Kopier forløb og synkronisér ikke',
    'Copy course and sync': 'Kopier forløb og synkronisér',
    'Copy element': 'Kopier element',
    'Copy element and bypass sync': 'Kopier element og synkronisér ikke',
    'Copy element and sync': 'Kopier element og synkronisér',
    'Copy element to other group': 'Kopier element til et andet hold',
    'Copy element to these groups?': 'Kopier element til disse hold?',
    'Copy element to this group?': 'Kopier element til dette hold',
    'Copy existing question': 'Kopier eksisterende spørgsmål fra holdet',
    'Copy file': 'Kopier fil',
    'Copy file url to clipboard': 'Kopier filens webadresse til udklipsholder',
    'Copy group': 'Kopier hold',
    'Copy groups': 'Kopier hold',
    'Copy lesson': 'Kopier lektion',
    'Copy lesson and bypass sync': 'Kopier lektion og synkronisér ikke',
    'Copy lesson and sync': 'Kopier lektion og synkronisér',
    'Copy link': "Kopier link",
    'Copy new adaptive test': 'Kopier new adaptiv test',
    'Copy of': 'Kopi af',
    'Copy to clipboard': 'Kopier til udklipsholder',
    'Copy to other groups': 'Kopier element til andre hold',
    'Corrected': 'Rettet',
    'Correct': 'Korrekt',
    'Correct answer': 'Korrekt svar',
    'Correct answer(s)': 'Korrekt(e) svar',
    'Correct assignment': 'Ret opgave',
    'Correct using DirectEdit': 'Ret med DirectEdit',
    'Corresponding answer': 'Tilhørende svar',
    'Course': 'Forløb',
    'Counsellor': 'Vejleder',
    'Counsellors': 'Vejledere',
    'Counsellors list': 'Vejleder liste',
    'Course moved': 'Forløb flyttet',
    'courses': 'Forløb',
    'Course created': 'Forløb oprettet',
    'Course removed': 'Forløb fjernet',
    'Course statistics': 'Forløbsstatistik',
    'Course title': 'Forløbstitel',
    'Create': 'Opret',
    'Created': 'Oprettet',
    'Create another': 'Opret endnu en',
    'Create assignment': 'Opret opgave',
    'Create assignments in bulk': 'Opret opgaver i bunker',
    'Create and release feedback': 'Opret og frigiv feedback',
    'Create and show': 'Opret og vis',
    'Create board item': 'Tilføj opslag',
    'Create class': 'Opret hold',
    'Create collection': 'Opret kollektion',
    'Create consultation': 'Opret konsultation',
    'Create feedback': 'Opret feedback',
    'Create fields and drag them to the desired location on the image. You can switch between image and text. Adjust the \'matches with\' field to group together fields, that can be placed in the same places in the picture.': 'Opret felter og træk dem til det rigtige sted på billedet. Du kan vælge imellem billeder og tekst. Justér \'Matcher med\' feltet for at gruppere felter der gerne må ombyttes på billedet.',
    'Create folder': 'Opret mappe',
    'Create image label': 'Opret billedetikette',
    'Create Geogebra element': 'Opret Geogebra element',
    'Create group': 'Opret hold',
    'Create news post': 'Opret nyhed',
    'Create new conversation': 'Opret ny samtale',
    'Create new course': 'Opret nyt forløb',
    'Create new group': 'Opret nyt hold',
    'Create new material': 'Opret ny lektion',
    'Create new question': 'Opret nyt spørgsmål',
    'Create conference post': 'Opret forum tråd/spørgsmål',
    'Create question': 'Opret spørgsmål',
    'Create questionnaire': 'Opret spørgeskema',
    'Create quiz': 'Opret quiz',
    'Create question and show': 'Opret spørgsmål og vis',
    'Create quiz and show': 'Opret quiz og vis',
    'Create permission group': 'Opret rolle',
    'Create post': 'Opret opslag',
    'Create sub group': 'Opret underhold',
    'Create subject': 'Opret emne',
    'Create text': 'Opret tekst',
    'Create text label': 'Opret tekstetikette',
    'Create timeline': 'Opret tidslinje',
    'Create topic': 'Opret underemne',
    'Create user': 'Opret bruger',
    'Create your account': 'Opret din bruger',
    'created the assignment': 'oprettede afleveringen',
    'Croatian': 'Kroatisk',
    'Currently unavailable': "Ikke tilgængelig lige nu",
    'Czech': 'Tjekkisk',
    'Danish': 'Dansk',
    'Dansk': 'Dansk',
    'dashboard': 'Overblik',
    'Dashboard': 'Overblik',
    'dashboard-new-teacher-greeting': 'Du har endnu ikke nogen aktive hold på edaptio. <br/> Klik nedenfor for at oprette dit første hold.',
    'Date': 'Dato',
    'Definition': 'Definition',
    'Delete group information': 'Fjern information',
    'Delete': 'Slet',
    'Depending on following courses': 'Afhænger af følgende forløb',
    'Description': 'Beskrivelse',
    'Description too long': 'Beskrivelser for lang',
    'Descriptive text': 'Beskrivelsestekst',
    'Design': 'Design',
    'Deutsch': 'Tysk',
    'Dialog': 'Dialog',
    'Difficulty': 'Sværhedsgrad',
    'Direct edit': 'Direct edit',
    'Disable assignment links in assignments app': 'Slå opgaver-links fra i opgaver-app (brug kun som oversigt).',
    'Disable video': 'Slå video fra',
    'Disable audio': 'Slå lyd fra',
    'Dive deeper by choosing course and lesson.': 'Dyk dybere ned ved at vælge forløb og lektion.',
    'Document': 'Dokument',
    'Documents': 'Dokumenter',
    'Done': 'Færdig',
    'Download': 'Hent',
    'Download as csv': 'Download som csv',
    'Download original file': 'Hent original fil',
    'Download zip': 'Hent zip fil',
    'Do not create sub group': 'Opret ikke underhold',
    'Do not join': 'Tilmeld mig ikke',
    'Do not use deadline': 'Brug ikke afleveringsdato',
    'Do you know the answer?': 'Kender du svaret?',
    'Do you need help?': 'Har du brug for hjælp?',
    'Do you really want to leave? you have unsaved changes!': 'Er du sikker på du vil forlade siden? Du har ting du ikke har gemt!',
    'Do you want to abandon the process?': 'Har du lyst til at afslutte processen?',
    'Do you want to continue, but only copy new elements?': 'Har du lyst til at fortsætte, men kun kopiere nye elementer?',
    'Do you want to enable camera after joining call?': 'Vil du starte dit kamera når du kommer ind i opkaldet?',
    'Do you want to end this call for all participants or just leave this conversation?': "Vil du afslutte opkaldet for alle medlemmer, eller bare forlade opkaldet?",
    'Do you want to evenly distribute the rest of the users assignments afterwards?': 'Vil du automatisk omfordele fremtidige opgaver jævnt for brugeren efter?',
    'Do you want to remove': "Vil du fjerne",
    'Do you want to you want to leave current call?': 'Vil du forlade dette opkald?',
    'Do you want to you want to leave current webinar?': "Vil du forlade dette webinar?",
    'Do you want to synchronize elements that have already been copied?': 'Har du lyst til at synkronisere elementer, som allerede er blevet kopieret før?',
    'Do you want to test out some of our math materials?': 'Har du lyst til at prøve et udpluk af vores materialer til matematik?',
    'Do you wish to join the institution': 'Vil du tilmelde dig institutionen',
    'Do you wish to share this with everyone?': 'Kunne du tænke dig at dele dette med alle?',
    'Drag and drop labels into this box to create alternative (wrong) labels': 'Træk og slip etiketter ind i denne boks for at oprette alternative (forkerte) etiketter',
    'Drag and drop on image': 'træk og slip på billede',
    'Drama': 'Dramatik',
    'Dropout members': 'Udmeldte medlemmer',
    'Dropped out': 'Udmeldt',
    'Due_date': 'Afleveringsdato',
    'Due date': 'Afleveringsdato',
    'Due date required': 'Afleveringsdato påkrævet',
    'Duplicate': 'Duplikér',
    'Duplicate assignment': 'Duplikér opgave',
    'Duplicate flashcard': 'Duplikér flashkort',
    'Duplicate question': 'Duplikér spørgsmål',
    'Dutch': 'Hollandsk',
    'Economics': 'Økonomi',
    'Edaptio group': 'Edaptio hold',
    'Edit': 'Rediger',
    'Edit assignment': 'Rediger opgave',
    'Edit audio': 'Redigér lyd',
    'Edit board': 'Rediger opslagstavle',
    'Edit board item': 'Rediger opslag',
    'Edit collection': 'Rediger kollektion',
    'Edit course': 'Rediger forløb',
    'Edit courses order': 'Rediger rækkefølge af forløb',
    'Edit external link': 'Redigér eksternt link',
    'Edit file': 'Redigér fil',
    'Edit flashcard': 'Redigér flashkort',
    'Edit flashcards': 'Redigér flashkort',
    'Edit geogebra': 'Redigér geogebra',
    'Edit Geogebra element': 'Rediger Geogebra element',
    'edit-group': 'Holdindstillinger',
    'Edit group': 'Rediger hold',
    'Edit group information': 'Rediger gruppeinformation',
    'Edit group name': 'Rediger holdnavn',
    'Edit holiday': 'Rediger ferie/helligdag',
    'Edit image': 'Redigér billede',
    'Edit image label': 'Rediger billedetikette',
    'Edit material': 'Redigér lektion',
    'Edit materials order': 'Rediger rækkefølge af lektioner',
    'Edit message': 'Redigér besked',
    'Edit notebook': 'Redigér notesbog',
    'Edit permission group': 'Rediger rolle',
    'Edit post': 'Rediger opslag',
    'Edit question': 'Rediger spørgsmål',
    'Edit questionnaire': 'Rediger spørgeskema',
    'Edit questions': 'Rediger spørgsmål',
    'Edit quiz': 'Rediger quiz',
    'Edit timeline': 'Rediger tidslinje',
    'Edit selected learning element': 'Rediger det valgte læringselement',
    'Edit subject': 'Rediger emne',
    'Edit tests': 'Rediger tests',
    'Edit text': 'Redigér tekst',
    'Edit text label': 'Rediger tekstetikette',
    'Edit topic': 'Rediger underemne',
    'Edit user': 'Rediger bruger',
    'Element': 'Element',
    'Element already in lesson': 'Elementet er allerede på lektionen',
    'Element moved': 'Læringselement flyttet',
    'Elements': 'Elementer',
    'Edit video': 'Redigér video',
    'Edit vimeo': 'Redigér vimeo',
    'Edit youtube': 'Redigér youtube',
    'Embedded': 'Indlejret',
    'Enable camera': 'Slå kamera til',
    'Enable video': 'Slå video til',
    'Enable audio': 'Slå lyd til',
    'Email': 'Email',
    'email': 'Email',
    'Email / Username': 'Email / brugernavn',
    'Email already exists': 'Email eksisterer allerede i systemet',
    'End chat': 'Afslut chat',
    'End for all': "Afslut for alle",
    'English': 'Engelsk',
    'Enter class code': 'Indtast holdets kode',
    'Enter colleague email': 'Skriv kollegas email',
    'Enter your nickname to join this call': "Skriv dit kaldenavn for at joine opkaldet",
    'Error': 'Fejl',
    'Error occurred while trying to load your text. Please contact us to resolve this issue or simply re-enter text.': 'Der skete en fejl da vi prøvede at indlæse din tekst. Kontakt supporten hvis det bliver ved med at ske, og så fixer vi det.',
    'Error occurred during video chat. Please contact us to resolve this issue or try to restart video chat.': 'Fejl ved etablering af forbindelse. Venligst kontakt supporten eller prøv at genstarte videoopkaldet.',
    'Error: time must be in HH:MM:SS format': 'Fejl: tid skal være TT:MM:SS format',
    'Esperanto': 'Esperanto',
    'Evaluate all words': 'Evaluér alle ord',
    'Event': 'Begivenhed',
    'Events': 'Begivenheder',
    'Exam': 'Eksamen',
    'Exam mode': 'Eksamineringstilstand',
    'Exam mode limits the students communication features for an entire team during an exam. Can be turned on/off at any time, and works right away.': 'Eksamineringstilstand begrænser brugernes kommunikationsmuligheder på Edaptio, så man kan holde eksamener. Funktionen kan blive slået fra/til til enhver tid og virker øjeblikkeligt.',
    'Exit fullscreen': 'Luk fuld skærm',
    'Expand': 'Fold ud',
    'Expand all': 'Fold all ud',
    'Expressions': 'Udtryk',
    'External link': 'Eksternt link',
    'External service forgotten error': 'Du kan ikke anmode om nyt kodeord på denne institution, da i anvender et eksternt system til at logge jer ind. Kontakt administrationen på din institution.',
    'External service not linked': 'Ekstern service ikke er linket til nogen bruger',
    'External user id': 'Eksternt bruger-id',
    'Failed to activate video chat. Please contact us.': "Video chat aktivering fejlede. Kontakt venligst supporten. ",
    'Failed to delete information': 'Kunne ikke fjerne information',
    'Failed to join video chat. Maybe you re not a participant of video chat. Try to ask to join': "Der skete en fejl med at tilgå videochatten. Maybe er du ikke medlem af videochatten. På at bede om adgang. ",
    'Failed to lock\\unlock board': 'Fejl ved lås\\lås op af opslagstavle',
    'Failed to lock\\unlock board item': 'Fejl ved lås\\lås op af opslag',
    'Failed to remove board item': 'Fejl ved fjern opslag',
    'Failed to save board item': 'Fejl ved gem opslag',
    'Failed to save information': 'Kunne ikke gemme information',
    'Failed to start video chat. Please contact us.': "Vi kunne ikke starte videochatten. Kontakt venligst support.",
    'Failed to subscribe to board updates. Try to refresh board': 'Fejl ved oprettelse af forbindelse til opslagstavle-opdateringer. Prøv at genopfriske opslagstavle.',
    'Failed to subscribe to conference updates. Try to refresh conference': 'Fejl ved oprettelse af forbindelse til forum-opdateringer. Prøv at genopfriske forum.',
    'Failed to subscribe to news updates. Try to refresh news': 'Fejl ved oprettelse af forbindelse til nyheder-opdateringer. Prøv at genopfriske nyheder.',
    'Failed to subscribe to page updates. Try to refresh the page': 'Fejl ved oprettelse af forbindelse til side-opdateringer. Prøv at genopfriske side.',
    'Failed to subscribe to post answers updates. Try to refresh post answers': 'Fejl ved oprettelse af forbindelse til opslag-svar-opdateringer. Prøv at genopfriske opslag svar.',
    'FAQ': 'Ofte stillede spørgsmål',
    'Feedback': 'Feedback',
    'Feedback created': 'Feedback oprettet',
    'Feedback for this quiz try': 'Feedback til dette quiz forsøg',
    'Feedback from past assignments': 'Feedback fra tidligere opgaver',
    'Feedback with this answer': 'Feedback ved denne svarmulighed',
    'Feedback for answer': 'Feedback til svar',
    'Feedback on this answer': 'Feedback til dette svar',
    'Feedback released': 'Feedback frigivet',
    'Feedback saved': 'Feedback gemt',
    'Female': 'Kvinde',
    'Fields': 'Felter',
    'File': 'Fil',
    'Files': 'Filer',
    'File added': 'Fil tilføjet',
    'File removed': 'Fil fjernet',
    'File saved': 'Fil gemt',
    'File path': 'Filsti',
    'File too big, please upgrade your account to increase file size limit. (Trial limit:': 'Filen er for stor. Du kan opgradere din Edaptio konto hvis du vil oploade større filer. (Nuværende begrænsning: ',
    'Files added': 'Filer tilføjet',
    'file_sharing': 'Fildeling',
    'Filter': 'Filtrér',
    'Fill in the blank': 'Fill in the blank',
    'Fill in the blank (Cloze)': 'Fill in the blank (Cloze)',
    'Finish': 'Færdig',
    'Finnish': 'Finsk',
    'Firstname': 'Fornavn',
    'First handin': 'Første aflevering',
    'Fixed assignment date': 'Fast afleveringsdato',
    'Flexible assignment date': 'Fleksibel afleveringsdato',
    'Float': 'Decimaltal',
    'Folders': 'Mapper',
    'Folder added': 'Mappe tilføjet',
    'Folder removed': 'Mappe fjernet',
    'Folder saved': 'Folder gemt',
    'Forgot password?': 'Glemt kodeord?',
    'Forgotten password': 'Glemt kodeord',
    'for clarification.': 'til afklaring',
    'Fraction': 'Brøk',
    'Fractions': 'Brøker',
    'Flashcard': 'Flashkort',
    'flashcards': 'Flashkort',
    'Flashcards': 'Flashkort',
    'French': 'Fransk',
    'From': 'Fra',
    'from this call?': "fra dette opkald?",
    'Full': 'Fuld',
    'Fullscreen': 'Fuld skærm',
    'gave feedback for the assignment': 'gav feedback til afleveringen',
    'Gender': 'Køn',
    'General': 'Generelt',
    'General chat': 'Generel chat',
    'General feedback': 'Generel tilbagemelding',
    'General question types': 'Generelle spørgsmålstyper',
    'geogebra': 'Geogebra',
    'Geogebra': 'Geogebra',
    'Geogebra element': 'Geogebra element',
    'Geogebra element removed': 'Geogebra element fjernet',
    'Geogebra id': 'Geogebra id',
    'Geogebra url or id': 'Geogebra URL eller ID',
    'Geography': 'Geografi',
    'German': 'Tysk',
    'Get started': 'Kom igang',
    'Get started using Edaptio for free': 'Kom gratis igang med Edaptio',
    'Get started with Edaptio': 'Kom igang med Edaptio',
    'Get this code/URL from your teacher.': 'Få denne kode/URL fra din underviser',
    'Get turnins as zip': 'Hent afleveringer som zip',
    'Go to dashboard': 'Gå til overblik',
    'Go to group': 'Gå til hold',
    'Go to guide': 'Gå til guide',
    'Go to link': 'Gå til link',
    'Go to the team': 'Gå til holdet',
    'Go to the lesson view to add learning elements!': 'Gå til lektionen for at tilføje læringselementer!',
    'Good afternoon': 'Godeftermiddag,',
    'Good evening': 'Godaften,',
    'Good morning': 'Godmorgen,',
    'Grade': 'Karakter',
    'Grade scale': 'Karakterskala',
    'grade_scale.danish': 'Dansk 7-trins skala',
    'grade_scale.letters': 'Bogstaver A-F',
    'grade_scale.numbers': 'Tal 1-6',
    'grade_scale.romanDTU': 'Romertal I-IV',
    'Greek': 'Græsk',
    'Group': 'Hold',
    'Group with': 'Gruppér med',
    'group_courses': 'Forløb',
    'groups': 'Hold',
    'Groups in exam mode': 'Hold i eksamenstilstand',
    'group-information': 'Information',
    'group-statistics': 'Holdstatistik',
    'Group archived': 'Hold arkiveret',
    'Group created': 'Hold oprettet',
    'Group hand-in allowed': 'Gruppeaflevering tilladt',
    'Group name': 'Holdnavn',
    'Group saved': 'Hold gemt',
    'Group type': 'Holdtype',
    'Group unarchived': 'Hold fjernet fra arkiv',
    'Half': 'Halv',
    'Hatian Creole': 'Hatian Creole',
    'Handed in': 'Afleveret',
    'Handed in after deadline': 'Afleveret efter deadline',
    'has a hand up': "har en hånd oppe",
    'has started a video-chat. Click here to attend:': 'har startet en video-chat. Klik her for at deltage:',
    'Help': 'Hjælp',
    'Help center': 'Hjælpecenter',
    'Here you can copy all your group contents to another group. Remember that elements you have previously copied will only be synchronized, and not copied again.': 'Her kan du kopiere al indhold på dit hold til et andet hold. Husk at elementer som du har kopieret før, ikke vil blive kopieret igen, men derimod synkroniseret.',
    'Here you can put groups in exam mode. Exam mode means that all users in the particular group can not use any communication tools on the platform. E.g messages, news and conference': 'Her kan du sætte hold i eksamenstilstand. Eksamenstilstand betyder, at alle brugere på pågældende hold ikke kan bruge nogen kommunikationsværktøjer på platformen. Så som beskeder, nyheder eller debat.',
    'HH:MM:SS': 'TT:MM:SS',
    'Hidden lesson': "Skjult lektion",
    'Hide element': 'Skjul element',
    'Hide menu': 'Skjul menu',
    'Hide/show side menu': 'Skjul/vis sidemenu',
    'Hide non due date assignments': 'Skjult afleveringer uden frist',
    'Hidden': 'Skjult',
    'Hidden assignment': 'Skjult opgave',
    'Hidden course': 'Skjult forløb',
    'Hidden folder': 'Skjult mappe',
    'Hidden from students': 'Skjult for brugere',
    'Hidden material': 'Skjult lektion',
    'Hindi': 'Hindi',
    'History': 'Historie',
    'Home': 'Hjem',
    'How do i log in?': 'Hvordan logger jeg ind?',
    'Hungarian': 'Ungarsk',
    'Icelandic': 'Islandsk',
    'Identifier': 'Identifier',
    'If unchecked, the user will be presented with all words form the word list.': 'Hvis ikke valgt, vil brugere blive præsenteret for alle ordene i ordlisten.',
    'If you have been sent here by your teacher, then you already have a login. So make sure to ask them how to login and for your credentials.': 'Hvis du er blevet sendt herhen af din underviser, så har du allerede et login. Spørg din underviser efter dine oplysninger.',
    'If you have downloaded your assignments via the "download as zip" button, then you can upload the feedback files directly here. We will automatically generate feedback and attach the files. Make sure you only upload files you have not uploaded before, and only for submissions you have not given feedback to already.' : 'Hvis du har hentet afleveringerne via "download som zip" knappen, så kan du uploade din feedback samlet her. Vi sørger for automatisk at generere feedback og vedhæfte filerne. Sørg for kun at uploade filer, som du ikke har uploadet før, og kun til afleveringer som du ikke allerede har rettet.',
    'If you\'re supposed to have access to this page, try refreshing.': 'Hvis det er meningen at du skal have adgang til denne side, så prøv at genindlæse browservinduet.',
    'I\'m sure': 'Jeg er sikker',
    'Image': 'Billede',
    'image': 'Billede',
    'Image dimensions': 'Billeddimensioner',
    'Image height': 'Billedhøjde',
    'Image width': 'Billedbredde',
    'image-drag-and-drop': 'Træk og slip på billede',
    'Import': 'Importer',
    'Import data': 'Importer data',
    'Import existing question': 'Importér eksisterende spørgsmål fra holdet',
    'Import users': 'Importer brugere',
    'Import wiris instance': 'Importer et wiris spørgsmål',
    'In a test, all feedback is received at the end of the quiz.': 'I en test får brugeren al feedback i slutningen af quizzen.',
    'Incorrect file name': 'Forkert filnavn',
    'Indonesian': 'Indonesisk',
    'information': 'Information',
    'Information': 'Information',
    'Information deleted': 'Information fjernet',
    'Information saved': 'Information gemt',
    'Innovation': 'Innovation',
    'Input not email': 'Input er ikke en email',
    'Input too short': 'Input for kort',
    'Input valid date and time - or skip the deadline': 'Tast korrekt dato og tid - eller vælg ikke at bruge deadline på denne opgave',
    'Institution': 'Institution',
    'Institution/company name': 'Navn på institution/virksomhed',
    'Institution groups': 'Institutionens hold',
    'Institution payments': 'Betaling og forbrug',
    'Institutions': 'Institutioner',
    'Institution language': 'Institutionssprog',
    'Institution settings': 'Institutionsindstillinger',
    'Integer': 'Heltal',
    'interactive_objects': 'Geogebra',
    'Interactive exercise': 'Interaktiv øvelse',
    'International economy': 'International økonomi',
    'Invalid': 'Ugyldig',
    'Invalid form': 'Ugyldig data sendt til server',
    'Invalid: Must be at least 1': 'Ugydig: skal være minimum 1',
    'Invalid: Must be at most': 'Ugydig: må højst være',
    'Invalid: Must be between 0 - 100': 'Ugydig: Skal være imellem 0 og 100',
    'Invite colleagues': 'Invitér kolleger',
    'Invite more': 'Invitér flere',
    'Invite Participants': "Inviter medlemmer",
    'Invite participants via link': "Inviter medlemmer via link",
    'Invites have been sent to your colleagues. They will be added to your institution as soon as they click the link in the email and complete the registration.': 'Invitationerne er blevet sendt til dine kolleger. De vil blive tilføjet så snart de har gennemført registreringen på Edaptio.',
    'Invite(s) sent.': 'Invitation(er) sendt',
    'Italian': 'Italiensk',
    'Japanese': 'Japansk',
    'Join': 'Tilmeld',
    'Joined': 'Joinede',
    'Join call': 'Deltag i opkald',
    'Join now': 'Tilmeld dig her',
    'Join your class': 'Tilmeld dig',
    'Join with code not allowed': 'Tilmelding med kode er ikke tilladt for dette hold',
    'Keep training': 'Bliv ved med at træne',
    'Keyword': 'Nøgleord',
    'Knowledge bank': 'Vidensbank',
    'Korean': 'Koreansk',
    'label': 'etikette',
    'Labels not on image': 'Etikette som ikke er på billedet',
    'Language': 'Sprog',
    'lang.da': 'Dansk',
    'lang.en': 'Engelsk',
    'Language saved': 'Sprog gemt',
    'Lastname': 'Efternavn',
    'Last answered': 'Seneste besvarelse',
    'Latest activity': 'Seneste aktivitet',
    'Latest answers': 'Seneste svar',
    'Latest handin': 'Seneste aflevering',
    'Latest sync': 'Seneste synkronisering',
    'Latest try': 'Seneste forsøg',
    'Latin': 'Latinsk',
    'Latvian': 'Lettisk',
    'Learning element created': 'Læringselement oprettet',
    'Learning element removed': 'Læringselement fjernet',
    'Learning element saved': 'Læringselement gemt',
    'Leave': "Forlad",
    'Leave current video chat': 'Forlad nuværende videoopkald',
    'Leave current webinar': "Forlad dette webinar",
    'Left us': 'Forlod os',
    'Lesson': 'Lektion',
    'Lesson moved': 'Lektion flyttet',
    'Lesson title': 'Lektionstitel',
    'Lessonbank': 'Lektionsbank',
    'Lessons': 'Lektioner',
    'let all in': "lad alle komme ind",
    'Level': 'Niveau',
    'Levels': 'Niveauer',
    'Library': 'Bibliotek',
    'Link': 'Link',
    'Link copied!': "Link kopieret!",
    'Link course to group': 'Link forløb til hold',
    'Link element to group': 'Link læringselement til hold',
    'Link file to group': 'Link fil til hold',
    'Link learning element to group': 'Link læringselement til hold',
    'Link lesson to group': 'Link lektion til hold',
    'Link text': 'Link tekst',
    'Link to group': 'Link til hold',
    'Link to join to video chat': "Link til adgang til video chat",
    'List of participants': "Medlemsliste",
    'Load earlier': 'Hent tidligere',
    'Load external quiz': 'Indlæs ekstern quiz',
    'Load more': 'Hent mere',
    'Loading': 'Indlæser',
    'Lock board': 'Lås opslagstavle',
    'Lock board item': 'Lås opslag',
    'Locked course. You have not yet completed the prerequisite course(s) for this course.': 'Låst forløb. Du har endnu ikke fuldført det/de forløb som dette forløb afhænger af.',
    'Login': 'Log ind',
    'Login failed with the following message': 'Log ind fejlede med følgende meddelelse',
    'Logout': 'Log ud',
    'Log in as': 'Log på som',
    'Log in with Facebook': 'Log ind med Facebook',
    'Log in with Google': 'Log ind med Google',
    'Looking for a specific user? Search below.': 'Leder du efter en bestemt bruger? Søg nedenfor.',
    'Looking to log in? Click here': 'Leder du efter log ind? Klik her',
    'Lower border': 'Nedre grænse',
    'Made by': 'Lavet af',
    'Male': 'Mand',
    'Manage board item': 'Administrér opslag',
    'Manage groups': 'Administrer hold',
    'Manage institutions': 'Administrer institutioner',
    'Manage moodle': 'Administrer moodle',
    'Manage permissions': 'Administrer tilladelser',
    'Manage users': 'Administrer brugere',
    'Mandatory': 'Obligatorisk',
    'Maple TA': 'Maple TA',
    'Macedonian': 'Makedonsk',
    'Mark all as read': 'Markér alle som læst',
    'Mark student as self-study': 'Markér som selvstuderende',
    'Mark unread': 'Markér ulæst',
    'match': 'Match',
    'Match': 'Match',
    'Match case insensitive': 'Skal IKKE skelne imellem store og små bogstaver',
    'Match case sensitive': 'Skal skelne imellem store og små bogstaver',
    'Match options': 'Match muligheder',
    'Match with arrows': 'Match med pile',
    'Matching cloze tag': 'Matchende tekst hashtag',
    'Matching with': 'Matcher med',
    'match-wiris': 'Wiris match',
    'material': 'Lektion',
    'material_objects': 'Lektion',
    'materials': 'Lektioner',
    'Material added': 'Lektion tilføjet',
    'Material created': 'Lektion oprettet',
    'Material deleted': 'Lektion slettet',
    'Material group': 'Materialehold',
    'Material removed': 'Lektion fjernet',
    'Mathematics': 'Matematik',
    'Media': 'Medie',
    'Member': 'Medlem',
    'Members': 'Medlemmer',
    'members': 'Medlemmer',
    'Members can comment on news': 'Medlemmer kan kommentere nyheder',
    'Members can post news': 'Medlemmer kan slå nyheder op',
    'Menu': 'Menu',
    'Messages': 'Beskeder',
    'Microphone' : 'Mikrofon',
    'min': 'min',
    'Minimum number of assignments behind': 'Minimum antal opgaver bagud',
    'Minimum number of questions': 'Minimum antal spørgsmål',
    'Moldavian': 'Moldovisk',
    'Montenegrin': 'Montenegrinsk',
    'months-full-array': ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'December'],
    'months-short-array': ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
    'Moodle': 'Moodle',
    'Moodle group': 'Moodle hold',
    'More': 'Mere',
    'More settings': 'Flere indstillinger',
    'Most apps have a central action button - look for it in the same place as this one': 'Det fleste apps har en central handlingsknap - kig efter den samme sted som denne',
    'Move': 'Flyt',
    'Move to folder': 'Flyt til anden mappe',
    'ms_class_notebook': 'Klassenotesbog',
    'Multiple choice': 'Multiple choice',
    'multiple-choice': 'Multiple choice',
    'multi-choice-wiris': 'Wiris multiple choice',
    'Multiple choice settings': 'Multiple choice indstillinger',
    'Music': 'Musik',
    'Must be a valid secure link (https)': 'Skal være et validt sikkert link (https)',
    'Must be between': 'Skal være imellem',
    'Mute': 'Slå lyd fra',
    'Muted': 'Lyd slukket',
    'mute all': "mute alle",
    'My institution is not on the list': 'Min institution er ikke på listen',
    'Name': 'Navn',
    'Name saved': 'Navn gemt',
    'Need help?': 'Brug for hjælp?',
    'Never': 'Aldrig',
    'New assignment': 'Ny opgave',
    'New conversation': 'Ny samtale',
    'New field': 'Nyt felt',
    'New Geogebra element': 'Nyt Geogebra element',
    'New image': 'Nyt billede',
    'New Post': 'Nyt opslag',
    'New question': 'Nyt spørgsmål',
    'New quiz': 'Ny quiz',
    'New password': 'Nyt kodeord',
    'New relation': 'Ny relation',
    'New word': 'Nyt ord',
    'news': 'Nyheder',
    'Next': 'Næste',
    'Next assignment': 'Næste aflevering',
    'Next assignment answer without feedback': 'Næste aflevering uden feedback',
    'Next course': 'Næste forløb',
    'Next lesson': 'Næste lektion',
    'Next material': 'Næste lektion',
    'Next month': 'Næste måned',
    'Next question': 'Næste spørgsmål',
    'Next topic': 'Næste delemne',
    'No': 'Nej',
    'None': 'Ingen',
    'Notifications': 'Notifikationer',
    'No admins yet': 'Ingen undervisere endnu',
    'No answers': 'Ingen svar',
    'No assignment date': 'Ingen afleveringsdato',
    'No assignments found': 'Ingen opgaver fundet',
    'No collections found': 'Ingen kollektioner fundet',
    'No completed consultations' : 'Ingen færdiggjorte konsultationer',
    'No conversations': 'Ingen samtaler',
    'No counsellors': 'Ingen vejledere',
    'No course': 'Uden for forløb',
    'No courses found': 'Ingen forløb fundet',
    'No description': 'Ingen beskrivelse',
    'No files': 'Ingen filer',
    'No files or folders yet': 'Ingen filer eller mapper endnu',
    'No Geogebra elements found': 'Ingen Geogebra elementer fundet',
    'No group information created': 'Ingen information oprettet',
    'No groups': 'Ingen hold',
    'No groups found': 'Ingen hold fundet',
    'No information': 'Ingen information',
    'No learning elements found': 'Ingen læringselementer fundet',
    'No materials found': 'Ingen lektioner fundet',
    'No members': 'Ingen medlemmer',
    'No members yet': 'Ingen medlemmer endnu',
    'No messages found': 'Ingen beskeder endnu',
    'No news found for this group': 'Ingen nyheder på dette hold endnu',
    'No notifications': 'Ingen notifikationer',
    'No, only visible for my institution': 'Nej, kun tilgængeligt for min institution',
    'No permissions found': 'Ingen tilladelser fundet',
    'No permission to access your microphone or\\and camera you (or your companions) may experience some problems.': "Du har ikke givet tilladelse til at vi må bruge microfon og/eller video. Dette kan give problemer for dig selv eller dine gæster i video-kaldet.",
    'No posts found': 'Ingen opslag fundet',
    'No questions': 'Ingen spørgsmål',
    'No questions found': 'Ingen spørgsmål fundet',
    'No questionnaires found': 'Ingen spørgeskemaer fundet',
    'No quizzes found': 'Ingen quizzer fundet',
    'No results': 'Ingen resultater',
    'No results yet': 'Ingen resultater endnu',
    'No subjects': 'Ingen emner',
    'No title': 'Ingen titel',
    'No topic': 'Intet underemne',
    'No topics': 'Ingen underemner',
    'No students': 'Ingen studerende',
    'No tries yet': 'Ingen forsøg endnu',
    'No upcoming consultations': 'Ingen kommende konsultationer',
    'No videos found': 'Ingen videoer fundet',
    'Norwegian': 'Norsk',
    'Not assigned to course': 'Ikke tildelt til forløb',
    'Not enough data': 'Ikke nok data',
    'Not active': 'Ikke aktiv',
    'Not added': 'Ikke tilføjet',
    'Not chosen': 'Ikke valgt',
    'Not correct': 'Ikke korrekt',
    'Not completed': 'Ikke fuldført',
    'Not graded': 'Ikke rettet',
    'Not passed': 'Ikke bestået',
    'Not released': 'Ikke frigivet',
    'Not submitted': 'Ikke afleveret',
    'Not valid video chat session provided. Please restart video call normally Page reload will not help.': "Dette er ikke en valid video chat session. Venligst genstart video chat. Det hjælper ikke at genindlæse.",
    'Nothing has been shared with you yet': 'Intet er blevet delt med dig endnu',
    'Number of assignments': 'Antal opgaver',
    'Number of assignments missing feedback': 'Antal opgaver som mangler at blive rettet',
    'Number of decimals': 'Antal decimaler',
    'Number of missing': 'Antal manglende',
    'Number of votes': 'Antal stemmer',
    'Number of words': 'Antal ord',
    'Number type': 'Nummertype',
    'Numeric variables': 'Nummeriske variable',
    'OK': 'OK',
    'Okay': 'Okay',
    'Of': 'Af',
    'of': 'af',
    'Of all questions are answered correctly': 'Af alle spørgsmål bliver korrekt besvaret',
    'Off': 'Fra',
    'Old password': 'Nuværende kodeord',
    'On': 'Til',
    'Only open answer for now': 'Kun åbne svar for nu',
    'Oops something went wrong. Maybe you didn\'t grant permissions to access your camera or microphone.': "Ups! Noget gik galt. Tjek at du har givet tilladelse til kamera og mikrofon. ",
    'Oops something went wrong. Please try again.': "Ups! Noget gik galt. Prøv igen. ",
    'Open': 'Åben',
    'Open answer': 'Åbent svar',
    'Open external link': 'Åben eksternt link',
    'Open link': 'Åben link',
    'Open turnin': 'Åben besvarelse',
    'open-answer': 'Åbent svar',
    'Option': 'Mulighed',
    'Optional': 'Valgfrit',
    'Optional settings': 'Valgfrie indstillinger',
    'Options': 'Valgmuligheder',
    'or': 'eller',
    'Order saved': 'Rækkefølge gemt',
    'Other login options': 'Andre loginmuligheder',
    'Oups! This route does not exist!': 'Ups! Denne side eksisterer ikke!',
    'Overview': 'Overblik',
    'Page': 'Side',
    'Pages': 'Sider',
    'Participants': 'Deltagere',
    'Passed': 'Bestået',
    'Passed or completed': 'Bestået eller fuldført',
    'Password': 'Kodeord',
    'Password saved': 'Kodeord gemt',
    'Passwords must match': 'Kodeordene skal være ens',
    'Pass percentage': 'Beståelsesprocent',
    'Paste wiris XML': 'Indsæt wiris kode',
    'Percentage': 'Procent',
    'Percentage or fractions': 'Procent eller brøk',
    'Permission': 'Tilladelse',
    'Permissions': 'Tilladelser',
    'Permission group': 'Rolle',
    'Permission groups': 'Roller',
    'permissions.admin_bank': 'Administrator i banken',
    'permissions.admin_groups_classes': 'Administrer hold og klasser for hele institutionen',
    'permissions.admin_institution_settings': 'Administrer generelle instillinger for institutionen',
    'permissions.admin_moodle_administration': 'Administrer MOODLE for institutionen',
    'permissions.admin_permissions': 'Administrer tildeling af rettigheder for hele institutionen',
    'permissions.admin_sign_in_as_other': 'Log på som anden bruger',
    'permissions.admin_stats': 'Tilgå statistikker for hele institutionen',
    // "permissions.auto_admin_in_groups" : "Automatisk underviser på hold, som brugeren inviteres til",
    'permissions.can_view_teacher_activity': 'Kan se underviser-statistikker',
    'permissions.change_language': 'Skift sprog',
    'permissions.change_profile_picture': 'Skift profilbillede',
    'permissions.edit_module_assignments': 'Administratorrettigheder til app: Opgaver',
    'permissions.edit_module_conferences': 'Administratorrettigheder til app: Konferencer',
    'permissions.edit_module_consultations': 'Administratorrettigheder til app: Konsultationer',
    'permissions.editor_bank': 'Redaktør i banken',
    'permissions.edit_module_file_sharing': 'Administratorrettigheder til app: Fildeling',
    'permissions.edit_module_information': 'Administratorrettigheder til app: Information',
    'permissions.edit_module_quizzes': 'Administratorrettigheder til app: Quizzer',
    'permissions.edit_module_materials': 'Administratorrettigheder til app: lektioner',
    'permissions.edit_module_interactive': 'Administratorrettigheder til app: Geogebra',
    'permissions.edit_module_paths': 'Administratorrettigheder til app: Forløb',
    'permissions.edit_module_news': 'Administratorrettigheder til app: Nyheder',
    'permissions.edit_module_videos': 'Administratorrettigheder til app: Videoer',
    'permissions.edit_module_homework_aid': 'Administratorrettigheder til app: Videohjælp',
    // "permissions.message_groups_classes" : "Send beskeder til hele hold og klasser",
    'permissions.profile_assignments': 'Se opgaver for anden bruger',
    'permissions.profile_quizzes': 'Se quizzer for anden bruger',
    'permissions.profile_paths': 'Se forløb for anden bruger',
    'permissions.profile_stats': 'Se statistikker for anden bruger',
    // "permissions.search_entire_institution" : "Søg på alle hold, afleveringer og brugere på hele institutionen",
    // "permissions.see_own_assignments" : "Se opgaver for egen bruger",
    // "permissions.see_own_stats" : "Se statistikker for egen bruger",
    // "permissions.send_reminders" : "Send påmindelser til brugere",
    'permissions.view_bank': 'Adgang til banken',
    // "permissions.view_module_assignments" : "Brugerrettigheder til app: Opgaver",
    // "permissions.view_module_conferences" : "Brugerrettigheder til app: Konferencer",
    // "permissions.view_module_file_sharing" : "Brugerrettigheder til app: Fildeling",
    // "permissions.view_module_information" : "Brugerrettigheder til app: Information",
    // "permissions.view_module_quizzes" : "Brugerrettigheder til app: Quizzer",
    // "permissions.view_module_paths" : "Brugerrettigheder til app: Forløb",
    // "permissions.view_module_news" : "Brugerrettigheder til app: Nyheder",
    // "permissions.view_module_materials" : "Brugerrettigheder til app: lektioner",
    // "permissions.view_module_interactive" : "Brugerrettigheder til app: Geogebra",
    // "permissions.view_module_videos" : "Brugerrettigheder til app: Videoer",
    'permissions.user_edit_own_group': 'Admin in own group',
    'Personal groups': 'Personlige hold',
    'Philosophy': 'Filosofi',
    'Physics': 'Fysik',
    'Pick date': 'Vælg dato',
    'Pick due date': 'Vælg afleveringsdato',
    'Pick due time': 'Vælg afleveringstid',
    'Pick new background image': 'Vælg nyt baggrundsbillede',
    'Pick time': 'Vælg tid',
    'Pick the same date if it is just a single holiday': 'Vælg den samme dato hvis det er en enkelt helligdag',
    'Pin': "Fastgør",
    'Pin post': 'Fastgør opslag',
    'Pin side menu': 'Fastgør sidemenu',
    'Pinned': 'Fastgjort',
    'Phone': 'Telefon',
    'Please fix all errors and try again': 'Venligst ret alle fejl og prøv igen',
    'Please select a user': 'Venligst vælg en bruger',
    'Please use any other browser for browsing our platform.': 'Venligst brug en hvilken som helst anden browser til at tilgå vores platform.',
    'Please verify your email.': 'Venligst bekræft din email.',
    'Please verify your email': 'Venligst bekræft din email',
    'Please wait until the call owner lets you in': "Vent venligst til du bliver lukket ind i opkaldet",
    'Polish': 'Polsk',
    'Pool size': 'Pool størrelse',
    'Portuguese': 'Portugisisk',
    'Possibilities': 'Muligheder',
    'Post': 'Slå op',
    'Post created': 'Opslag oprettet',
    'Post removed': 'Opslag fjernet',
    'Post saved': 'Opslag gemt',
    'Practiced': 'Trænet',
    'Practice again': 'Træn igen',
    'Practice quiz': 'Kan træne quiz',
    'Presentation': 'Præsentation',
    'Press enter to add new field, and backspace to remove': 'Tryk enter for at tilføje nyt felt, eller backspace for at slette',
    'Preview data': 'Vis data',
    'Previous': 'Forrige',
    'Previous month': 'Forrige måned',
    'Previous topic': 'Forrige delemne',
    'Profile': 'Profil',
    'Progression': 'Progression',
    'Psychology': 'Psykologi',
    'Pull the rows to edit the sort order of the apps and activate/deactivate the app using the lever. You can also hide apps from the students': 'Træk i ikonerne for at ændre rækkefølgen af apps, og aktiver/deaktiver apps ved at slå den første knap til. Du kan også skjule apps for dine brugere ved at indstille på knappen længst til højre.',
    'Put labels in the same group to make them interchangeable.': 'Sæt etiketter i den samme gruppe for at gøre dem udskiftelige.',
    'Quarter': 'Kvart',
    'Question': 'Spørgsmål',
    'Question and answers': 'Spørgsmål og svar',
    'Questionnaire': 'Spørgeskema',
    'questionnaires': 'Spørgeskemaer',
    'Questionnaires': 'Spørgeskemaer',
    'Questions': 'Spørgsmål',
    'Question bank': 'Spørgsmålsbank',
    'Question overview': 'Spørgsmålsoversigt',
    'Question statistics': 'Spørgsmålsstatistik',
    'Question type': 'Spørgsmålstype',
    'Questions from the bank': 'Spørgsmål fra banken',
    'Questions in order': 'Spørgsmål i rækkefølge',
    'Questions must have at least one correct answer and cannot be empty': 'Spørgsmål skal have minimum et korrekt svar og må ikke være tomme',
    'Queue': "Kø",
    'Quiz': 'Quiz',
    'quiz_objects': 'Quiz',
    'quiz_question_objects': 'Quizspørgsmål',
    'Quiz completion percentage': 'Quiz fuldførelsesprocent',
    'Quiz matrix': 'Quiz matrix',
    'Quiz mode': 'Quiz funktion',
    'Quiz statistics': 'Quizstatistik',
    'Quiz status': 'Quiz status',
    'Quiz type': 'Quiz type',
    'Quizzes': 'Quizzer',
    'quizzes': 'Quizzer',
    'Radio buttons': 'Radio knapper',
    'Random pool size': 'Antal tilfældige spørgsmål',
    'Random questions': 'Tilfældige spørgsmål',
    'Read messages': 'Læste',
    'Read more': 'Læs mere',
    'Reading time': 'Læsetid',
    'Recalculate assignment plan for user': 'Genberegn opgaveplan for bruger',
    'Recalculate assignment plans for group': 'Genberegn opgaveplaner for hold',
    'Recalculate cloze options': 'Hiv tekst-variable igen',
    'Reconnect': 'Genopret forbindelse',
    'Refresh board': 'Genopfrisk opslagstavle',
    'Refresh the page if the changes do not appear': 'Genindlæs siden hvis ændringerne ikke kommer frem',
    'Register': 'Registrér',
    'Register handin': 'Registrér aflevering',
    'Register handin manually': 'Registrér aflevering manuelt',
    'Rejected': 'Afvist',
    'Reject assignment': 'Afvis aflevering',
    'Released': 'Frigivet',
    'Release feedback': 'Frigiv feedback',
    'Religion': 'Religion',
    'Remember to link this quiz to a Maple TA assignment after you save it. If you fail to do this, users will encounter a dead link.': 'Husk at linke denne quiz til en opgave i Maple TA efter du opretter den. Hvis du ikke gør det, vil dine brugere møde et dødt link. ',
    'Remove': 'Fjern',
    'Remove answer': 'Fjern svar',
    'Remove assignment': 'Fjern opgave',
    'Remove attachment': 'Fjern vedhæftet fil',
    'Remove audio': 'Fjern lyd',
    'Remove board': 'Fjern opslagstavle',
    'Remove collection': 'Fjern kollektion',
    'Remove course': 'Fjern forløb',
    'Remove external link': 'Fjern eksternt link',
    'Remove feedback': 'Fjern tilbagemelding',
    'Remove file': 'Fjern fil',
    'Remove flashcard': 'Fjern flashkort',
    'Remove flashcards': 'Fjern flashkort',
    'Remove folder': 'Fjern mappe',
    'Remove from material': 'Fjern fra lektion',
    'Remove geogebra': 'Fjern geogebra',
    'Remove Geogebra element': 'Fjern Geogebra element',
    'Remove group': 'Fjern hold',
    'Remove image': 'Fjern billede',
    'Remove match set': 'Fjern match sæt',
    'Remove material': 'Fjern lektion',
    'Remove member': 'Fjern medlem',
    'Remove label': 'Fjern etikette',
    'Remove learning element': 'Fjern læringselement',
    'Remove post': 'Fjern opslag',
    'Remove subject': 'Fjern emne',
    'Remove text': 'Fjern tekst',
    'Remove topic': 'Fjern underemne',
    'Remove question': 'Fjern spørgsmål',
    'Remove questionnaire': 'Fjern spørgeskema',
    'Remove quiz': 'Fjern quiz',
    'Remove timeline': 'Fjern tidslinje',
    'Remove user': 'Fjern bruger',
    'Remove user from the conversation': 'Fjern bruger fra samtale',
    'Remove video': 'Fjern video',
    'Remove vimeo': 'Fjern vimeo',
    'Remove your Facebook account': 'Fjern din Facebook bruger',
    'Remove your Google account': 'Fjern din Google bruger',
    'Remove youtube': 'Fjern youtube',
    'Remove variable': 'Fjern variabel',
    'Remove word': 'Fjern ord',
    'Rename': 'Omdøb',
    'Reorder elements': 'Omorganiser læringselementer',
    'Repeatable': 'Gentagelig',
    'Repeatable quiz': 'Gentagelig quiz',
    'Repeat quiz': 'Gentag quiz',
    'Repeat password': 'Gentag kodeord',
    'Reset password': 'Sæt nyt kodeord',
    'Reset completion deadlines': 'Genstart gennemførselsdeadlines',
    'Required': 'Påkrævet',
    'Required pass percentage': 'Påkrævet beståelsesprocent',
    'Requires at least': 'Kræver mindst',
    'Result': 'Resultat',
    'Results': 'Resultater',
    'Retry': "Prøv igen",
    'Return to dashboard': 'Tilbage til dashboard',
    'Role': 'Rolle',
    'Romanian': 'Romansk',
    'Russian': 'Russisk',
    'Save': 'Gem',
    'Save and attach to feedback': 'Gem og vedhæft på feedback',
    'Save and release feedback': 'Gem og frigiv feedback',
    'Save and show': 'Gem og vis',
    'Save assignment': 'Gem opgave',
    'Save audio file': 'Gem lydfil',
    'Save collection': 'Gem kollektion',
    'Save external link': 'Gem eksternt link',
    'Save feedback': 'Gem feedback',
    'Save file': 'Gem fil',
    'Save geogebra': 'Gem geogebra',
    'Save image': 'Gem billede',
    'Save in filesystem': 'Gem i fildeling',
    'Save order': 'Gem rækkefølge',
    'Save question and show': 'Gem spørgsmål og vis',
    'Save question': 'Gem spørgsmål',
    'Save questionnaire': 'Gem spørgeskema',
    'Save quiz': 'Gem quiz',
    'Save quiz and show': 'Gem quiz og vis',
    'Save video': 'Gem video',
    'Save Vimeo video': 'Gem Vimeo video',
    'Save subject': 'Gem emne',
    'Save text': 'Gem tekst',
    'Save to': 'Gem til',
    'Save to root folder': 'Gem i grundfolder',
    'Save topic': 'Gem underemne',
    'Save youtube video': 'Gem youtube video',
    'Science': 'Naturvidenskab',
    'School': 'Skole',
    'Search': 'Søg',
    'Search all students': 'Søg alle studerende',
    'Search counsellor': 'Søg vejleder',
    'Search for users, groups..': 'Søg efter hold, brugere..',
    'Search group': 'Søg hold',
    'Search person': 'Søg person',
    'Search question': 'Søg spørgsmål',
    'Search results': 'Søgeresultater',
    'Search student': 'Søg studerende',
    'Search students in the class': 'Søg kun studerende på hold',
    'Search user': 'Søg bruger',
    'Search youtube': 'Søg Youtube',
    'sec': 'sek',
    'Security warning': 'Sikkerhedsadvarsel',
    'See all': 'Se alle',
    'See all news': 'Se alle nyheder',
    'See past feedback': 'Se tidligere feedback',
    'Seen': 'Set',
    'Seen by all': 'Set af alle',
    'Seen by student': 'Set af studerende',
    'Selected counsellor\'s students': 'Valgte vejleders studerende',
    'Selected groups': 'Valgte hold',
    'Selected members': 'Valgte medlemmer',
    'Select a month': 'Vælg en måned',
    'Select tags': 'Vælg emner',
    'Select a year': 'Vælg et år',
    'Select from list': "Vælg fra liste",
    'Send': 'Send',
    'Send a message to start the chat': 'Send en besked for at starte chatten',
    'Send copy to colleagues': 'Send kopi til kolleger',
    'Send element to these colleagues?': 'Send element til disse kolleger?',
    'Send element to this colleague?': 'Send element til denne kollega?',
    'Send Invites': 'Send invitationer',
    'Send message': 'Send besked',
    'Send messages': 'Send beskeder',
    'Send unreleased feedback': 'Send ikke-frigivet feedback',
    'Sentence': 'Sætning',
    'Serbian': 'Serbisk',
    'Serbo-Croatian': 'Serbo-kroatisk',
    'Setting': 'Indstilling',
    'Settings': 'Indstillinger',
    'Settings saved': 'Indstillinger gemt',
    'Sign up': 'Tilmeld dig',
    'Sign up with Facebook' : 'Tilmeld dig med Facebook',
    'Sign up with Google' : 'Tilmeld dig med Google',
    'SimpleMath multiple choice': 'SimpleMath multiple choice',
    'Simplemath settings': 'Simplemath indstillinger',
    'SimpleMath settings': 'SimpleMath indstillinger',
    'SimpleMath short answer': 'SimpleMath kortsvar',
    'simple-math-short-answer': 'SimpleMath kortsvar',
    'simple-math-multiple-choice': 'SimpleMath multiple choice',
    'Size': 'Størrelse',
    'Share': 'Del',
    'Share course': 'Del forløb',
    'Share element': 'Del element',
    'Share file': 'Del fil',
    'Share folder': 'Del mappe',
    'Share material': 'Del lektion',
    'Share screen': 'Del skærm',
    'Share with colleagues': 'Del med kolleger',
    'shared': 'Delt',
    'shared the assignment': 'Delte opgaven',
    'shared the course': 'Delte forløbet',
    'shared the flashcard': 'Delte flashkortet',
    'shared the flashcard collection': 'Delte flashkort kollektionen',
    'shared the folder': 'Delte folderen',
    'shared the geogebra': 'Delte geogebra elementet',
    'shared the group': 'Delte holdet',
    'shared the lesson': 'Delte lektionen',
    'shared the link': 'Delte linket',
    'shared the media': 'Delte mediaet',
    'shared the text': 'Delte teksten',
    'shared the question': 'Delte spørgsmålet',
    'shared the questionnaire': 'Delte spørgsskemaet',
    'shared the quiz': 'Delte quizzen',
    'shared the timeline': 'Delte tidslinjen',
    'Shared with me': 'Delt med mig',
    'Short answer': 'Kortsvar',
    'Short answer settings': 'Kortsvarindstillinger',
    'short-answer': 'Kortsvar',
    'short-answer-wiris': 'Wiris kortsvar',
    'Show': 'Vis',
    'Show all': 'Vis alle',
    'Show element': 'Vis element',
    'Show info': 'Vis info',
    'Show less': 'Vis mindre',
    'Show more': 'Vis mere',
    'Skip first line': 'Spring første linje over',
    'Skip over elements that have already been copied': 'Spring elementer over som allerede er blevet kopieret',
    'Slovak': 'Slovakisk',
    'Socialstudies': 'Samfundsfag',
    'Some elements have already been copied to these groups.': 'Nogle elementer er allerede blevet kopieret til disse hold',
    'Some elements have already been copied to this group.': 'Nogle elementer er allerede blevet kopieret til dette hold',
    'Sorry, we can not connect your account. Please try again later.': 'Beklager, vi kan ikke linke din bruger på dette tidspunkt. Prøv venligst senere.',
    'Spanish': 'Spansk',
    'Spanish Latin American': 'Spansk (Latin Amerika)',
    'Specify the difficulty of the question. This is your subjective assessment compared to the rest of the content.': 'Sæt sværhedsgraden på dette spørgsmål. Dette er din subjektive vurdering sammenlignet med resten af indholdet på dette niveau.',
    'Spell': 'Stav',
    'spell-speech': 'Stav ord',
    'Spell word': 'Stav ord',
    'Start': 'Start',
    'Start broadcasting': "Begynd at udsende video",
    'Start exam mode': 'Start eksamenstilstand',
    'Start making great learning content, by verifying your email.' : 'Verificer din email, og begynd at bygge fantastiske læringsmaterialer!',
    'Start quiz': 'Start quiz',
    'Start reading': 'Start oplæsning',
    'Start video chat': 'Start video chat',
    'Statistics': 'Statistik',
    'Status': 'Status',
    'STEM': 'Naturfag og teknik',
    'Stop broadcasting': "Stop videoudsendelse",
    'Stop exam mode': 'Stop eksamenstilstand',
    'Stop reading': 'Stop oplæsning',
    'Stop sharing': 'Stop deling',
    'Student': 'Studerende',
    'student': 'studerende',
    'students': 'studerende',
    'Student handed in offline': 'Bruger afleverede opgaven offline',
    'Students can add flashcards': 'Studerende kan tilføje flashkort',
    'Students list': 'Studerende liste',
    'Student / student average': 'Bruger / gennemsnit af bruger',
    'Student Testing': 'Studerende test',
    'Student view': 'Vis som studerende',
    'Subgroups': 'Underhold',
    'Subject': 'Emne',
    'Subjects': 'Emner',
    'Submit answer & continue': 'Angiv svar & fortsæt',
    'Success': 'Succes',
    'Support mail': 'Support e-mail',
    'Swahili': 'Swahili',
    'Swap out': 'Udskift',
    'Swedish': 'Svensk',
    'Switch': 'Udskift',
    'Switch additional group': 'Udskift sekundært hold',
    'Switch back to main user': 'Skift tilbage til oprindelige bruger',
    'Switch main group': 'Udskift primært hold',
    'Sync': 'Synkronisér',
    'Synchronize elements that have already been copied': 'Synkronisér elementer som allerede er blevet kopieret',
    'Synchronize hashtags manually': 'Synkronisér hashtags manuelt',
    'Tags': 'Emner',
    'Tamil': 'Tamil',
    'Teacher': 'Underviser',
    'Teachers': 'Undervisere',
    'Teacher view': 'Vis som underviser',
    'Term': 'Udtryk',
    'Test': 'Test',
    'Test answer': 'Test svar',
    'text': 'Tekst',
    'Text': 'Tekst',
    'Text can\'t be empty and should be shorter then 255 characters.': 'Tekst kan ikke være tom og skal være mindre end 255 karakterer.',
    'Text copied': 'Tekst kopieret',
    'Text on label': 'Tekst på etikette',
    'Time': 'Tid',
    'Timeline': 'Tidslinje',
    'Time in HH:MM:SS': 'Tid i TT:MM:SS',
    'Time to complete course (days)': 'Tid til at gennemføre holdet (dage)',
    'Tip': 'Tip',
    'Title': 'Titel',
    'Title required': 'Titel er påkrævet',
    'Title too long': 'Titel for lang',
    'Thai': 'Thailandsk',
    'Thanks for your answer': 'Tak for dit svar',
    'Thank you for your interest in Edaptio.': 'Tak for din interesse i Edaptio.',
    'Thank you for your understanding': 'Tak for din forståelse',
    'The user has received an email with all the necessary information to get started': 'Brugeren har modtaget en mail med alle de relevante informationer til at komme i gang.',
    'The user will write an open ended text here': 'Brugeren vil skrive et åbent svar her',
    'There is currently an error with the WIRIS plugin, which is why we have removed it. We are working on resolving it.': 'Der er i øjeblikket en fejl med WIRIS pluginet, og vi har fjernet det. Vi arbejder hårdt på at få det løst.',
    'The assignment is closed for handin': 'Opgaven er lukket for aflevering',
    'The corrections have been attached to the feedback': 'Retterelserne er vedhæftet din tilbagemelding',
    'The Geogebra element has been created': 'Geogebra elementet blev oprettet',
    'The Geogebra element has been saved': 'Geogebra elementet blev gemt',
    'The group is currently in exam mode. All communication suspended.': 'Holdet er i øjeblikket i eksamenstilstand. Al kommunikation er suspenderet.',
    'The question has been created': 'Spørgsmålet blev oprettet',
    'The question has been saved': 'Spørgsmålet blev gemt',
    'The quiz has been created': 'Quizzen blev oprettet',
    'The quiz has been saved': 'Quizzen blev gemt',
    'The system cannot auto-correct open answer questions, but your teacher can see your answer.': 'Systemet kan ikke automatisk rette åbne spørgsmål, men din underviser kan se dit svar.',
    'The system rejected the request with the following message': 'Systemet afviste operationen med følgende besked',
    'There are unsaved changes': 'Der er ændringer som ikke er gemt',
    'This course is a reference from another group, and can therefore not be edited here.': 'Dette forløb er en reference til et andet sted, og kan derfor ikke rettes her.',
    'This course has been copied to this group before.': 'Dette forløb er blevet kopieret til dette hold før.',
    'This element has been copied to this group before.': 'Dette element er blevet kopieret til dette hold før.',
    'This lesson has been copied to this group before.': 'Denne lektion er blevet kopieret til dette hold før.',
    'This group does not have a class notebook yet. Contact your teacher in order to fix this.': 'Dette hold har ikke en klassenotesbog endnu. Kontakt din underviser for at fikse det.',
    'this guide': 'denne guide',
    'This is a beta feature, and errors can occur': 'Dette er en eksperimentel funktion, og fejl kan opstå.',
    'This is a collection of all the videos from your courses': 'Dette er en samling af alle videoerne fra dine forløb',
    'This is an open answer question. This means that you have to reflect on the question, and you have to write an answer to complete it.': 'Dette er et \'åbent svar\' spørgsmål. Det betyder, at du skal reflektere og svare med dine egne ord, og du skal skrive noget for at fuldføre det.',
    'This is an overview of the students progression through the videos in the courses/materials.': 'Dette er et overblik over brugerens progression igennem videoerne i forløbene/lektionerne.',
    'This lesson is a reference from another group, and can therefore not be edited here.': 'Denne lektion er en reference til et andet sted, og kan derfor ikke rettes her.',
    'This lesson is empty.': 'Denne lektion er tom.',
    'This quiz type requires an active subscription to Maple TA, which you need to obtain yourself.': 'Denne quiztype kræver en aktiv licens til Maple TA\'s software, som du selv/din institution står for at anskaffe.',
    'This widget imports all of your courses automatically': 'Denne widget importerer automatisk alle dine forløb i rækkefølge',
    'This will hold news from your classes': 'Her vil der komme nyheder på tværs af dine hold',
    'Three quarter': 'Trekvart',
    'to': 'til',
    'To': 'Til',
    'Today': 'Idag',
    'Toggle fullscreen mode': 'Gå til fuldskærm',
    'Top': 'Top',
    'Topic': 'Underemne',
    'Topics': 'Underemner',
    'to join your class.': 'for at tilmelde sig holdet',
    'To top': 'Til top',
    'Total': 'Total',
    'Toggle fullscreen': 'Skift fuldskærm',
    'Total number of assignment turnins': 'Samlet antal af opgavebesvarelser',
    'Trainable': 'Muligt at træne',
    'Train mathematics': 'Træn matematik',
    'Translate': 'Oversæt',
    'Translate word': 'Oversæt ord',
    'Tries': 'Forsøg',
    'Try': 'Forsøg',
    'Try Edaptio': 'Prøv Edaptio',
    'Try now': 'Prøv nu',
    'Turkish': 'Tyrkisk',
    'Turnin overview': 'Afleveringsoversigt',
    'Turn in questionnaire': 'Aflever spørgeskema',
    'Turn in quiz': 'Aflever quiz',
    'Turn video off': 'Slå video fra',
    'Turn video on': 'Slå video til',
    'Type': 'Type',
    'Type in your message': 'Skriv besked her',
    'UK English': 'Engelsk (UK)',
    'UMS': 'UMS',
    'UMS group': 'UMS hold',
    'Unarchive': 'Fjern fra arkiv',
    'Unarchive group': 'Fjern hold fra arkiv',
    'Unilogin': 'Unilogin',
    'Unilogin username': 'Unilogin brugernavn',
    'Unknown': 'Ukendt',
    'Unlock board': 'Lås opslagstavle op',
    'Unlock board item': 'Lås opslag op',
    'Unmute': 'Slå lyd til',
    'Unpin': 'Frigør',
    'Unpin post': 'Frigør opslag',
    'Unpin side menu': 'Gør sidemenu flydende',
    'Unread messages': 'Ulæste',
    'Upcoming consultations': 'Kommende konsultationer',
    'Update profile picture': 'Opdater profilbillede',
    'Update and log in': 'Opdatér og log in',
    'updated the assignment': 'opdaterede afleveringen',
    'Upload': 'Upload',
    'Upload feedback' : 'Upload feedback',
    'Upload new file': 'Upload ny fil',
    'Uploads': 'Uploaded',
    'Upper border': 'Øvre grænse',
    'Use 0 if you want to disable it': 'Brug 0 hvis du vil slå beståelsesprocent fra',
    'Use in group': 'Brug på hold',
    'Use course in group': 'Brug forløb på hold',
    'Use hashtags to indicate word insertions': 'Brug hashtags til at oprette felter til indsættelse af ord.',
    'Use learning element in group': 'Brug læringsobjekt på hold',
    'Use material in group': 'Brug lektion på hold',
    'Use the arrow keys to switch and flip the cards': 'Brug pilepasterne på tastaturet til at skifte- og vende flashkort',
    'Use the feedback, improve your assignment and hand in again': 'Brug feedbacken, finpuds opgaven og aflever igen.',
    'Use this in a group': 'Brug dette på et hold',
    'User': 'Bruger',
    'User assignments behind': 'Brugere som er bagud med opgaver',
    'User assignment progression throughout courses': 'Brugerprogression i opgaver opdelt i forløb',
    'User created': 'Bruger oprettet',
    'User is presented with a single random word': 'Brugeren bliver præsenteret med et enkelt tilfældigt ord',
    'User is presented with all words': 'Brugeren bliver præsenteret med alle ord fra listen',
    'User name': 'Brugernavn',
    'User progression': 'Brugerprogression',
    'User quiz progression': 'Brugerprogression i quizzer',
    'User deleted': 'Bruger slettet',
    'User saved': 'Bruger gemt',
    'Users': 'Brugere',
    'Users not yet started': 'Brugere som ikke er startet endnu',
    'Users under average completion': 'Brugere der ligger under gennemsnittet for holdet',
    'Username': 'Brugernavn',
    'US English': 'Engelsk (US)',
    'Validating': 'Validerer',
    'Value': 'Værdi',
    'video-conference': 'Videohjælp',
    'Video': 'Video',
    'Video chat': 'Video chat',
    'Video chat does not exist or it\'s already finished.': 'Video chatten kunne ikke findes.',
    'Video id required': 'Video id påkrævet',
    'Video is off': 'Video slukket',
    'Video is on': 'Video tændt',
    'videos': 'Videoer',
    'Video preview': 'Video forhåndsvisning',
    'Video quiz': 'Video quiz',
    'Vietnamese': 'Vietnamesisk',
    'View': 'Vis',
    'Viewing as a student': 'Viser som en studerende',
    'Viewing as a teacher': 'Viser som en underviser',
    'View messages': 'Vis meddelelser ',
    'Vimeo': 'Vimeo',
    'Vimeo video id/URL': 'Vimeo video id/URL',
    'Visible to students': 'Synlig for brugere',
    'Visual arts': 'Billedkunst',
    'Voice gender': 'Køn på stemme',
    'wants to join': "vil gerne ind",
    'wants to join video chat': "vil gerne deltage i video chat",
    'webinars': 'webinar',
    'Weekdays-full-array': ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
    'Weekdays-short-array': ['Søn', 'Man', 'Tirs', 'Ons', 'Tors', 'Fre', 'Lør'],
    'Welsh': 'Walisisk',
    'We are preparing your file for embed, make sure to refresh the page in a little while. This should take no more than 2 minutes.': 'Vi er ved at forberede din fil til indlejring. Genindlæs siden om et lille stykke tid for at se resultatet. Dette bør ikke tage mere end 2 minutter.',
    'We can see, that you are using Internet Explorer to browse the web. This is an old, outdated and insecure browser, which is why we have chosen not to support it.': 'Vi kan se, at du bruger Internet Explorer til at gå på internettet. Dette er en gammel og usikker browser, som vi har valgt ikke længere at supportere. ',
    'We have sent an email to the provided email address, if it exists in the system. Remember to check your spam filter.': 'Vi har sendt en email til mail adressen hvis de eksisterer i vores system. Husk at tjekke dit spam filter.',
    'What are you?': 'Hvad er du?',
    'When is the assignment due?': 'Hvornår skal opgaven afleveres?',
    'What educational level?': 'Hvilket klassetrin?',
    'What media should the users turn in?': 'Hvilken type medie skal afleveres?',
    'What skills level?': 'Hvilket niveau?',
    'What type of assignment?': 'Hvilken type opgave?',
    'What would you like to learn about?': 'Hvad vil du gerne lære om?',
    'Who are you?': 'Hvem er du?',
    'Wiris match': 'Wiris match',
    'Wiris multiple choice': 'Wiris multiple choice',
    'Wiris short answer': 'Wiris kortsvar',
    'Write answer': 'Skriv svar',
    'Write comment': 'Skriv kommentar',
    'Write description': 'Skriv beskrivelse',
    'Write here': 'Skriv her',
    'Write message': 'Skriv besked',
    'Write the code from the marked area below in the text field, to confirm deletion': 'Skriv koden fra det markerede område ind i tekstfeltet, for at bekræfte sletningen',
    'Write the full sentence/text below. Use hashtags (#) before the words that need to be filled in. See': 'Skriv den fulde sætning/tekst nedenfor. Brug hashtags (#) før de ord som skal fyldes ind. Se',
    'Write what you hear': 'Skriv hvad du hører',
    'Wrong username/password': 'Forkert brugernavn/kodeord',
    'Year/date/title': 'År/data/titel',
    'Yes': 'Ja',
    'Yes, archive this group': 'Ja, arkivér dette hold',
    'Yes, delete this assignment': 'Ja, slet denne opgave',
    'Yes, delete this collection': 'Ja, slet denne kollektion',
    'Yes, delete this feedback': 'Ja, slet dette feedback',
    'Yes, delete this file': 'Ja, slet denne fil',
    'Yes, delete this folder': 'Ja, slet denne mappe',
    'Yes, delete this Geogebra element': 'Ja, slet dette Geogebra element',
    'Yes, delete this group': 'Ja, slet dette hold',
    'Yes, delete this group information': 'Ja, slet denne information',
    'Yes, delete this post': 'Ja, slet dette opslag',
    'Yes, delete this quiz': 'Ja, slet denne quiz',
    'Yes, delete this question': 'Ja, slet dette spørgsmål',
    'Yes, delete this user': 'Ja, slet denne bruger',
    'Yes, everyone can use this': 'Ja, alle må bruge dette',
    'Yes, turn in this questionnaire': 'Ja, aflever dette spørgeskema',
    'Yes, turn in this quiz': 'Ja, aflever denne quiz',
    'Yes, remove this course': 'Ja, fjern dette forløb',
    'Yes, remove this learning element': 'Ja, fjern dette læringselement',
    'Yes, remove this material': 'Ja, fjern denne lektion',
    'Yes, remove this member': 'Ja, fjern dette medlem',
    'Yes, start video call': 'Ja, start video kald',
    'Your assignment plan': 'Din afleveringsplan',
    'Your assignment turnin': 'Din opgaveaflevering',
    'Your class has multiple sub-groups. Choose which one to view for.': 'Dit hold har et eller flere underhold. Vælg hvilket et du vil se nedenfor.',
    'Your files have been uploaded and matched.': 'Dine filer er blevet uploaded og matched med feedback.',
    'Your streak': 'Din streak',
    'Youtube': 'Youtube',
    'Youtube video': 'Youtube video',
    'YouTube video ID': 'YouTube video ID',
    'Youtube video id': 'Youtube video id',
    'You answered correct': 'Du svarede korrekt',
    'You are currently in exam mode. All communication suspended.': 'Du er i øjeblikket i eksamenstilstand. Al kommunikation er suspenderet.',
    'You are in a waiting room': "Du er i venteværelset",
    'You are in training mode. Your answers are not recorded, and you get instant feedback. Start the quiz to complete it.': 'Du er i træningsdelen af quizzen og dine svar bliver ikke gemt. Start quizzen for at gennemføre den.',
    'You are up!': "Det er din tur!",
    'You are using Internet Explorer to browse. We do not guarantee support for this, and some areas of the page may not work. We encourage you to switch to any other browser.': 'Du bruger Internet Explorer til at tilgå vores side. Vi garanterer ikke support for denne browser, og dele af siden kan potentielt ikke virke. Vi anbefaler at skifte til en hvilken som helst anden browser.',
    'You can not access your assignments here in this group. This will serve as an overview only.': 'Du kan ikke tilgå dine opgaver denne vej, på dette hold. Denne app vil kun være et overblik her.',
    'You can find the course in your group': 'Du kan finde forløbet på dit hold',
    'You can find the element on the material': 'Du kan finde læringselementet på lektionen',
    'You can find the material on the course': 'Du kan finde lektionen på forløbet',
    'You can not enter the program this way.': 'Du kan ikke komme ind denne vej.',
    'You can not have a larger question pool than number of questions attached': 'Du kan ikke have en større spørgsmålspulje end antal spørgsmål tilknyttet',
    'You can only add admins here': 'Du kan kun tilføje undervisere her',
    'You can reject the assignment and force the student to hand in again': 'Du kan afvise afleveringen og opfordre brugeren til at aflevere igen',
    'You can see this guide to get started': 'Du kan se denne guide for at komme igang',
    'You did not choose all the correct answers': 'Du valgte ikke alle de korrekte svar',
    'You do not have much time left for this assignment': 'Du har ikke meget tid tilbage til denne opgave',
    'You have a good amount of time left for this assignment': 'Du har fint med tid til denne opgave',
    'You have already handed this assignment in.': 'Du har allerede afleveret denne opgave.',
    'You have answered all the questions in this topic. Keep training or go to the next topic.': 'Du har svaret på alle spørgsmål i dette underemne. Bliv ved med at træne eller gå til næste underemne.',
    'You have assigned duplicate rows to represent the same value': 'Du har angivet at flere rækker repræsenterer den samme værdi',
    'You have missed the due date, or the assignment has been rejected': 'Du har misset afleveringsdatoen for denne aflevering, eller den er blevet afvist.',
    'You have to have atleast one question for your quiz': 'Du skal have mindst ét spørgsmål tilknyttet til din quiz',
    'You must add either a group or a person to the conversation': 'Du skal enten tilføje mindst et hold eller en person',
    'You must choose minimum one answer': 'Du skal vælge minimum ét svar her',
    'You must choose more than one answer': 'Du skal vælge mere end ét svar her',
    'You must choose only one answer': 'Du skal kun vælge ét svar her',
    'You only need to choose one answer': 'Du skal kun vælge ét svar her',
    'You will automatically be added as admin in the group upon creation': 'Du vil automatisk blive tilføjet som underviser på holdet når du opretter.',
    'Your answer': 'Dit svar',
    'Your answer(s)': 'Dine svar',
    'Your answer percentage': 'Din svarprocent',
    'Your answer was not correct': 'Dit svar var ikke korrekt',
    'Your assignment answer': 'Din opgaveaflevering',
    'Your groups': 'Dine hold',
    'Your level': 'Dit niveau',
    'Your nickname': "Dit kaldenavn",
    'Your token is not valid or expired. Try again.': 'Dit  link er ikke godkendt, eller udløbet. Prøv igen.',
    'was archived by': 'blev arkiveret af',
    'Waiting list': "Venteliste",
    'Waiting for others to arrive': "Venter på at andre ankommer",
    'Watch out': 'Pas på',
    'We could not log you in via uni-login. This was either because you are not in the system yet, or because the school does not have access to the system.': 'Vi kunne ikke logge dig ind via uni-login. Dette er enten fordi du ikke er blevet synkroniseret endnu, eller også har din skole ikke adgang til systemet.',
    'What element would you like want to add?': 'Hvilket element vil du gerne tilføje?',
    'Wiris settings': 'Wiris-indstillinger',
    'Word': 'Ord',
    'Words': 'Ord',
    'Word list': 'Ordliste',
    'Wrong password': 'Forkert kodeord',
}
