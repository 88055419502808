import { DataClient } from '@edaptio/data-service-client/dist/edaptio.data.umd'

export default {
    install(Vue, options) {
        Vue.prototype.$dataCollector = new DataClient({
            dryRun: options.dryRun,
            debugMode: options.debugMode,
            dataServiceToken: options.dataServiceToken,
            dataServiceEndpointUrl: options.dataServiceEndpointUrl
        })
    }
}
