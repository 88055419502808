import axios from 'axios'

export default {

    createNewExternalLink(data) {
        return axios.post('/external-links', data)
    },

    saveExternalLink({ link_id, data }) {
        return axios.put('/external-links/'+link_id, data)
    },


}
