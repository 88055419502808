import axios from 'axios'

export default {

    async createTimeline(data) {
        return await axios.post(`/timelines`, data)
    },

    async editTimeline(data) {
        const { timeline_id, group_id, timeline_data } = data
        return await axios.put(`/timelines/${ timeline_id }`, {
            timeline_id : timeline_id,
            group_id : group_id,
            timeline_data : timeline_data
        })
    },

    async fetchTimelineData({ timeline_id }) {
        return await axios.get(`/timelines/${timeline_id}`)
    },


}
