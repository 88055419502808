import axios from 'axios'

export default {

    async fetchAllGroupNews(group_id) {
        return await axios.get('/news/group/'+group_id)
    },

    async pullAllUserNews() {
        return await axios.get('/news')
    },

    async registerNewsSeen({ group_id }) {
        return await axios.put(`/news/seen/${group_id}`, {})
    },

}
