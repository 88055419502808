import Vue from 'vue'
import courseAPI from '@/api/courseAPI'

export const courseStore = {
  // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
  namespaced: true,

  state: {
      courses : {},
      course_dependencies : {},
      course_completions : [],
      latest_reference_course_id : 0
  },


  getters: {

  },


  actions : {

      async addNewCourse({ commit, state }, { group_id, course_data }) {

          const course_id = course_data.group_course_id
          let found = false
          state.courses[group_id].map((course, index) => {
              if (parseInt(course.group_course_id) === course_id) {
                  found = true
              }
          })

          if (found) {
              commit('SAVE_COURSE', {
                  course_id  : course_id,
                  group_id : group_id,
                  title : course_data.title,
                  status : course_data.status
              })
          } else {
              commit('ADD_COURSE', {
                  group_id : parseInt(group_id),
                  course_data : course_data
              })
          }

      },

      clearCourseStore({ commit, state }, { group_id }) {
          commit('CLEAR_STORE', {
              group_id : group_id
          })
          return 1
      },

      async createCourse({ commit, state }, { group_id, title, hidden=false }) {
          const response = await courseAPI.createCourse({
              group_id : group_id,
              title : title,
              hidden : hidden
          })

          commit('ADD_COURSE', {
              group_id : parseInt(group_id),
              course_data : response.data
          })

          return response
      },

      /**
       * Function for getting course dependencies
       * @param group_id
       * @returns {Promise<*>}
       */
      async fetchCourseDependencies({ commit, state }, { group_id }) {

          const api_response = await courseAPI.fetchCourseDependencies({
              group_id : group_id
          })

          commit('SET_COURSE_DEPENDENCIES', {
              group_id : group_id,
              courses : api_response.data
          })

          return api_response

      },

      async fetchGroupCourses({ commit, state }, { group_id, force_refresh, reference_course_id = 0 }) {
          // check if set
          force_refresh = !!force_refresh
          if ((reference_course_id > 0) && (parseInt(reference_course_id) !== state.latest_reference_course_id)) {
              force_refresh = true
              commit('SET_REFERENCE_COURSE_ID', {
                  reference_course_id : parseInt(reference_course_id)
              })
          }

          if (!force_refresh && state.courses[group_id] && state.courses[group_id].length) {
              return state.courses[group_id]
          } else {

              const api_response = await courseAPI.fetchGroupCourses({
                  group_id : group_id,
                  reference_course_id : reference_course_id
              })

              commit('SET_GROUP_COURSES', {
                  group_id : group_id,
                  reference_course_id : reference_course_id,
                  courses : api_response.data
              })

              return api_response.data
          }

        },

        async getCourseCompletions ({ commit, state }) {

            const api_response = await courseAPI.getCourseCompletions()

            commit('SET_COURSE_COMPLETIONS', {
                courses : api_response.data
            })

            return api_response

        },

        /**
         * @desc Remove course
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Course data
         * @param {number} data.course_id
         * @param {number} [data.group_id]
         * @return {Promise} response
         */
        async removeCourse(vx, data) {
            const { commit } = vx
            const { course_id, group_id = null } = data

            const response = await courseAPI.removeCourse({
                course_id: parseInt(course_id),
            })

            // Update local state if group_id passed
            if (!!group_id) {
                commit('REMOVE_COURSE', {
                    group_id: parseInt(group_id),
                    course_id: parseInt(course_id),
                })
            }

            return response
        },

        async removeMaterialFromCourse({ commit, state }, { course_id, material_id }) {
            return await courseAPI.removeMaterialFromCourse({
                course_id : course_id,
                material_id  : material_id
            })
        },

        async saveCourse({ commit, state }, { course_id, group_id, title, status, dependencies }) {

          const api_response = courseAPI.saveCourse({
                group_id : group_id,
                course_id  : course_id,
                title : title,
                status : status,
                dependencies : dependencies
            })

            commit('SAVE_COURSE', {
                course_id  : course_id,
                group_id : group_id,
                title : title,
                status : status
            })

            return await api_response

        },

        async saveCourseDependencies({ commit, state }, { dependencies }) {

            const api_response = await courseAPI.saveCourseDependencies({
                dependencies : dependencies
            })

            commit('SET_COURSE_DEPENDENCIES', {
                group_id : group_id,
                courses : api_response.data
            })

            return api_response

        },

        async saveCoursesSequence({ commit, state }, { group_id, courses, reference_course_id }) {

            let formatted_courses = []
            let sorted_courses = []

            courses.forEach((course, index) => {
                formatted_courses.push(course.group_course_id)

                sorted_courses.push({
                    ...course,
                    sort_order: index,
                })

                return 1
            })

            const response = courseAPI.saveCoursesSequence({
                group_id : group_id,
                courses  : formatted_courses
            })

            commit('SET_GROUP_COURSES', {
                group_id : group_id,
                reference_course_id : reference_course_id,
                courses : courses
            })

            return await response
        },

  },


  mutations: {

        ADD_COURSE: (state, { group_id, course_data }) => {
            if (!state.courses[group_id]) {
                Vue.set(state.courses, group_id, [])
            }

            state.courses[group_id].push(course_data)
        },

        CLEAR_STORE: (state, { group_id }) => {
            Vue.set(state.courses, group_id, [])
        },

        REMOVE_COURSE: (state, data) => {
            const { group_id, course_id } = data

            if (!state.courses[group_id]) return 0

            const courseIndex = state.courses[group_id].findIndex(course => {
                return parseInt(course.group_course_id) === parseInt(course_id)
            })

            if (courseIndex === -1) return 0

            Vue.delete(state.courses[group_id], courseIndex)
        },

        SAVE_COURSE: (state, { course_id, group_id, title, status }) => {
          state.courses[group_id].map((course, index) => {
              if (parseInt(course.group_course_id) === parseInt(course_id)) {
                  Vue.set(state.courses[group_id][index], 'title', title)
                  Vue.set(state.courses[group_id][index], 'status', status)
              }
          })
        },

        SET_COURSE_COMPLETIONS: (state, { courses }) => {
            Vue.set(state, 'course_completions', courses)
        },

        SET_COURSE_DEPENDENCIES: (state, { group_id, courses }) => {
            // Vue.set(state.course_dependencies, group_id, {})
            Vue.set(state.course_dependencies, group_id, courses)
            // Object.entries(courses).forEach(([source_course_id, dependencies]) => {
            //     dependencies.map(depending_on_course_id => {
            //         Vue.set(state.course_dependencies[group_id], source_course_id, parseInt(depending_on_course_id))
            //     })
            // })
        },

        SET_GROUP_COURSES: (state, { group_id, reference_course_id, courses }) => {

            // reference_course_id = parseInt(reference_course_id)
            //
            // courses.map( (course,index) => {
            //     courses[index]['reference_course_id'] = reference_course_id
            // })

            Vue.set(state.courses, group_id, courses)


        },

        SET_REFERENCE_COURSE_ID: (state, { reference_course_id }) => {
            Vue.set(state, 'latest_reference_course_id', reference_course_id)
        }
    }
}
