import axios from 'axios'

export default {

    async archiveConversation({ conversation_id }) {
        return await axios.put(`/messages/conversation/archive/${conversation_id}`, {})
    },

    async createNewConversation({ title, groups, members }) {
        return await axios.post('/messages/conversations', {
            title : title,
            groups : groups,
            members : members
        })
    },

    async updateConversation({ id, title, groups, members }) {
        return await axios.put(`/messages/conversations/${id}`, {
            title : title,
            groups : groups,
            members : members
        })
    },

    async getConversationMessages({ conversation_id }) {
        return await axios.get(`/messages/${conversation_id}`)
    },

    async getConversations() {
        return await axios.get('/messages/conversations')
    },

    async markConversationRead({ conversation_id }) {
        return await axios.put(`/messages/seen/${conversation_id}`, {})
    },

    async markConversationUnread({ conversation_id }) {
        return await axios.put(`/messages/unseen/${conversation_id}`, {})
    },

    async sendNewMessage({ conversation_id , message_data }) {
        return await axios.post('/messages', {
            conversation_id : conversation_id,
            message_data : message_data
        })
    },

    /**
     * @desc Update message
     *
     * message_data = {
     *    message_text: String,
     *    attachments: ArrayOf(Object),
     * }
    */
    async updateMessage({ conversation_id, message_id, message_data }) {
      // TODO: Returns updated message object
      return await axios.put(`/messages/${message_id}`, {
          conversation_id : conversation_id,
          message_data : message_data,
      })
    },

    async addConversationMembers({ conversation_id, members_data }) {
        return await axios.post(`/messages/conversations/${conversation_id}/members`, members_data)
    },

    async removeConversationMember({conversation_id, user_id}){
        return await axios.delete(`/messages/conversations/${conversation_id}/member/${user_id}`)
    },

    async removeMessage({conversation_id, message_id}) {
        return await axios.delete(`/messages/conversations/${conversation_id}/message/${message_id}`)
    }
}
