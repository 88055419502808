import counsellorApi from '@/api/counsellorAPI'

export const counsellorStore = {
    namespaced: true,

    state: {
        activeCounsellors: [],
        activeCounsellorsSearchQuery: '',
        activeCounsellorsLimit: 10,
        counsellors: [],
        counsellorSearchQuery: '',
        counsellorsOffset: 0,
        counsellorsLimit: 10,
        counsellorId: null,
        counsellorStudents: [],
        studentsPossibleToAssign: [],
        studentPossibleToAssignSearchQuery: '',
        studentPossibleToAssignOffset: 0,
        studentPossibleToAssignLimit: 10,
        loadingActiveCounsellors: false,
        loadingCounsellors: false,
        loadingCounsellorStudents: false,
        loadingStudentPossibleToAssign: false,
        moreCounsellorsExist: false,
        moreStudentPossibleToAssignExist: false
    },
    mutations: {
        /**
         * Super mutation. Just to avoid writing lots of code
         * @param state
         * @param payload
         */
        mutate(state, payload) {
            if (Object.keys(state).indexOf(payload.what) > -1 && typeof payload.value !== 'undefined') {
                state[payload.what] = payload.value
            }
        }
    },
    actions: {
        /**
         * Load active counsellors
         * @param commit
         * @param state
         * @param query
         * @param limit
         * @returns {Promise<void>}
         */
        async loadActiveCounsellors({commit, state}, {query, limit}) {
            commit('mutate', { what: 'activeCounsellorsSearchQuery', value: query })
            commit('mutate', { what: 'activeCounsellorsLimit', value: limit })

            commit('mutate', { what: 'loadingActiveCounsellors', value: true })

            try {
                const searchResults = await counsellorApi.getCounsellors({
                    query: state.activeCounsellorsSearchQuery,
                    offset: 0,
                    limit: state.activeCounsellorsLimit,
                    active: 1
                })

                if (searchResults.data instanceof Array && searchResults.data.length > 0) {
                    commit('mutate', { what: 'activeCounsellors', value: searchResults.data })
                } else {
                    commit('mutate', { what: 'activeCounsellors', value: [] })
                }
            } catch (error) {
                commit('mutate', { what: 'activeCounsellors', value: [] })
            } finally {
                commit('mutate', { what: 'loadingActiveCounsellors', value: false })
            }
        },
        /**
         * Load counsellors
         * @param commit
         * @param state
         * @param query
         * @param offset
         * @param limit
         * @returns {Promise<void>}
         */
        async loadCounsellors({commit, state}, {query, offset, limit}) {
            commit('mutate', { what: 'counsellorSearchQuery', value: query })
            commit('mutate', { what: 'counsellorsOffset', value: offset })
            commit('mutate', { what: 'counsellorsLimit', value: limit })

            commit('mutate', { what: 'loadingCounsellors', value: true })

            try {
                const searchResults = await counsellorApi.getCounsellors({
                    query: state.counsellorSearchQuery,
                    offset: state.counsellorsOffset,
                    limit: state.counsellorsLimit
                })

                if (searchResults.data instanceof Array && searchResults.data.length > 0) {
                    commit('mutate', { what: 'counsellors', value: searchResults.data })
                    commit('mutate', { what: 'moreCounsellorsExist', value: true })
                } else {
                    commit('mutate', { what: 'counsellors', value: [] })
                    commit('mutate', { what: 'moreCounsellorsExist', value: false })
                }
            } catch (error) {
                commit('mutate', { what: 'counsellors', value: [] })
            } finally {
                commit('mutate', { what: 'loadingCounsellors', value: false })
            }
        },
        /**
         * Load counsellor students
         * @param commit
         * @param state
         * @param counsellorId
         * @returns {Promise<void>}
         */
        async loadCounsellorStudents({commit, state}, {counsellorId}) {
            commit('mutate', {  what: 'counsellorId', value: counsellorId })

            commit('mutate', {  what: 'loadingCounsellorStudents', value: true })

            try {
                const searchResults = await counsellorApi.getCounsellorStudents({
                    counsellorId: state.counsellorId
                })

                commit('mutate', {
                    what: 'counsellorStudents',
                    value: searchResults.data instanceof Array ? searchResults.data : []
                })
            } catch (error) {
                commit('mutate', { what: 'counsellorStudents', value: [] })
            } finally {
                commit('mutate', {  what: 'loadingCounsellorStudents', value: false })
            }
        },
        /**
         * Load possible counsellor students
         * @param commit
         * @param state
         * @param counsellorId
         * @param query
         * @param offset
         * @param limit
         * @returns {Promise<void>}
         */
        async loadStudentsPossibleToAssign({commit, state}, {counsellorId, query, offset, limit}) {
            commit('mutate', { what: 'counsellorId', value: counsellorId })
            commit('mutate', { what: 'studentPossibleToAssignSearchQuery', value: query })
            commit('mutate', { what: 'studentPossibleToAssignOffset', value: offset })
            commit('mutate', { what: 'studentPossibleToAssignLimit', value: limit })

            commit('mutate', { what: 'loadingStudentPossibleToAssign', value: true })

            try {
                const searchResults = await counsellorApi.getPossibleCounsellorStudents({
                    counsellorId: state.counsellorId,
                    query: state.studentPossibleToAssignSearchQuery,
                    offset: state.studentPossibleToAssignOffset,
                    limit: state.studentPossibleToAssignLimit
                })

                if (searchResults.data instanceof Array && searchResults.data.length > 0) {
                    commit('mutate', { what: 'studentsPossibleToAssign', value: searchResults.data })
                    commit('mutate', { what: 'moreStudentPossibleToAssignExist', value: true })
                } else {
                    commit('mutate', { what: 'studentsPossibleToAssign', value: [] })
                    commit('mutate', { what: 'moreStudentPossibleToAssignExist', value: false })
                }
            } catch (error) {
                commit('mutate', { what: 'studentsPossibleToAssign', value: [] })
            } finally {
                commit('mutate', { what: 'loadingStudentPossibleToAssign', value: false })
            }
        },
        /**
         * Assign student to counsellor
         * @param context
         * @param counsellorId
         * @param studentId
         * @returns {*|AxiosPromise<any>}
         */
        assignCounsellor(context, {counsellorId, studentId}) {
            return counsellorApi.assignUserCounsellor({
                counsellorUserId: counsellorId,
                userId: studentId
            })
        },
        /**
         * Unassign student from counsellor
         * @param context
         * @param counsellorId
         * @param studentId
         * @returns {*|AxiosPromise<any>}
         */
        unAssignCounsellor(context, {counsellorId, studentId}) {
            return counsellorApi.unAssignUserCounsellor({
                counsellorUserId: counsellorId,
                userId: studentId
            })
        }
    }
}
