var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("dialogue"),
      _vm._v(" "),
      _c("heartbeat"),
      _vm._v(" "),
      _c("video-chat-notification"),
      _vm._v(" "),
      !_vm.isLoading
        ? _c("navbar", {
            on: {
              logout: _vm.logout,
              setLanguage: function(lang) {
                return _vm.setLanguage(lang)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-content",
        [
          _vm.isLoading
            ? _c("loading-spinner", { attrs: { offsetTop: 250 } })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isLoading
            ? _c(
                "div",
                {
                  staticClass: "baseColor main-container pa-0 full-page-wrapper"
                },
                [
                  !_vm.isLoading
                    ? _c(
                        "v-slide-y-transition",
                        { attrs: { mode: "out-in" } },
                        [!_vm.isLoading ? _c("router-view") : _vm._e()],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("vue-snotify")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }