import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

/*
 *  SUBROUTES
*/
import adminRoutes from '@/router/subroutes/adminRoutes'
import studentAdministrationRoutes from '@/router/subroutes/studentAdministrationRoutes'
import groupRoutes from '@/router/subroutes/groupRoutes'
import profileRoutes from '@/router/subroutes/profileRoutes'


/*
 *   VIEWS
*/
const fourOThree = () => import('@/views/403')
const FourOFour = () => import('@/views/404')
const adaptiveTestView = () => import('@/views/adaptive-tests/adaptiveTestView')
const adaptiveTestsOverviewView = () => import('@/views/adaptive-tests/adaptiveTestsOverviewView')
const adminMainView = () => import('@/views/admin/adminMainView')
const bankCreateEditView = () => import( '@/views/bank/bankCreateEditView')
const bankItemView = () => import('@/views/bank/bankItemView')
const bankOverview = () => import('@/views/bank/bankOverview')
const conversationsView = () => import('@/views/messages/conversationsView')
const createNewConversationView = () => import('@/views/messages/createNewConversationView')
const dashboard = () => import(/* webpackPrefetch: true */'@/views/dashboard')
const frontpage = () => import('@/views/frontpage/frontpage')
const groupsMainView = () => import('@/views/groups/groupsMainView')
const groupsOverview = () => import(/* webpackPrefetch: true */'@/views/groups/groupsOverview')
const institutionFilesharingView = () => import('@/views/files/institutionFilesharingView')
const institutionNewsView = () => import('@/views/news/institutionNewsView.vue')
const LearnU = () => import('@/views/learnU/LearnU.vue')
const inviteToInstitutionView = () => import('@/views/invite/inviteToInstitutionView')
const joinClassSignupConfirm = () => import('@/views/join/joinClassSignupConfirm')
const joinInstitutionView = () => import('@/views/join/joinInstitutionView')
const joinView = () => import(/* webpackPrefetch: true */'@/views/join/joinView')
const login = () => import(/* webpackPrefetch: true */'@/views/login/loginView')
const messagesView = () => import('@/views/messages/messagesView')
const moodlelogin = () => import('@/views/login/moodleloginView')
const profileMainView = () => import('@/views/profile/profileMainView')
const resetPasswordView = () => import('@/views/login/resetPasswordView')
const searchMainView = () => import('@/views/search/searchMainView')
const signupConfirmView = () => import('@/views/sign-up/signupConfirmView')
const signupView = () => import(/* webpackPrefetch: true */'@/views/sign-up/signupView')
const sharedOverview = () => import('@/views/shared/sharedOverview')
const studentAdministrationOverview = () => import('@/views/studentadministration/studentAdministrationOverview')
const testView = () => import('@/views/testView')
const unilogin = () => import('@/views/login/uniloginVuew')
const videoChat = () => import('@/views/chat/videoChat')

// application routes
const routes = [
    {
        path: '/',
        component: frontpage,
        meta: { view : 'frontpage' }
    },
    {
        path: '/403',
        component: fourOThree,
        meta : { view : '403' }
    },
    {
        path: '/404',
        component: FourOFour,
        meta : { view : '404' }
    },
    {
        path: '/admin',
        component: adminMainView,
        children: adminRoutes,
        meta : { view : 'admin-main' }
    },
    {
        path: '/adaptive-test/:adaptive_test_id',
        component: adaptiveTestView,
        meta: { requiresAuth: true, view : 'adaptive-test' }
    },
    {
        path: '/adaptive-tests/:class_id?/:level_id?/:subject_id?',
        component: adaptiveTestsOverviewView,
        meta: { requiresAuth: true, view : 'adaptive-tests-overview' }
    },
    {
        path: '/administration',
        component: studentAdministrationOverview,
        meta: { requiresAuth: true, view : 'student-administration-main' },
        children : studentAdministrationRoutes
    },
    {
        path: '/bank',
        component: bankOverview,
        meta: { requiresAuth: true, view : 'bank' }
    },
    {
        path: '/bank/create',
        component: bankCreateEditView,
        meta: { requiresAuth: true, view : 'bank-create' }
    },
    {
        path: '/bank/item/:item_id/edit',
        component: bankCreateEditView,
        meta: { requiresAuth: true, view : 'bank-item-edit' }
    },
    {
        path: '/bank/item/:item_id',
        component: bankItemView,
        meta: { requiresAuth: true, view : 'bank-item' }
    },
    {
        path: '/dashboard',
        component: dashboard,
        meta: { requiresAuth: true, view : 'dashboard' }
    },
    {
        path: '/files',
        component: institutionFilesharingView,
        meta: { requiresAuth: true, view : 'institution-files' }
    },
    {
        path: '/groups',
        component: groupsOverview,
        meta: { requiresAuth: true, view: 'groups-overview' },
    },
    {
        path: '/groups/:group_id',
        component: groupsMainView,
        meta: { requiresAuth: true, view : 'groups-main' },
        children: groupRoutes
    },
    {
        path: '/institution/invite',
        component: inviteToInstitutionView,
        meta: {
            requiresAuth: true,
            view: 'invite-to-institution',
        },
    },
    {
        path: '/join/class/signup/confirm/:token',
        component: joinClassSignupConfirm,
        meta: { view: 'join-class-signup-confirm'}
    },
    {
        path: '/join/institution/:token',
        component: joinInstitutionView,
        meta: { view: 'join-institution'},
    },
    {
        path: '/join/:join_id?',
        component: joinView,
        meta: { view: 'join-view' },
    },
    {
        path: '/login/:error',
        component: login,
        meta : { view : 'login-error' }
    },
    {
        path: '/login',
        component: login,
        meta : { view : 'login' }
    },
    {
        path: '/messages/new',
        component: createNewConversationView,
        meta: { requiresAuth: true, view : 'create-conversation' },
    },
    {
        path: '/messages/:conversation_id',
        component: messagesView,
        meta: { requiresAuth: true, view : 'messages' },
    },
    {
        path: '/messages',
        component: conversationsView,
        meta: { requiresAuth: true, view : 'conversations' },
    },
    {
        path: '/moodle-login/:token/:course_id',
        component: moodlelogin,
        meta : { view : 'moodle-login' }
    },
    {
        path: '/news',
        component: institutionNewsView,
        meta: { requiresAuth: true, view : 'institution-news' }
    },
    {
        path: '/videochat/:id',
        component: LearnU,
        meta: { requiresAuth: true, view : 'learnu' }
    },
    {
        path: '/profile/:user_id',
        component: profileMainView,
        meta: { requiresAuth: true, view : 'profile-main' },
        children: profileRoutes
    },
    {
        path: '/reset-password/:token',
        component: resetPasswordView,
        meta: { view : 'reset-password' },
    },
    {
        path: '/search',
        component: searchMainView,
        meta: { requiresAuth: true, view : 'search' },
    },
    {
        path: '/signup',
        component: signupView,
        meta : { view : 'signup' }
    },
    {
        path: '/signup/confirm/:token',
        component: signupConfirmView,
        meta : { view : 'signup confirm' }
    },
    {
        path: '/shared-with-me',
        component: sharedOverview,
        meta: { requiresAuth: true, view: 'shared-overview' },
    },
    {
        path: '/uni-login/:token',
        component: unilogin,
        meta : { view : 'uni-login' }
    },
    {
        path: '/video-chat/:id',
        component: videoChat,
        meta : {
            requiresAuth: true,
            view : 'video-chat'
        }
    },
    {
        path: '/video-chat/:id/join/:token',
        redirect: (to) => {
            return {
                path: `/video-chat/${to.params.id}`,
                meta: {
                    zebra: '123'
                }
            }
        }
    },
    {
        path: '/test',
        component: testView,
        meta: { requiresAuth: true }
    },
    // every other non-existing route go to 404
    {
        path: '*',
        redirect: '/404'
    }
]

// export router instance
export default new Router({
  mode: 'history',
  routes
})
