import axios from 'axios'

export default {
    getBoard(id) {
        return axios.get(`/board/${id}`)
    },
    getBoardItems(id) {
        return axios.get(`/board/${id}/item`)
    },
    createBoard(data) {
        return axios.post('/board', data)
    },
    updateBoard(id, data) {
        return axios.put(`/board/${id}`, data)
    },
    createBoardItem(boardId, data) {
        return axios.post(`/board/${boardId}/item`, data)
    },
    updateBoardItem(boardId, itemId, data) {
        return axios.put(`/board/${boardId}/item/${itemId}`, data)
    },
    removeBoardItem(boardId, itemId) {
        return axios.delete(`/board/${boardId}/item/${itemId}`)
    }
}
