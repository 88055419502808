/*
  LANGUAGE FILE FOR ENGLISH LANG
 */

export default {
    'A-Niveau': 'A-Niveau',
    'Abandon': 'Abandon',
    'Abandon copying to this group': 'Abandon copying to this class',
    'Account already linked': 'Account already linked',
    'Account linked': 'Account linked',
    'Access Denied!': 'Access Denied!',
    'Actions': 'Actions',
    'Active': 'Active',
    'Activity': 'Activity',
    'Adaptive settings': 'Adaptive settings',
    'Adaptive test': 'Adaptive test',
    'Adaptive tests': 'Adaptive tests',
    'Add': 'Add',
    'Additional group': 'Additional class',
    'Add additional group': 'Add additional class',
    'Add answer': 'Add answer',
    'Add assignment': 'Add assignment',
    'Add attachment': 'Add attachment',
    'Add audio': 'Add audio',
    'Add board': 'Add pinboard',
    'Add board item': 'Add pinboard item',
    'Add category': 'Add category',
    'Add collection': 'Add collection',
    'Add course': 'Add course',
    'Add description': 'Add description',
    'Add event': 'Add event',
    'Add existing material': 'Add existing lesson',
    'Add expression': 'Add expression',
    'Add external link': 'Add external link',
    'Add feedback': 'Add feedback',
    'Add file': 'Add file',
    'Add files/folders': 'Add files/folders',
    'Add flashcard': 'Add flashcard',
    'Add folder': 'Add folder',
    'Add geogebra': 'Add Geogebra',
    'Add image': 'Add image',
    'Add information': 'Add information',
    'Add learning element': 'Add learning element',
    'Add image label': 'Add image label',
    'Add match set': 'Add match set',
    'Add material': 'Add lesson',
    'Add new course': 'Add new course',
    'Add new dependency': 'Add new dependency',
    'Add new flashcard': 'Add new flashcard',
    'Add new institution': 'Add new institution',
    'Add new item': 'Add new item',
    'Add new learning element': 'Add new learning element',
    'Add new match option': 'Add new match option',
    'Add new material': 'Add new lesson',
    'Add new question': 'Add new question',
    'Add new tag': 'Add new tag',
    'Add numeric variable': 'Add numeric variable',
    'Add only tags that do not exist yet. Make sure you have tried to search for the tag before adding.': 'Add only tags that do not exist yet. Make sure you have tried to search for the tag before adding.',
    'Add question': 'Add question',
    'Add question from questionbank': 'Add question from questionbank',
    'Add questionnaire': 'Add questionnaire',
    'Add quiz': 'Add quiz',
    'Add text': 'Add text',
    'Add text label': 'Add text label',
    'Add timeline': 'Add timeline',
    'Add to word list': 'Add to word list',
    'Add video': 'Add video',
    'Add Vimeo video': 'Add Vimeo video',
    'Add youtube video': 'Add youtube video',
    'Added': 'Added',
    'Add groups': 'Add classes',
    'Add new answer possibility': 'Add new answer',
    'Add new expression': 'Add new expression',
    'Add new nummeric variable': 'Add new nummeric variable',
    'Add member': 'Add member',
    'Add members': 'Add members',
    'Add participants': 'Add participants',
    'Add vacation/holiday': 'Add vacation/holiday',
    'Add word': 'Add word',
    'Add words to the word list. Any number of words from the list can be chosen at random.': 'Add words to the word list. Any number of words from the list can be chosen at random.',
    'Adjust your settings and start a call': 'Adjust your settings and start the call',
    'Admin': 'Admin',
    'Admins': 'Admins',
    'Administration': 'Administration',
    'Afrikaans': 'Afrikaans',
    'Albanian': 'Albanian',
    'All': 'All',
    'All classes, courses and content will be transferred, and nothing will be lost.': 'All classes, courses and content will be transferred, and nothing will be lost.',
    'All correct answers must be chosen': 'All correct answers must be chosen',
    'All correct answers must be selected': 'All correct answers must be selected',
    'All file types': 'All file types',
    'All topics': 'All topics',
    'All variables must have one hashtag in the beginning and lower threshhold must be lower than the upper threshhold': 'All variables must have one hashtag in the beginning and lower threshhold must be lower than the upper threshhold',
    'Allow counsellors': 'Allow counsellors',
    'Allow direct user creation in the group': 'Allow direct user creation in the group',
    'Allow groups': 'Allow classes',
    'Allow late turnin': 'Allow late submission',
    'Allow groups code join': 'Allow groups code join',
    'Allow questions on material elements': 'Allow questions on learning elements',
    'All participants from your class can access this call. If you want to invite others, send them a link.':'All participants from your class can access this call. If you want to invite others, send them the call link.',
    'Allow peers': 'Allow peers',
    'Allow students to see results': 'Allow students to see results',
    'Already have an account?': 'Already have an account?',
    'An error occurred': 'An error occurred',
    'An error occurred in the quiz. Please refresh your browser window, and if the error persists, contact the support.': 'An error occurred in the quiz. Please refresh your browser window, and if the error persists, contact the support.',
    'Ancient history': 'Ancient history',
    'Answer': 'Answer',
    'Answer date': 'Answer date',
    'Answer {number} more': 'Answer {number} more',
    'Answer evaluation criteria': 'Answer evaluation criteria',
    'Answer possibilities': 'Answers',
    'Answer possibility': 'Answer',
    'Answer the question to continue the video': 'Answer the question to continue the video',
    'Answers': 'Answers',
    'Answers must have a unique match option assigned': 'Answers must have a unique match option assigned',
    'Answers not in category': 'Answers not in category',
    'Appears on': 'Appears on',
    'Apps': 'Apps',
    'Arabic': 'Arabic',
    'Archive': 'Archive',
    'Archive group': 'Archive class',
    'Archive for all members': 'Archive for all members',
    'Archived': 'Archived',
    'Archived groups': 'Archived classes',
    'Are you a student or a teacher?': 'Are you a student or a teacher?',
    'Are you sure?': 'Are you sure?',
    'Are you sure you have checked the list thoroughly? Remember you can search it too!': 'Are you sure you have checked the list thoroughly? Remember you can search it too!',
    'Are you sure you want to archive this group for all members?': 'Are you sure you want to archive this class for all members?',
    'Are you sure you want to delete this?': 'Are you sure you want to delete this?',
    'Are you sure you want to delete this assignment?': 'Are you sure you want to delete this assignment?',
    'Are you sure you want to delete this collection?': 'Are you sure you want to delete this collection?',
    'Are you sure you want to delete this feedback?': 'Are you sure you want to delete this feedback?',
    'Are you sure you want to delete this file?': 'Are you sure you want to delete this file?',
    'Are you sure you want to delete this folder?': 'Are you sure you want to delete this folder? This will also remove any subfolder and file in the folder.',
    'Are you sure you want to delete this Geogebra element?': 'Are you sure you want to delete this Geogebra element?',
    'Are you sure you want to delete this group?': 'Are you sure you want to delete this class?',
    'Are you sure you want to delete this group information?': 'Are you sure you want to delete this class information?',
    'Are you sure you want to delete this post?': 'Are you sure you want to delete this post?',
    'Are you sure you want to delete this question?': 'Are you sure you want to delete this question?',
    'Are you sure you want to delete this quiz?': 'Are you sure you want to delete this quiz?',
    'Are you sure you want to delete this user?': 'Are you sure you want to delete this user?',
    'Are you sure you want to duplicate this question?': 'Are you sure you want to duplicate this question?',
    'Are you sure you want to leave the meeting?': 'Are you sure you want to leave the meeting?',
    'Are you sure you want to mark student as a self-study? This will move all assignments years into the future.': 'Are you sure you want to mark student as a self-study? This will move all assignments years into the future.',
    'Are you sure you want to recalculate assignment plan?': 'Are you sure you want to recalculate assignment plan?',
    'Are you sure you want to remove this answer?': 'Are you sure you want to remove this answer?',
    'Are you sure you want to remove this course?': 'Are you sure you want to remove this course?',
    'Are you sure you want to remove this label?': 'Are you sure you want to remove this label?',
    'Are you sure you want to remove this learning element?': 'Are you sure you want to remove this learning element?',
    'Are you sure you want to remove this member?': 'Are you sure you want to remove this member?',
    'Are you sure you want to remove this match set?': 'Are you sure you want to remove this match set?',
    'Are you sure you want to remove this material?': 'Are you sure you want to remove this lesson?',
    'Are you sure you want to remove this question?': 'Are you sure you want to remove this question?',
    'Are you sure you want to remove this subject?': 'Are you sure you want to remove this subject?',
    'Are you sure you want to remove this topic?': 'Are you sure you want to remove this topic?',
    'Are you sure you want to remove this variable?': 'Are you sure you want to remove this variable?',
    'Are you sure you want to remove this word?': 'Are you sure you want to remove this word?',
    'Are you sure you want to start a video call with this user?': 'Are you sure you want to start a video call with this user?',
    'Are you sure you want to turn in this questionnaire?': 'Are you sure you want to turn in this questionnaire?',
    'Are you sure you want to turn in this quiz?': 'Are you sure you want to turn in this quiz?',
    'Are you unsure? Click the button below to read a guide on this': 'Are you unsure? Click the button below to read a guide on this',
    'Armenian': 'Armenian',
    'Arrows': 'Arrows',
    'Ask to join': 'Ask to join',
    'Ask your students to go to': 'Ask your students to go to',
    'Assign': 'Assign',
    'Assign category': 'Assign category',
    'Assign student': 'Assign student',
    'Assign student to': 'Assign student to',
    'Assignment': 'Assignment',
    'assignment_objects': 'Assignment',
    'assignments': 'Assignments',
    'Assignments behind': 'Assignments behind',
    'Assignments completion percentage': 'Assignments completion percentage',
    'Assignment answer': 'Assignment answer',
    'Assignment created': 'Assignment created',
    'Assignment handin timeline': 'Assignment submission timeline',
    'Assignment handins/feedback statistics': 'Assignment submission/feedback statistics',
    'Assignment plans': 'Assignment plans',
    'Assignment rejected': 'Assignment rejected',
    'Assignment removed': 'Assignment removed',
    'Assignment saved': 'Assignment saved',
    'Assignment statistics': 'Assignment statistics',
    'Assignment text': 'Assignment text',
    'Assignment title': 'Assignment title',
    'Assignment turnins missing feedback': 'Assignment submissions missing feedback',
    'Assignment turnin overview': 'Assignment submissions overview',
    'At least one correct answer chosen': 'At least one correct answer chosen',
    'Attach media': 'Attach media',
    'Attachments': 'Attachments',
    'Attachment': 'Attachment',
    'Average on teams': 'Average for class',
    'Average quiz answer percentage for group': 'Average quiz answer percentage for class',
    'Average user completion': 'Average user completion',
    'Audio': 'Audio',
    'Audio is on': 'Audio is on',
    'Australian': 'Australian',
    'Awaiting feedback': 'Awaiting feedback',
    'B-Niveau': 'B-Niveau',
    'Back': 'Back',
    'Back to courses': 'Back to courses',
    'Back to dashboard': 'Back to dashboard',
    'Back to groups': 'Back to classes',
    'Back to overview': 'Back to overview',
    'Back to quizzes': 'Back to quizzes',
    'Bank': 'Bank',
    'Below are download links to the most popular browsers, in case you do not have them installed': 'Below are download links to the most popular browsers, in case you do not have them installed',
    'Biology': 'Biology',
    'Board': 'Pinboard',
    'Bosnian': 'Bosnian',
    'Brazilian Portuguese': 'Brazilian Portuguese',
    'Business economics': 'Business economics',
    'C-Niveau': 'C-Niveau',
    'Ca': 'Ca',
    'Calculate': 'Calculate',
    'Calculate this expression': 'Calculate this expression',
    'Camera': 'Camera',
    'Cancel': 'Cancel',
    'Cancel call': 'Cancel call',
    'Case sensitive': 'Case sensitive',
    'Catalan': 'Catalan',
    'Categories': 'Categories',
    'Category': 'Category',
    'changed the feedback for the assignment': 'changed the feedback for the assignment',
    'Changelog': 'Changelog',
    'Change group': 'Change class',
    'Change image': 'Change image',
    'Change moodle relation': 'Change moodle relation',
    'Change UMS relation': 'Change UMS relation',
    'characters': 'characters',
    'chat': 'Chat',
    'Chat': 'Chat',
    'Checkbox': 'Checkbox',
    'Check your inbox!': 'Check your inbox!',
    'Chemestry': 'Chemestry',
    'Change submission date for user': 'Change submission date for user',
    'Chinese': 'Chinese',
    'Connect Facebook': 'Connect Facebook',
    'Connect Google': 'Connect Google',
    'Connect your account': 'Connect your account',
    'Choose': 'Choose',
    'Choose another element': 'Choose another element',
    'Choose another group': 'Choose another class',
    'Choose assignment': 'Choose assignment',
    'Choose assignment from group': 'Choose assignment from class',
    'Choose between seeing quizzes the user has passed or completed.': 'Choose between seeing quizzes the user has passed or completed.',
    'Choose class': 'Choose class',
    'Choose collection': 'Choose collection',
    'Choose collection from group': 'Choose collection from class',
    'Choose column representation': 'Choose column representation',
    'Choose correct match option': 'Choose correct match option',
    'Choose course': 'Choose course',
    'Choose CSV file': 'Choose CSV file',
    'Choose date': 'Choose date',
    'Choose element': 'Choose element',
    'Choose element type': 'Choose element type',
    'Choose file from filesharing': 'Choose file from filesharing',
    'Choose files': 'Choose files',
    'Choose from existing materials': 'Choose from existing lessons',
    'Choose gender': 'Choose gender',
    'Choose Geogebra': 'Choose Geogebra',
    'Choose Geogebra from group': 'Choose Geogebra from class',
    'Choose grade': 'Choose grade',
    'Choose group': 'Choose group',
    'Choose institution': 'Choose institution',
    'Choose material': 'Choose lesson',
    'Choose members': 'Choose members',
    'Choose language': 'Choose language',
    'Choose learning element': 'Choose learning element',
    'Choose lesson': 'Choose lesson',
    'Choose new file': 'Choose new file',
    'Choose questionnaire': 'Choose questionnaire',
    'Choose questionnaire from group': 'Choose questionnaire from class',
    'Choose quiz': 'Choose quiz',
    'Choose quiz from group': 'Choose quiz from class',
    'Choose random words': 'Choose random words',
    'Choose subgroup': 'Choose subgroup',
    'Choose the default language of the group': 'Choose the default language of the class',
    'Choose type': 'Choose type',
    'Choose user': 'Choose user',
    'Choose weekday': 'Choose weekday',
    'Choose what this group is teaching': 'Choose what this class is teaching',
    'Chosen': 'Chosen',
    'Chosen file': 'Chosen file',
    'Chosen group': 'Chosen class',
    'Chosen user': 'Chosen user',
    'Class': 'Class',
    'Classes': 'Classes',
    'Classic': 'Classic',
    'Class notebook link': 'Class notebook link',
    'Clear': 'Clear',
    'Click enter to submit': 'Click enter to submit',
    'Click here to add a new learning element or edit the selected element': 'Click here to add a new learning element or edit the selected element',
    'Click here to add existing material, create a new material or change the current material order': 'Click here to add existing lesson, create a new lesson or change the current lesson order',
    'Click here to create a new assignment': 'Click here to create a new assignment',
    'Click here to create a new conference post': 'Click here to create a new conference post',
    'Click here to create a new conversation with a student or a group': 'Click here to create a new conversation with a student or a class',
    'Click here to create a new course or change the current course order': 'Click here to create a new course or change the current course order',
    'Click here to create a new Geogebra element': 'Click here to create a new Geogebra element',
    'Click here to create a new material': 'Click here to create a new lesson',
    'Click here to create a new news post': 'Click here to create a new news post',
    'Click here to create a new quiz': 'Click here to create a new quiz',
    'Click here to create a new question': 'Click here to create a new question',
    'Click here to edit the group information text': 'Click here to edit the class information text',
    'Click here to go to your class notebook': 'Click here to go to your class notebook',
    'Click left/right arrows to go to next/previous card': 'Click left/right arrows to go to next/previous card',
    'Click on the answer to the left, and then a match option to the right, in order to make a connection.': 'Click on the answer to the left, and then a match option to the right, in order to make a connection.',
    'Click on the card to see the back': 'Click on the card to see the back',
    'Click on the card to see the front': 'Click on the card to see the front',
    'Click the add learning element button in the menu on the left': 'Click the add learning element button in the menu on the left',
    'Click the create button to get started': 'Click the create button to get started',
    'Click the big button in the lower right corner to get started': 'Click the big button in the lower right corner to get started',
    'Click the button above to begin': 'Click the button above to begin',
    'Click the button to add the first learning element': 'Click the button to add the first learning element',
    'Click the create button on the left side to get started': 'Click the create button on the left side to get started',
    'Click this button and click one of the sub-buttons to create a new folder or upload a new file': 'Click this button and click one of the sub-buttons to create a new folder or upload a new file',
    'Click this button to open the menu with your groups on all pages': 'Click this button to open the menu with your classes on all pages',
    'Click to start edit': 'Click to start edit',
    'Click up/down arrows to flip card': 'Click up/down arrows to flip card',
    'Close': 'Close',
    'cloze': 'Fill in the blank (Cloze)',
    'code': 'code',
    'Collapse': 'Collapse',
    'Color': 'Color',
    'Color can\'t be empty.': 'Color can\'t be empty.',
    'Comment created': 'Comment created',
    'Comment removed': 'Comment removed',
    'Completed': 'Completed',
    'Completed consultations': 'Completed consultations',
    'conference': 'Conference',
    'Conference thread': 'Conference thread',
    'Confirm': 'Confirm',
    'consultations': 'Consultations',
    'Contact support': 'Contact support',
    'Contact support@edaptio.com for more info': 'Contact support@edaptio.com for more info',
    'Continue': 'Continue',
    'Continue where you left off': 'Continue where you left off',
    'Conversations': 'Conversations',
    'Conversation title': 'Conversation title',
    'Collapse all': 'Collapse all',
    'Collection': 'Collection',
    'Collections': 'Collections',
    'Complete registration': 'Complete registration',
    'Copied': 'Copied',
    'Copied to clipboard': 'Copied to clipboard',
    'Copy': 'Copy',
    'Copy answer to the feedback field': 'Copy answer to the feedback field',
    'Copy course': 'Copy course',
    'Copy course and bypass sync': 'Copy course and bypass sync',
    'Copy course and sync': 'Copy course and sync',
    'Copy element': 'Copy element',
    'Copy element to these groups?': 'Copy element to these classes?',
    'Copy element to this group?': 'Copy element to this class?',
    'Copy file': 'Copy file',
    'Copy file url to clipboard': 'Copy file url to clipboard',
    'Copy group': 'Copy class',
    'Copy groups': 'Copy classes',
    'Copy element and bypass sync': 'Copy element and bypass sync',
    'Copy element and sync': 'Copy element and sync',
    'Copy element to other group': 'Copy element to other class',
    'Copy existing question': 'Copy existing question',
    'Copy lesson': 'Copy lesson',
    'Copy lesson and bypass sync': 'Copy lesson and bypass sync',
    'Copy lesson and sync': 'Copy lesson and sync',
    'Copy link': 'Copy link',
    'Copy new adaptive test': 'Copy new adaptive test',
    'Copy of': 'Copy of',
    'Copy to clipboard': 'Copy to clipboard',
    'Copy to other groups': 'Copy to other classes',
    'Corrected': 'Corrected',
    'Correct': 'Correct',
    'Correct answer': 'Correct answer',
    'Correct answer(s)': 'Correct answer(s)',
    'Correct assignment': 'Correct assignment',
    'Correct using DirectEdit': 'Correct using DirectEdit',
    'Corresponding answer': 'Corresponding answer',
    'Counsellor': 'Counsellor',
    'Counsellors': 'Counsellors',
    'Counsellors list': 'Counsellors list',
    'Course': 'Course',
    'Course moved': 'Course moved',
    'courses': 'Courses',
    'Course created': 'Course created',
    'Course removed': 'Course removed',
    'Course statistics': 'Course statistics',
    'Course title': 'Course title',
    'Create': 'Create',
    'Created': 'Created',
    'Create another': 'Create another',
    'Create assignment': 'Create assignment',
    'Create assignments in bulk': 'Create assignments in bulk',
    'Create and release feedback': 'Create and release feedback',
    'Create and show': 'Create and show',
    'Create board item': 'Create pinboard item',
    'Create class': 'Create class',
    'Create collection': 'Create collection',
    'Create consultation' : 'Create consultation',
    'Create feedback': 'Create feedback',
    'Create fields and drag them to the desired location on the image. You can switch between image and text. Adjust the \'matches with\' field to group together fields, that can be placed in the same places in the picture.': 'Create fields and drag them to the desired location on the image. You can switch between image and text. Adjust the \'matches with\' field to group together fields, that can be placed in the same places in the picture.',
    'Create folder': 'Create folder',
    'Create Geogebra element': 'Create Geogebra element',
    'Create group': 'Create class',
    'Create image label': 'Create image label',
    'Create news post': 'Create news post',
    'Create new conversation': 'Create new conversation',
    'Create new course': 'Create new course',
    'Create new group': 'Create new class',
    'Create new material': 'Create new lesson',
    'Create new question': 'Create new question',
    'Create conference post': 'Create conference post',
    'Create question': 'Create question',
    'Create questionnaire': 'Create questionnaire',
    'Create quiz': 'Create quiz',
    'Create question and show': 'Create question and show',
    'Create quiz and show': 'Create quiz and show',
    'Create permission group': 'Create permission group',
    'Create post': 'Create post',
    'Create sub group': 'Create sub group',
    'Create subject': 'Create subject',
    'Create text': 'Create text',
    'Create text label': 'Create text label',
    'Create timeline': 'Create timeline',
    'Create topic': 'Create topic',
    'Create user': 'Create user',
    'Create your account': 'Create your account',
    'created the assignment': 'created the assignment',
    'Croatian': 'Croatian',
    'Currently unavailable': 'Currently unavailable',
    'Czech': 'Czech',
    'Danish': 'Danish',
    'Dansk': 'Dansk',
    'dashboard': 'Dashboard',
    'Dashboard': 'Dashboard',
    'dashboard-new-teacher-greeting': 'You do not have any active classes on edaptio yet. <br/> Click below to create your first class.',
    'Date': 'Date',
    'Delete group information': 'Delete class information',
    'Delete': 'Delete',
    'Definition': 'Definition',
    'Depending on following courses': 'Depending on following courses',
    'Description': 'Description',
    'Description too long': 'Description too long',
    'Descriptive text': 'Descriptive text',
    'Design': 'Design',
    'Deutsch': 'German',
    'Dialog': 'Dialog',
    'Difficulty': 'Difficulty',
    'Direct edit': 'Direct edit',
    'Disable assignment links in assignments app': 'Disable assignment links in assignments app',
    'Disable video': 'Disable video',
    'Disable audio': 'Disable audio',
    'Dive deeper by choosing course and lesson.': 'Dive deeper by choosing course and lesson.',
    'Document': 'Document',
    'Documents': 'Documents',
    'Done': 'Done',
    'Download': 'Download',
    'Download as csv': 'Download as csv',
    'Download original file': 'Download original file',
    'Download zip': 'Download zip file',
    'Do not create sub group': 'Do not create sub group',
    'Do not join': 'Do not join',
    'Do not use deadline': 'Do not use deadline',
    'Do you know the answer?': 'Do you know the answer?',
    'Do you need help?': 'Do you need help?',
    'Do you really want to leave? you have unsaved changes!': 'Do you really want to leave? you have unsaved changes!',
    'Do you want to abandon the process?': 'Do you want to abandon the process?',
    'Do you want to continue, but only copy new elements?': 'Do you want to continue, but only copy new elements?',
    'Do you want to enable camera after joining call?': 'Do you want to enable camera after joining call?',
    'Do you want to end this call for all participants or just leave this conversation?': 'Do you want to end this call for all participants or just leave this conversation?',
    'Do you want to evenly distribute the rest of the users assignments afterwards?': 'Do you want to evenly distribute the rest of the users assignments afterwards?',
    'Do you want to remove': 'Do you want to remove',
    'Do you want to you want to leave current call?': 'Do you want to you want to leave current call?',
    'Do you want to you want to leave current webinar?': 'Do you want to you want to leave current webinar?',
    'Do you want to synchronize elements that have already been copied?': 'Do you want to synchronize elements that have already been copied?',
    'Do you want to test out some of our math materials?': 'Do you want to test out some of our math materials?',
    'Do you wish to join the institution': 'Do you wish to join the institution',
    'Do you wish to share this with everyone?': 'Do you wish to share this with everyone?',
    'Drag and drop labels into this box to create alternative (wrong) labels': 'Drag and drop labels into this box to create alternative (wrong) labels',
    'Drag and drop on image': 'Drag and drop on image',
    'Drama': 'Drama',
    'Dropout members': 'Dropout members',
    'Dropped out': 'Dropped out',
    'Due_date': 'Due date',
    'Due date': 'Due date',
    'Due date required': 'Due date required',
    'Duplicate': 'Duplicate',
    'Duplicate assignment': 'Duplicate assignment',
    'Duplicate flashcard': 'Duplicate flashcard',
    'Duplicate question': 'Duplicate question',
    'Dutch': 'Dutch',
    'Economics': 'Economics',
    'Edaptio group': 'Edaptio class',
    'Edit': 'Edit',
    'Edit assignment': 'Edit assignment',
    'Edit audio': 'Edit audio',
    'Edit board': 'Edit pinboard',
    'Edit board item': 'Edit pinboard item',
    'Edit collection': 'Edit collection',
    'Edit course': 'Edit course',
    'Edit courses order': 'Edit courses order',
    'Edit external link': 'Edit external link',
    'Edit file': 'Edit file',
    'Edit flashcard': 'Edit flashcard',
    'Edit flashcards': 'Edit flashcards',
    'Edit geogebra': 'Edit geogebra',
    'Edit Geogebra element': 'Edit Geogebra element',
    'edit-group': 'Edit class',
    'Edit group': 'Edit class',
    'Edit group information': 'Edit class information',
    'Edit group name': 'Edit class name',
    'Edit holiday': 'Edit holiday',
    'Edit image': 'Edit image',
    'Edit image label': 'Edit image label',
    'Edit material': 'Edit lesson',
    'Edit materials order': 'Edit lesson order',
    'Edit message': 'Edit message',
    'Edit notebook': 'Edit notebook',
    'Edit permission group': 'Edit permission group',
    'Edit post': 'Edit post',
    'Edit question': 'Edit question',
    'Edit questionnaire': 'Edit questionnaire',
    'Edit questions': 'Edit questions',
    'Edit quiz': 'Edit quiz',
    'Edit timeline': 'Edit timeline',
    'Edit selected learning element': 'Edit selected learning element',
    'Edit subject': 'Edit subject',
    'Edit tests': 'Edit tests',
    'Edit text': 'Edit text',
    'Edit text label': 'Edit text label',
    'Edit topic': 'Edit topic',
    'Edit user': 'Edit user',
    'Element': 'Element',
    'Element already in lesson': 'Element already in lesson',
    'Element moved': 'Element moved',
    'Elements': 'Elements',
    'Edit video': 'Edit video',
    'Edit vimeo': 'Edit vimeo',
    'Edit youtube': 'Edit youtube',
    'Email': 'Email',
    'email': 'email',
    'Email / Username': 'Email / Username',
    'Email already exists': 'Email already exists',
    'Embedded': 'Embedded',
    'Enable camera': 'Enable camera',
    'Enable video': 'Enable video',
    'Enable audio': 'Enable audio',
    'End chat': 'End chat',
    'End for all': 'End for all',
    'English': 'English',
    'Enter class code': 'Enter class code',
    'Enter colleague email': 'Enter colleague email',
    'Enter your nickname to join this call': 'Enter your nickname to join this call',
    'Error': 'Error',
    'Error occurred while trying to load your text. Please contact us to resolve this issue or simply re-enter text.': 'Error occurred while trying to load your text. Please contact us to resolve this issue or simply re-enter text.',
    'Error occurred during video chat. Please contact us to resolve this issue or try to restart video chat.': 'Error occurred during video chat. Please contact us to resolve this issue or try to restart video chat.',
    'Error: time must be in HH:MM:SS format': 'Error: time must be in HH:MM:SS format',
    'Esperanto': 'Esperanto',
    'Evaluate all words': 'Evaluate all words',
    'Event': 'Event',
    'Events': 'Events',
    'Exam': 'Exam',
    'Exam mode': 'Exam mode',
    'Exam mode limits the students communication features for an entire class during an exam. Can be turned on/off at any time, and works right away.': 'Exam mode limits the students communication features for an entire class during an exam. Can be turned on/off at any time, and works right away.',
    'Exit fullscreen': 'Exit fullscreen',
    'Expand': 'Expand',
    'Expand all': 'Expand all',
    'Expressions': 'Expressions',
    'External link': 'External link',
    'External service forgotten error': 'You cannot request a new password on this institution, as you use an external single sign-on provider. Contact the administration on your institution.',
    'External service not linked': 'External service not linked to any user',
    'External user id': 'External user id',
    'Failed to activate video chat. Please contact us.': 'Failed to activate video chat. Please contact us.',
    'Failed to join video chat. Maybe you re not a participant of video chat. Try to ask to join': 'Failed to join video chat. Maybe you re not a participant of video chat. Try to ask to join',
    'Failed to lock\\unlock board': 'Failed to lock\\unlock pinboard',
    'Failed to lock\\unlock board item': 'Failed to lock\\unlock pinboard item',
    'Failed to remove board item': 'Failed to remove pinboard item',
    'Failed to save board item': 'Failed to save pinboard item',
    'Failed to save information': 'Failed to save information',
    'Failed to start video chat. Please contact us.': 'Failed to start video chat. Please contact us.',
    'Failed to subscribe to board updates. Try to refresh board': 'Failed to subscribe to pinboard updates. Try to refresh pinboard',
    'Failed to subscribe to conference updates. Try to refresh conference': 'Failed to subscribe to conference updates. Try to refresh conference',
    'Failed to subscribe to news updates. Try to refresh news': 'Failed to subscribe to news updates. Try to refresh news',
    'Failed to subscribe to page updates. Try to refresh the page': 'Failed to subscribe to page updates. Try to refresh the page',
    'Failed to subscribe to post answers updates. Try to refresh post answers': 'Failed to subscribe to post answers updates. Try to refresh post answers',
    'Failed to delete information': 'Failed to delete information',
    'FAQ': 'FAQ',
    'Feedback': 'Feedback',
    'Feedback created': 'Feedback created',
    'Feedback for this quiz try': 'Feedback for this quiz try',
    'Feedback from past assignments': 'Feedback from past assignments',
    'Feedback with this answer': 'Feedback with this answer',
    'Feedback for answer': 'Feedback for answer',
    'Feedback on this answer': 'Feedback on this answer',
    'Feedback released': 'Feedback released',
    'Feedback saved': 'Feedback saved',
    'Female': 'Female',
    'Fields': 'Fields',
    'File': 'File',
    'Files': 'Files',
    'File added': 'File added',
    'File removed': 'File removed',
    'File saved': 'File saved',
    'File path': 'File path',
    'File too big, please upgrade your account to increase file size limit. (Trial limit:': 'File too big, please upgrade your account to increase file size limit. (Trial limit:',
    'Files added': 'Files added',
    'file_sharing': 'File sharing',
    'Filter': 'Filter',
    'Fill in the blank': 'Fill in the blank',
    'Fill in the blank (Cloze)': 'Fill in the blank (Cloze)',
    'Finish': 'Finish',
    'Finnish': 'Finnish',
    'Firstname': 'Firstname',
    'First handin': 'First submission',
    'Fixed assignment date': 'Fixed assignment date',
    'Flexible assignment date': 'Flexible assignment date',
    'Float': 'Float',
    'Folders': 'Folders',
    'Folder added': 'Folder added',
    'Folder removed': 'Folder removed',
    'Folder saved': 'Folder saved',
    'Forgot password?': 'Forgot password?',
    'Forgotten password': 'Forgotten password',
    'for clarification.': 'for clarification.',
    'Fraction': 'Fraction',
    'Fractions': 'Fractions',
    'Flashcard': 'Flashcard',
    'flashcards': 'Flashcards',
    'Flashcards': 'Flashcards',
    'French': 'French',
    'From': 'From',
    'from this call?': 'from this call?',
    'Full': 'Full',
    'Fullscreen': 'Fullscreen',
    'gave feedback for the assignment': 'gave feedback for the assignment',
    'Gender': 'Gender',
    'General': 'General',
    'General chat': 'General chat',
    'General feedback': 'General feedback',
    'General question types': 'General question types',
    'geogebra': 'Geogebra',
    'Geogebra': 'Geogebra',
    'Geogebra element': 'Geogebra element',
    'Geogebra element removed': 'Geogebra element removed',
    'Geogebra id': 'Geogebra id',
    'Geogebra url or id': 'Geogebra url or id',
    'Geography': 'Geography',
    'German': 'German',
    'Get started': 'Get started',
    'Get started using Edaptio for free': 'Get started using Edaptio for free',
    'Get started with Edaptio': 'Get started with Edaptio',
    'Get this code/URL from your teacher.': 'Get this code/URL from your teacher.',
    'Get turnins as zip': 'Get submissions as zip',
    'Go to dashboard': 'Go to dashboard',
    'Go to group': 'Go to class',
    'Go to guide': 'Go to guide',
    'Go to link': 'Go to link',
    'Go to the class': 'Go to the class',
    'Go to the lesson view to add learning elements!': 'Go to the lesson view to add learning elements!',
    'Good afternoon': 'Good afternoon',
    'Good evening': 'Good evening',
    'Good morning': 'Good morning',
    'Grade': 'Grade',
    'Grade scale': 'Grade scale',
    'grade_scale.danish': 'Danish 7-step scale',
    'grade_scale.letters': 'Letters A-F',
    'grade_scale.numbers': 'Numbers 1-6',
    'grade_scale.romanDTU': 'Roman numbers I-IV',
    'Greek': 'Greek',
    'Group': 'Class',
    'Group with': 'Group with',
    'group_courses': 'Course',
    'groups': 'Classes',
    'Groups in exam mode': 'Classes in exam mode',
    'group-information': 'Information',
    'group-statistics': 'Class statistics',
    'Group created': 'Class created',
    'Group hand-in allowed': 'Group hand-in allowed',
    'Group name': 'Class name',
    'Group archived': 'Class archived',
    'Group unarchived': 'Class unarchived',
    'Group saved': 'Class saved',
    'Group type': 'Holdtype',
    'Half': 'Half',
    'Hatian Creole': 'Hatian Creole',
    'Handed in': 'Handed in',
    'Handed in after deadline': 'Handed in after deadline',
    'has a hand up': 'has a hand up',
    'has started a video-chat. Click here to attend:': 'has started a video-chat. Click here to attend:',
    'Help': 'Help',
    'Help center': 'Help center',
    'Here you can copy all your group contents to another group. Remember that elements you have previously copied will only be synchronized, and not copied again.': 'Here you can copy all your class contents to another class. Remember that elements you have previously copied will only be synchronized, and not copied again.',
    'Here you can put groups in exam mode. Exam mode means that all users in the particular group can not use any communication tools on the platform. E.g messages, news and conference': 'Here you can put classes in exam mode. Exam mode means that all users in the particular class can not use any communication tools on the platform. E.g messages, news and conference',
    'HH:MM:SS': 'HH:MM:SS',
    'Hidden lesson': 'Hidden lesson',
    'Hide element': 'Hide element',
    'Hide menu': 'Hide menu',
    'Hide non due date assignments': 'Hide non due date assignments',
    'Hide/show side menu': 'Hide/show side menu',
    'Hidden': 'Hidden',
    'Hidden assignment': 'Hidden assignment',
    'Hidden course': 'Hidden course',
    'Hidden folder': 'Hidden folder',
    'Hidden from students': 'Hidden from students',
    'Hidden material': 'Hidden lesson',
    'Hindi': 'Hindi',
    'History': 'History',
    'Home': 'Home',
    'How do i log in?': 'How do i log in?',
    'Hungarian': 'Hungarian',
    'Icelandic': 'Icelandic',
    'Identifier': 'Identifier',
    'If unchecked, the user will be presented with all words form the word list.': 'If unchecked, the user will be presented with all words form the word list.',
    'If you have been sent here by your teacher, then you already have a login. So make sure to ask them how to login and for your credentials.': 'If you have been sent here by your teacher, then you already have a login. So make sure to ask them how to login and for your credentials.',
    'If you have downloaded your assignments via the "download as zip" button, then you can upload the feedback files directly here. We will automatically generate feedback and attach the files. Make sure you only upload files you have not uploaded before, and only for submissions you have not given feedback to already.' : 'If you have downloaded your assignments via the "download as zip" button, then you can upload the feedback files directly here. We will automatically generate feedback and attach the files. Make sure you only upload files you have not uploaded before, and only for submissions you have not given feedback to already.',
    'If you\'re supposed to have access to this page, try refreshing.': 'If you\'re supposed to have access to this page, try refreshing.',
    'I\'m sure': 'I\'m sure',
    'Image': 'Image',
    'image': 'Image',
    'Image dimensions': 'Image dimensions',
    'Image height': 'Image height',
    'Image width': 'Image width',
    'image-drag-and-drop': 'Drag and drop on image',
    'Import': 'Import',
    'Import data': 'Import data',
    'Import existing question': 'Import existing question',
    'Import users': 'Import users',
    'Import wiris instance': 'Import wiris instance',
    'In a test, all feedback is received at the end of the quiz.': 'In a test, all feedback is received at the end of the quiz.',
    'Incorrect file name': 'Incorrect file name',
    'Indonesian': 'Indonesian',
    'information': 'Information',
    'Information': 'Information',
    'Information deleted': 'Information deleted',
    'Information saved': 'Information saved',
    'Innovation': 'Innovation',
    'Input not email': 'Input not email',
    'Input too short': 'Input too short',
    'Input valid date and time - or skip the deadline': 'Input valid date and time - or skip the deadline',
    'Institution': 'Institution',
    'Institution/company name': 'Institution/company name',
    'Institution groups': 'Institution classes',
    'Institution payments': 'Institution payments',
    'Institutions': 'Institutions',
    'Institution language': 'Institution language',
    'Institution settings': 'Institution settings',
    'Integer': 'Integer',
    'interactive_objects': 'Geogebra',
    'Interactive exercise': 'Interactive exercise',
    'International economy': 'International economy',
    'Invalid': 'Invalid',
    'Invalid form': 'Invalid form',
    'Invalid: Must be at least 1': 'Invalid: Must be at least 1',
    'Invalid: Must be at most': 'Invalid: Must be at most',
    'Invalid: Must be between 0 - 100': 'Invalid: Must be between 0 - 100',
    'Invite colleagues': 'Invite colleagues',
    'Invite more': 'Invite more',
    'Invite Participants': 'Invite Participants',
    'Invite participants via link': 'Invite participants via link',
    'Invites have been sent to your colleagues. They will be added to your institution as soon as they click the link in the email and complete the registration.': 'Invites have been sent to your colleagues. They will be added to your institution as soon as they click the link in the email and complete the registration.',
    'Invite(s) sent.': 'Invite(s) sent.',
    'Italian': 'Italian',
    'Japanese': 'Japanese',
    'Join': 'Join',
    'Joined': 'Joined',
    'Join call': 'Join call',
    'Join your class': 'Join your class',
    'Join with code not allowed': 'Join with code not allowed for this class',
    'Keep training': 'Keep training',
    'Keyword': 'Keyword',
    'Knowledge bank': 'Knowledge bank',
    'Korean': 'Korean',
    'label': 'label',
    'Labels not on image': 'Labels not on image',
    'Language': 'Language',
    'lang.da': 'Danish',
    'lang.en': 'English',
    'Language saved': 'Language saved',
    'Lastname': 'Lastname',
    'Last answered': 'Last answered',
    'Latest activity': 'Latest activity',
    'Latest answers': 'Latest answers',
    'Latest handin': 'Latest submission',
    'Latest sync': 'Latest sync',
    'Latest try': 'Latest try',
    'Latin': 'Latin',
    'Latvian': 'Latvian',
    'Learning element created': 'Learning element created',
    'Learning element removed': 'Learning element removed',
    'Learning element saved': 'Learning element saved',
    'Leave': 'Leave',
    'Leave current video chat': 'Leave current video chat',
    'Leave current webinar': 'Leave current webinar',
    'Left us': 'Left us',
    'Lesson': 'Lesson',
    'Lesson moved': 'Lesson moved',
    'Lesson title': 'Lesson title',
    'Lessonbank': 'Lessonbank',
    'Lessons': 'Lessons',
    'let all in': 'let all in',
    'Level': 'Level',
    'Levels': 'Levels',
    'Library': 'Library',
    'Link': 'Link',
    'Link copied!': 'Link copied!',
    'Link course to group': 'Link course to class',
    'Link element to group': 'Link element to class',
    'Link file to group': 'Link file to class',
    'Link learning element to group': 'Link learning element to class',
    'Link lesson to group': 'Link material to class',
    'Link text': 'Link text',
    'Link to group': 'Link to class',
    'Link to join to video chat': 'Link to join to video chat',
    'List of participants': 'List of participants',
    'Load earlier': 'Load earlier',
    'Load external quiz': 'Load external quiz',
    'Load more': 'Load more',
    'Loading': 'Loading',
    'Lock board': 'Lock pinboard',
    'Lock board item': 'Lock pinboard item',
    'Locked course. You have not yet completed the prerequisite course(s) for this course.': 'Locked course. You have not yet completed the prerequisite course(s) for this course.',
    'Login': 'Login',
    'Login failed with the following message': 'Login failed with the following message',
    'Logout': 'Log out',
    'Log in as': 'Log in as',
    'Log in with Facebook' : 'Log in with Facebook',
    'Log in with Google': 'Log in with Google',
    'Looking for a specific user? Search below.': 'Looking for a specific user? Search below.',
    'Looking to log in? Click here': 'Looking to log in? Click here',
    'Lower border': 'Lower border',
    'Made by': 'Made by',
    'Male': 'Male',
    'Manage board item': 'Manage pinboard item',
    'Manage groups': 'Manage classes',
    'Manage moodle': 'Manage moodle',
    'Manage permissions': 'Manage permissions',
    'Manage users': 'Manage users',
    'Mandatory': 'Mandatory',
    'manually.': 'manually.',
    'Maple TA': 'Maple TA',
    'Macedonian': 'Macedonian',
    'Mark all as read': 'Mark all as read',
    'Mark student as self-study': 'Mark student as self-study',
    'Mark unread': 'Mark unread',
    'match': 'Match',
    'Match': 'Match',
    'Match case insensitive': 'Match case insensitive',
    'Match case sensitive': 'Match case sensitive',
    'Match options': 'Match options',
    'Match with arrows': 'Match with arrows',
    'Matching cloze tag': 'Matching cloze tag',
    'Matching with': 'Matching with',
    'match-wiris': 'Wiris match',
    'material': 'Lesson',
    'material_objects': 'Lesson',
    'materials': 'Lessons',
    'Material added': 'Lesson added',
    'Material created': 'Lesson created',
    'Material deleted': 'Lesson deleted',
    'Material group': 'Material class',
    'Material removed': 'Lesson removed',
    'Mathematics': 'Mathematics',
    'Media': 'Media',
    'Member': 'Member',
    'Members': 'Members',
    'members': 'Members',
    'Members can comment on news': 'Members can comment on news',
    'Members can post news': 'Members can post news',
    'Menu': 'Menu',
    'Messages': 'Messages',
    'Microphone' : 'Microphone',
    'min': 'min',
    'Minimum number of assignments behind': 'Minimum number of assignments behind',
    'Minimum number of questions': 'Minimum number of questions',
    'Moldavian': 'Moldavian',
    'Montenegrin': 'Montenegrin',
    'months-full-array': ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    'months-short-array': ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    'Moodle': 'Moodle',
    'Moodle group': 'Moodle group',
    'More': 'More',
    'More settings': 'More settings',
    'Most apps have a central action button - look for it in the same place as this one': 'Most apps have a central action button - look for it in the same place as this one',
    'Move': 'Move',
    'Move to folder': 'Move to folder',
    'ms_class_notebook': 'Class notebook',
    'Multiple choice': 'Multiple choice',
    'multiple-choice': 'Multiple choice',
    'multi-choice-wiris': 'Wiris multiple choice',
    'Multiple choice settings': 'Multiple choice settings',
    'Music': 'Music',
    'Must be a valid secure link (https)': 'Must be a valid secure link (https)',
    'Must be between': 'Must be between',
    'Mute': 'Mute',
    'Muted': 'Muted',
    'mute all': 'mute all',
    'My institution is not on the list': 'My institution is not on the list',
    'Name': 'Name',
    'Name saved': 'Name saved',
    'Need help?': 'Need help?',
    'Never': 'Never',
    'New assignment': 'New assignment',
    'New conversation': 'New conversation',
    'New field': 'New field',
    'New Geogebra element': 'New Geogebra element',
    'New image': 'New image',
    'New Post': 'New Post',
    'New question': 'New question',
    'New quiz': 'New quiz',
    'New password': 'New password',
    'New relation': 'New relation',
    'New word': 'New word',
    'news': 'News',
    'Next': 'Next',
    'Next assignment': 'Next assignment',
    'Next assignment answer without feedback': 'Next assignment answer without feedback',
    'Next course': 'Next course',
    'Next lesson': 'Next lesson',
    'Next material': 'Next lesson',
    'Next month': 'Next month',
    'Next question': 'Next question',
    'Next topic': 'Next topic',
    'No': 'No',
    'None': 'None',
    'Notifications': 'Notifications',
    'No admins yet': 'No admins yet',
    'No answers': 'No answers',
    'No assignment date': 'No assignment date',
    'No assignments found': 'No assignments found',
    'No collections found': 'No collections found',
    'No completed consultations' : 'No completed consultations',
    'No conversations': 'No conversations',
    'No counsellors': 'No counsellors',
    'No course': 'No course',
    'No courses found': 'No courses found',
    'No description': 'No description',
    'No files' : 'No files',
    'No files or folders yet': 'No files or folders yet',
    'No Geogebra elements found': 'No Geogebra elements found',
    'No information': 'No information',
    'No group information created': 'No class information created',
    'No groups': 'No classes',
    'No groups found': 'No classes found',
    'No learning elements found': 'No learning elements found',
    'No materials found': 'No lessons found',
    'No members': 'No members',
    'No members yet': 'No members yet',
    'No messages found': 'No messages found',
    'No news found for this group': 'No news found for this class',
    'No notifications': 'No notifications',
    'No, only visible for my institution': 'No, only visible for my institution',
    'No permissions found': 'No permissions found',
    'No posts found': 'No posts found',
    'No questions': 'No questions',
    'No questions found': 'No questions found',
    'No questionnaires found': 'No questionnaires found',
    'No quizzes found': 'No quizzes found',
    'No permission to access your microphone or\\and camera you (or your companions) may experience some problems.': 'No permission to access your microphone or\\and camera you (or your companions) may experience some problems.',
    'No results': 'No results',
    'No results yet': 'No results yet',
    'No subjects': 'No subjects',
    'No title': 'No title',
    'No topic': 'No topic',
    'No topics': 'No topics',
    'No students': 'No students',
    'No tries yet': 'No tries yet',
    'No upcoming consultations': 'No upcoming consultations',
    'No videos found': 'No videos found',
    'Norwegian': 'Norwegian',
    'Not assigned to course': 'Not assigned to course',
    'Not enough data': 'Not enough data',
    'Not active': 'Not active',
    'Not added': 'Not added',
    'Not chosen': 'Not chosen',
    'Not correct': 'Not correct',
    'Not completed': 'Not completed',
    'Not graded': 'Not graded',
    'Not passed': 'Not passed',
    'Not released': 'Not released',
    'Not submitted': 'Not submitted',
    'Not valid video chat session provided. Please restart video call normally Page reload will not help.': 'Not valid video chat session provided. Please restart video call normally Page reload will not help.',
    'Nothing has been shared with you yet': 'Nothing has been shared with you yet',
    'Number of assignments': 'Number of assignments',
    'Number of assignments missing feedback': 'Number of assignments missing feedback',
    'Number of decimals': 'Number of decimals',
    'Number of missing': 'Number of missing',
    'Number of votes': 'Number of votes',
    'Number of words': 'Number of words',
    'Number type': 'Number type',
    'Numeric variables': 'Numeric variables',
    'OK': 'OK',
    'Okay': 'Okay',
    'Of': 'Of',
    'of': 'of',
    'Of all questions are answered correctly': 'Of all questions are answered correctly',
    'Off': 'Off',
    'Old password': 'Old password',
    'On': 'On',
    'Oops something went wrong. Maybe you didn\'t grant permissions to access your camera or microphone.': 'Oops something went wrong. Maybe you didn\'t grant permissions to access your camera or microphone.',
    'Oops something went wrong. Please try again.': 'Oops something went wrong. Please try again.',
    'Only open answer for now': 'Only open answer for now',
    'Open': 'Open',
    'Open answer': 'Open answer',
    'Open external link': 'Open external link',
    'Open link': 'Open link',
    'Open turnin': 'Open submission',
    'open-answer': 'Open answer',
    'Option': 'Option',
    'Optional': 'Optional',
    'Optional settings': 'Optional settings',
    'Options': 'Options',
    'or': 'or',
    'Order saved': 'Order saved',
    'Other login options': 'Other login options',
    'Overview': 'Overview',
    'Oups! This route does not exist!': 'Oups! This route does not exist!',
    'Page': 'Page',
    'Pages': 'Pages',
    'Participants': 'Participants',
    'Passed': 'Passed',
    'Passed or completed': 'Passed or completed',
    'Password': 'Password',
    'Password saved': 'Password saved',
    'Passwords must match': 'Passwords must match',
    'Pass percentage': 'Pass percentage',
    'Paste wiris XML': 'Paste wiris XML',
    'Percentage': 'Percentage',
    'Percentage or fractions': 'Percentage or fractions',
    'Permission': 'Permission',
    'Permissions': 'Permissions',
    'Permission group': 'Permission group',
    'Permission groups': 'Permission groups',
    // "permissions.admin_absence" : "Manage absence for the entire institution",
    // "permissions.admin_calendar" : "Manage schedule for the entire institution",
    'permissions.admin_bank': 'Admin in bank',
    'permissions.admin_groups_classes': 'Mange groups and classes for the institution',
    'permissions.admin_institution_settings': 'Manage general settings for the institution',
    'permissions.admin_moodle_administration': 'Administer MOODLE for the institution',
    'permissions.admin_permissions': 'Manage permissions for the institution',
    'permissions.admin_recommended_material': 'Recommend lessons to students',
    'permissions.admin_sign_in_as_other': 'Sign in as other user',
    'permissions.admin_stats': 'Access statistics for the institution',
    // "permissions.auto_admin_in_groups" : "Automatic promotion to author when joining groups",
    'permissions.can_view_teacher_activity': 'Can view teachers activity',
    'permissions.change_language': 'Change language',
    'permissions.change_profile_picture': 'Change profile picture',
    'permissions.edit_module_assignments': 'Admin rights to app: Assignments',
    'permissions.edit_module_conferences': 'Admin rights to app: Conferences',
    'permissions.edit_module_consultations': 'Admin rights to app: Consultations',
    'permissions.editor_bank': 'Editor in bank',
    'permissions.edit_module_file_sharing': 'Admin rights to app: File-sharing',
    // "permissions.edit_module_homework" : "Admin rights to app: Homework",
    'permissions.edit_module_information': 'Admin rights to app: Information',
    'permissions.edit_module_quizzes': 'Admin rights to app: Quizzes',
    'permissions.edit_module_materials': 'Admin rights to app: lessons',
    'permissions.edit_module_interactive': 'Admin rights to app: Geogebra',
    'permissions.edit_module_paths': 'Admin rights to app: Courses',
    'permissions.edit_module_news': 'Admin rights to app: News',
    'permissions.edit_module_videos': 'Admin rights to app: Videos',
    'permissions.edit_module_homework_aid': 'Admin rights to app: Video aid',
    'permissions.generic_replace_youtube_url': 'Generic replace Youtube URLs',
    // "permissions.force_join_groups" : "Force other users to join group upon invitation",
    // "permissions.manage_non_admin_groups" : "Manage all groups without being author",
    // "permissions.message_groups_classes" : "Send messages to entire groups and classes",
    // "permissions.profile_absence" : "See absence for other users",
    'permissions.profile_assignments': 'See assignments for other users',
    // "permissions.profile_calendar" : "See calendar for other users",
    // "permissions.profile_contracts" : "See contracts for other users",
    // "permissions.profile_grades" : "See grades for other users",
    'permissions.profile_quizzes': 'See quizzes for other users',
    'permissions.profile_paths': 'See courses for other users',
    'permissions.profile_stats': 'See statistics for other users',
    // "permissions.search_entire_institution" : "Search for all group contents",
    // "permissions.see_own_absence" : "See absence for own user",
    // "permissions.see_own_assignments" : "See assignments for own user",
    // "permissions.see_own_calendar" : "See calendar for own user",
    // "permissions.see_own_contracts" : "See contracts for own user",
    // "permissions.see_own_grades" : "See grades for own user",
    // "permissions.see_own_stats" : "See statistics for own user",
    // "permissions.send_reminders" : "Send reminders to students",
    'permissions.view_bank': 'Can view bank items',
    // "permissions.view_module_assignments" : "User rights for app: Assignments",
    // "permissions.view_module_conferences" : "User rights for app: Conferences",
    // "permissions.view_module_file_sharing" : "User rights for app: File-sharing",
    // "permissions.view_module_homework" : "User rights for app: Homework",
    // "permissions.view_module_information" : "User rights for app: Information",
    // "permissions.view_module_quizzes" : "User rights for app: Quizzes",
    // "permissions.view_module_paths" : "User rights for app: Courses",
    // "permissions.view_module_news" : "User rights for app: News",
    // "permissions.view_module_materials" : "User rights for app: lessons",
    // "permissions.view_module_interactive" : "User rights for app: Geogebra",
    // "permissions.view_module_videos" : "User rights for app: Videos",
    // "permissions.view_recommended_material" : "User rights to recommended lesson",
    'permissions.user_edit_own_group': 'Admin in own class',
    'Personal groups': 'Personal classes',
    'Philosophy': 'Philosophy',
    'Physics': 'Physics',
    'Pick date': 'Pick date',
    'Pick due date': 'Pick due date',
    'Pick due time': 'Pick due time',
    'Pick new background image': 'Pick new background image',
    'Pick time': 'Pick time',
    'Pick the same date if it is just a single holiday': 'Pick the same date if it is just a single holiday',
    'Pin': 'Pin',
    'Pin post': 'Pin post',
    'Pin side menu': 'Pin side menu',
    'Pinned': 'Pinned',
    'Phone': 'Phone',
    'Please fix all errors and try again': 'Please fix all errors and try again',
    'Please select a user': 'Please select a user',
    'Please use any other browser for browsing our platform.': 'Please use any other browser for browsing our platform.',
    'Please verify your email.': 'Please verify your email.',
    'Please verify your email': 'Please verify your email',
    'Please wait until the call owner lets you in': 'Please wait until the call owner lets you in',
    'Polish': 'Polish',
    'Pool size': 'Pool size',
    'Portuguese': 'Portuguese',
    'Possibilities': 'Options',
    'Post': 'Post',
    'Post created': 'Post created',
    'Post removed': 'Post removed',
    'Post saved': 'Post saved',
    'Practiced': 'Practiced',
    'Practice again': 'Practice again',
    'Practice quiz': 'Practice quiz',
    'Presentation': 'Presentation',
    'Press enter to add new field, and backspace to remove': 'Press enter to add new field, and backspace to remove',
    'Preview data': 'Preview data',
    'Previous': 'Previous',
    'Previous month': 'Previous month',
    'Previous topic': 'Previous topic',
    'Profile': 'Profile',
    'Progression': 'Progression',
    'Psychology': 'Psychology',
    'Pull the rows to edit the sort order of the apps and activate/deactivate the app using the lever. You can also hide apps from the students': 'Pull the icons to edit the sort order of the apps and activate/deactivate the app using the lever. You can also hide apps from the students',
    'Put labels in the same group to make them interchangeable.': 'Put labels in the same group to make them interchangeable.',
    'Quarter': 'Quarter',
    'Question': 'Question',
    'Question and answers': 'Question and answers',
    'Questionnaire': 'Questionnaire',
    'questionnaires': 'Questionnaires',
    'Questionnaires': 'Questionnaires',
    'Questions': 'Questions',
    'Question bank': 'Question bank',
    'Question overview': 'Question overview',
    'Question statistics': 'Question statistics',
    'Question type': 'Question type',
    'Questions from the bank': 'Questions from the bank',
    'Questions in order': 'Questions in order',
    'Questions must have at least one correct answer and cannot be empty': 'Questions must have at least one correct answer and cannot be empty',
    'Queue': 'Queue',
    'Quiz': 'Quiz',
    'quiz_objects': 'Quiz',
    'quiz_question_objects': 'Quiz question',
    'Quiz completion percentage': 'Quiz completion percentage',
    'Quiz matrix': 'Quiz matrix',
    'Quiz mode': 'Quiz mode',
    'Quiz statistics': 'Quiz statistics',
    'Quiz status': 'Quiz status',
    'Quiz type': 'Quiz type',
    'Quizzes': 'Quizzes',
    'quizzes': 'Quizzes',
    'Radio buttons': 'Radio buttons',
    'Random pool size': 'Random pool size',
    'Random questions': 'Random questions',
    'Read messages': 'Read',
    'Read more': 'Read more',
    'Reading time': 'Reading time',
    'Recalculate assignment plan for user': 'Recalculate assignment plan for user',
    'Recalculate assignment plans for group': 'Recalculate assignment plans for class',
    'Recalculate cloze options': 'Recalculate cloze options',
    'Reconnect': 'Reconnect',
    'Refresh board': 'Refresh pinboard',
    'Refresh the page if the changes do not appear': 'Refresh the page if the changes do not appear',
    'Register': 'Register',
    'Register handin': 'Register submission',
    'Register handin manually': 'Register submission manually',
    'Rejected': 'Rejected',
    'Reject assignment': 'Reject assignment',
    'Released': 'Released',
    'Release feedback': 'Release feedback',
    'Religion': 'Religion',
    'Remember to link this quiz to a Maple TA assignment after you save it. If you fail to do this, users will encounter a dead link.': 'Remember to link this quiz to a Maple TA assignment after you save it. If you fail to do this, users will encounter a dead link.',
    'Remove': 'Remove',
    'Remove answer': 'Remove answer',
    'Remove assignment': 'Remove assignment',
    'Remove attachment': 'Remove attachment',
    'Remove audio': 'Remove audio',
    'Remove board': 'Remove pinboard',
    'Remove collection': 'Remove collection',
    'Remove course': 'Remove course',
    'Remove external link': 'Remove external link',
    'Remove feedback': 'Remove feedback',
    'Remove file': 'Remove file',
    'Remove flashcard': 'Remove flashcard',
    'Remove flashcards': 'Remove flashcards',
    'Remove folder': 'Remove folder',
    'Remove from material': 'Remove from lesson',
    'Remove geogebra': 'Remove geogebra',
    'Remove Geogebra element': 'Remove Geogebra element',
    'Remove group': 'Remove class',
    'Remove image': 'Remove image',
    'Remove label': 'Remove label',
    'Remove learning element': 'Remove learning element',
    'Remove match set': 'Remove match set',
    'Remove material': 'Remove lesson',
    'Remove member': 'Remove member',
    'Remove post': 'Remove post',
    'Remove subject': 'Remove subject',
    'Remove text': 'Remove text',
    'Remove topic': 'Remove topic',
    'Remove question': 'Remove question',
    'Remove questionnaire': 'Remove questionnaire',
    'Remove quiz': 'Remove quiz',
    'Remove timeline': 'Remove timeline',
    'Remove user': 'Remove user',
    'Remove user from the conversation': 'Remove user from the conversation',
    'Remove video': 'Remove video',
    'Remove vimeo': 'Remove vimeo',
    'Remove your Facebook account': 'Remove your Facebook account',
    'Remove your Google account': 'Remove your Google account',
    'Remove youtube': 'Remove youtube',
    'Remove variable': 'Remove variable',
    'Remove word': 'Remove word',
    'Rename': 'Rename',
    'Reorder elements': 'Reorder elements',
    'Repeatable': 'Repeatable',
    'Repeatable quiz': 'Repeatable quiz',
    'Repeat quiz': 'Repeat quiz',
    'Repeat password': 'Repeat password',
    'Reset password': 'Reset password',
    'Reset completion deadlines': 'Reset completion deadlines',
    'Required': 'Required',
    'Required pass percentage': 'Required pass percentage',
    'Requires at least': 'Requires at least',
    'Result': 'Result',
    'Results': 'Results',
    'Retry': 'Retry',
    'Return to dashboard': 'Return to dashboard',
    'Role': 'Role',
    'Romanian': 'Romanian',
    'Russian': 'Russian',
    'Save': 'Save',
    'Save and attach to feedback': 'Save and attach to feedback',
    'Save and release feedback': 'Save and release feedback',
    'Save and show': 'Save and show',
    'Save assignment': 'Save assignment',
    'Save audio file': 'Save audio file',
    'Save collection': 'Save collection',
    'Save file': 'Save file',
    'Save feedback': 'Save feedback',
    'Save geogebra': 'Save geogebra',
    'Save image': 'Save image',
    'Save in filesystem': 'Save in filesystem',
    'Save order': 'Save order',
    'Save question and show': 'Save question and show',
    'Save question': 'Save question',
    'Save questionnaire': 'Save questionnaire',
    'Save quiz': 'Save quiz',
    'Save quiz and show': 'Save quiz and show',
    'Save video': 'Save video',
    'Save Vimeo video': 'Save Vimeo video',
    'Save subject': 'Save subject',
    'Save text': 'Save text',
    'Save to': 'Save to',
    'Save to root folder': 'Save to root folder',
    'Save topic': 'Save topic',
    'Save youtube video': 'Save youtube video',
    'Science': 'Science',
    'School': 'School',
    'Search': 'Search',
    'Search all students': 'Search all students',
    'Search counsellor': 'Search counsellor',
    'Search for users, groups..': 'Search for users, classes..',
    'Search group': 'Search class',
    'Search person': 'Search person',
    'Search question': 'Search question',
    'Search results': 'Search results',
    'Search student': 'Search student',
    'Search students in the class': 'Search students in the class',
    'Search user': 'Search user',
    'Search youtube': 'Search Youtube',
    'sec': 'sec',
    'Security warning': 'Security warning',
    'See all': 'See all',
    'See all news': 'See all news',
    'See past feedback': 'See past feedback',
    'Set': 'Set',
    'Seen by all': 'Seen by all',
    'Seen by student': 'Seen by student',
    'Selected counsellor\'s students': 'Selected counsellor\'s students',
    'Selected groups': 'Selected classes',
    'Selected members': 'Selected members',
    'Select a month': 'Select a month',
    'Select tags': 'Select tags',
    'Select a year': 'Select a year',
    'Select from list': 'Select from list',
    'Send': 'Send',
    'Send a message to start the chat': 'Send a message to start the chat',
    'Send copy to colleagues': 'Send copy to colleagues',
    'Send element to these colleagues?': 'Send element to these colleagues?',
    'Send element to this colleague?': 'Send element to this colleague?',
    'Send Invites': 'Send Invites',
    'Send message': 'Send message',
    'Send unreleased feedback': 'Send unreleased feedback',
    'Sentence': 'Sentence',
    'Serbian': 'Serbian',
    'Serbo-Croatian': 'Serbo-Croatian',
    'Setting': 'Setting',
    'Settings': 'Settings',
    'Settings saved': 'Settings saved',
    'Sign up': 'Sign up',
    'Sign up with Facebook' : 'Sign up with Facebook',
    'Sign up with Google' : 'Sign up with Google',
    'SimpleMath multiple choice': 'SimpleMath multiple choice',
    'Simplemath settings': 'Simplemath settings',
    'SimpleMath settings': 'SimpleMath settings',
    'SimpleMath short answer': 'SimpleMath short answer',
    'simple-math-short-answer': 'SimpleMath short answer',
    'simple-math-multiple-choice': 'SimpleMath multiple choice',
    'Size': 'Size',
    'Share': 'Share',
    'Share course': 'Share course',
    'Share element': 'Share element',
    'Share file': 'Share file',
    'Share folder': 'Share folder',
    'Share material': 'Share lesson',
    'Share screen': 'Share screen',
    'Share with colleagues': 'Share with colleagues',
    'shared': 'shared',
    'shared the assignment': 'shared the assignment',
    'shared the course': 'shared the course',
    'shared the flashcard': 'shared the flashcard',
    'shared the flashcard collection': 'shared the flashcard collection',
    'shared the folder': 'shared the folder',
    'shared the geogebra': 'shared the geogebra',
    'shared the group': 'shared the class',
    'shared the lesson': 'shared the lesson',
    'shared the link': 'shared the link',
    'shared the media': 'shared the media',
    'shared the text': 'shared the text',
    'shared the question': 'shared the question',
    'shared the questionnaire': 'shared the questionnaire',
    'shared the quiz': 'shared the quiz',
    'shared the timeline': 'shared the timeline',
    'Shared with me': 'Shared with me',
    'Short answer': 'Short answer',
    'Short answer settings': 'Short answer settings',
    'short-answer': 'Short answer',
    'short-answer-wiris': 'Wiris short answer',
    'Show': 'Show',
    'Show all': 'Show all',
    'Show element': 'Show element',
    'Show info': 'Show info',
    'Show less': 'Show less',
    'Show more': 'Show more',
    'Skip first line': 'Skip first line',
    'Skip over elements that have already been copied': 'Skip over elements that have already been copied',
    'Slovak': 'Slovak',
    'Socialstudies': 'Socialstudies',
    'Some elements have already been copied to these groups.': 'Some elements have already been copied to these classes.',
    'Some elements have already been copied to this group.': 'Some elements have already been copied to this classes.',
    'Sorry, we can not connect your account. Please try again later.': 'Sorry, we can not connect your account. Please try again later.',
    'Spanish': 'Spanish',
    'Spanish Latin American': 'Spanish (Latin American)',
    'Specify the difficulty of the question. This is your subjective assessment compared to the rest of the content.': 'Specify the difficulty of the question. This is your subjective assessment compared to the rest of the content on this level.',
    'Spell': 'Stav',
    'spell-speech': 'Spell word',
    'Spell word': 'Spell word',
    'Start': 'Start',
    'Start broadcasting': 'Start broadcasting',
    'Start exam mode': 'Start exam mode',
    'Start making great learning content, by verifying your email.' : 'Start making great learning content, by verifying your email.',
    'Start quiz': 'Start quiz',
    'Start reading': 'Start reading',
    'Start video chat': 'Start video chat',
    'Statistics': 'Statistics',
    'Status': 'Status',
    'STEM': 'STEM',
    'Stop broadcasting': 'Stop broadcasting',
    'Stop exam mode': 'Stop exam mode',
    'Stop reading': 'Stop reading',
    'Stop sharing': 'Stop sharing',
    'Student': 'Student',
    'student': 'student',
    'students': 'students',
    'Student handed in offline': 'Student handed in offline',
    'Students can add flashcards': 'Students can add flashcards',
    'Students list': 'Students list',
    'Student / student average': 'Student / student average',
    'Student Testing': 'Student Testing',
    'Student view': 'Student view',
    'Subgroups': 'Subgroups',
    'Subject': 'Subject',
    'Subjects': 'Subjects',
    'Submit answer & continue': 'Submit answer & continue',
    'Success': 'Success',
    'Support mail': 'Support mail',
    'Swahili': 'Swahili',
    'Swap out': 'Swap out',
    'Swedish': 'Swedish',
    'Switch': 'Switch',
    'Switch additional group': 'Switch additional class',
    'Switch back to main user': 'Switch back to main user',
    'Switch main group': 'Switch main class',
    'Sync': 'Sync',
    'Synchronize elements that have already been copied': 'Synchronize elements that have already been copied',
    'Synchronize hashtags manually': 'Synchronize hashtags manually',
    'Tags': 'Tags',
    'Tamil': 'Tamil',
    'Teacher': 'Teacher',
    'Teachers': 'Teachers',
    'Teacher view': 'Teacher view',
    'Term': 'Term',
    'Test': 'Test',
    'Test answer': 'Test answer',
    'text': 'Text',
    'Text': 'Text',
    'Text can\'t be empty and should be shorter then 255 characters.': 'Text can\'t be empty and should be shorter then 255 characters.',
    'Text copied': 'Text copied',
    'Text on label': 'Text on label',
    'Time': 'Time',
    'Timeline': 'Timeline',
    'Time in HH:MM:SS': 'Time in HH:MM:SS',
    'Time to complete course (days)': 'Time to complete course (days)',
    'Tip': 'Tip',
    'Title': 'Title',
    'Title required': 'Title required',
    'Title too long': 'Title too long',
    'Thai': 'Thai',
    'Thanks for your answer': 'Thanks for your answer',
    'Thank you for your interest in Edaptio.': 'Thank you for your interest in Edaptio.',
    'Thank you for your understanding': 'Thank you for your understanding',
    'The user has received an email with all the necessary information to get started': 'The user has received an email with all the necessary information to get started',
    'The user will write an open ended text here': 'The user will write an open ended text here',
    'There is currently an error with the WIRIS plugin, which is why we have removed it. We are working on resolving it.': 'There is currently an error with the WIRIS plugin, which is why we have removed it. We are working on resolving it.',
    'The assignment is closed for handin': 'The assignment is closed for submissions',
    'The corrections have been attached to the feedback': 'The corrections have been attached to the feedback',
    'The Geogebra element has been created': 'The Geogebra element has been created',
    'The Geogebra element has been saved': 'The Geogebra element has been saved',
    'The group is currently in exam mode. All communication suspended.': 'The class is currently in exam mode. All communication suspended.',
    'The question has been created': 'The quiz has been created',
    'The question has been saved': 'The quiz has been saved',
    'The quiz has been created': 'The quiz has been created',
    'The quiz has been saved': 'The quiz has been saved',
    'The system cannot auto-correct open answer questions, but your teacher can see your answer.': 'The system cannot auto-correct open answer questions, but your teacher can see your answer.',
    'The system rejected the request with the following message': 'The system rejected the request with the following message',
    'There are unsaved changes': 'There are unsaved changes',
    'This course is a reference from another group, and can therefore not be edited here.': 'This course is a reference from another class, and can therefore not be edited here.',
    'This element has been copied to this group before.': 'This element has been copied to this class before.',
    'This group does not have a class notebook yet. Contact your teacher in order to fix this.': 'This class does not have a class notebook yet. Contact your teacher in order to fix this.',
    'this guide': 'this guide',
    'This is a beta feature, and errors can occur': 'This is a beta feature, and errors can occur',
    'This is a collection of all the videos from your courses': 'This is a collection of all the videos from your courses',
    'This is an open answer question. This means that you have to reflect on the question, and you have to write an answer to complete it.': 'This is an open answer question. This means that you have to reflect on the question, and you have to write an answer to complete it.',
    'This is an overview of the students progression through the videos in the courses/lessons.': 'This is an overview of the students progression through the videos in the courses/lessons.',
    'This lesson is a reference from another group, and can therefore not be edited here.': 'This lesson is a reference from another class, and can therefore not be edited here.',
    'This lesson is empty.': 'This lesson is empty.',
    'This quiz type requires an active subscription to Maple TA, which you need to obtain yourself.': 'This quiz type requires an active subscription to Maple TA, which you need to obtain yourself.',
    'This widget imports all of your courses automatically': 'This widget imports all of your courses automatically',
    'This will hold news from your classes': 'This will hold news from your classes',
    'Three quarter': 'Three quarter',
    'to': 'to',
    'To': 'To',
    'Toggle fullscreen mode': 'Toggle fullscreen mode',
    'Today': 'Today',
    'Top': 'Top',
    'Topic': 'Topic',
    'Topics': 'Topics',
    'to join your class.': 'to join your class.',
    'To top': 'To top',
    'Toggle fullscreen': 'Toggle fullscreen',
    'Total': 'Total',
    'Total number of assignment turnins': 'Total number of assignment submissions',
    'Trainable': 'Trainable',
    'Train mathematics': 'Train mathematics',
    'Translate': 'Translate',
    'Translate word': 'Translate word',
    'Tries': 'Tries',
    'Try': 'Try',
    'Try Edaptio': 'Try Edaptio',
    'Try now': 'Try now',
    'Turkish': 'Turkish',
    'Turnin overview': 'Submission overview',
    'Turn in questionnaire': 'Turn in questionnaire',
    'Turn in quiz': 'Turn in quiz',
    'Turn video off': 'Turn video off',
    'Turn video on': 'Turn video on',
    'Type': 'Type',
    'Type in your message': 'Type in your message',
    'UK English': 'English (UK)',
    'UMS': 'UMS',
    'UMS group': 'UMS group',
    'Unarchive': 'Unarchive',
    'Unarchive group': 'Unarchive class',
    'Unilogin': 'Unilogin',
    'Unilogin username': 'Unilogin username',
    'Unknown': 'Unknown',
    'Unlock board': 'Unlock pinboard',
    'Unlock board item': 'Unlock pinboard item',
    'Unmute': 'Unmute',
    'Unpin': 'Unpin',
    'Unpin post': 'Unpin post',
    'Unpin side menu': 'Unpin side menu',
    'Unread messages': 'Unread',
    'Upcoming consultations': 'Upcoming consultations',
    'Update and log in': 'Update and log in',
    'Update profile picture': 'Update profile picture',
    'updated the assignment': 'updated the assignment',
    'Upload': 'Upload',
    'Uploads': 'Uploads',
    'Upload feedback' : 'Upload feedback',
    'Upload new file': 'Upload new file',
    'Upper border': 'Upper border',
    'Use 0 if you want to disable it': 'Use 0 if you want to disable the pass percentage',
    'Use in group': 'Use in class',
    'Use course in group': 'Use course in class',
    'Use hashtags to indicate word insertions': 'Use hashtags to indicate word insertions',
    'Use learning element in group': 'Use learning element in class',
    'Use material in group': 'Use lesson in class',
    'Use the arrow keys to switch and flip the cards': 'Use the arrow keys to switch and flip the cards',
    'Use the feedback, improve your assignment and hand in again': 'Use the feedback, improve your assignment and hand in again',
    'Use this in a group': 'Use this in a class',
    'User': 'User',
    'User assignments behind': 'User assignments behind',
    'User assignment progression throughout courses': 'User assignment progression throughout courses',
    'User created': 'User created',
    'User is presented with a single random word': 'User is presented with a single random word',
    'User is presented with all words': 'User is presented with all words',
    'User name': 'User name',
    'User progression': 'User progression',
    'User quiz progression': 'User quiz progression',
    'User deleted': 'User deleted',
    'User saved': 'User saved',
    'Users': 'Users',
    'Users not yet started': 'Users not yet started',
    'Users under average completion': 'Users under average completion',
    'Username': 'Username',
    'US English': 'English (US)',
    'Validating': 'Validating',
    'Value': 'Value',
    'video-conference': 'Video conference',
    'Video': 'Video',
    'Video chat': 'Video chat',
    'Video chat does not exist or it\'s already finished.': 'Video chat does not exist or it\'s already finished.',
    'Video id required': 'Video id required',
    'Video is off': 'Video is off',
    'Video is on': 'Video is on',
    'videos': 'Videos',
    'Video preview': 'Video preview',
    'Video quiz': 'Video quiz',
    'Vietnamese': 'Vietnamese',
    'View': 'View',
    'Viewing as a student': 'Viewing as a student',
    'Viewing as a teacher': 'Viewing as a teacher',
    'View messages': 'View messages',
    'Vimeo': 'Vimeo',
    'Vimeo video id/URL': 'Vimeo video id/URL',
    'Visible to students': 'Visible to students',
    'Visual arts': 'Billedkunst',
    'Voice gender': 'Voice gender',
    'webinars': 'Webinars',
    'Weekdays-full-array': ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    'Weekdays-short-array': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    'Welsh': 'Welsh',
    'We are preparing your file for embed, make sure to refresh the page in a little while. This should take no more than 2 minutes.': 'We are preparing your file for embed, make sure to refresh the page in a little while. This should take no more than 2 minutes.',
    'We can see, that you are using Internet Explorer to browse the web. This is an old, outdated and insecure browser, which is why we have chosen not to support it.': 'We can see, that you are using Internet Explorer to browse the web. This is an old, outdated and insecure browser, which is why we have chosen not to support it.',
    'We have sent an email to the provided email address, if it exists in the system. Remember to check your spam filter.': 'We have sent an email to the provided email address, if it exists in the system. Remember to check your spam filter.',
    'What are you?': 'What are you?',
    'When is the assignment due?': 'When is the assignment due?',
    'What educational level?': 'What educational level?',
    'What media should the users turn in?': 'What media should the users turn in?',
    'What type of assignment?': 'What type of assignment?',
    'What would you like to learn about?': 'What would you like to learn about?',
    'Who are you?': 'Who are you?',
    'Wiris match': 'Wiris match',
    'Wiris multiple choice': 'Wiris multiple choice',
    'Wiris short answer': 'Wiris short answer',
    'Write answer': 'Write answer',
    'Write comment': 'Write comment',
    'Write description': 'Write description',
    'Write here': 'Write here',
    'Write message': 'Write message',
    'Write the code from the marked area below in the text field, to confirm deletion': 'Write the code from the marked area below in the text field, to confirm deletion',
    'Write the full sentence/text below. Use hashtags (#) before the words that need to be filled in. See': 'Write the full sentence/text below. Use hashtags (#) before the words that need to be filled in. See',
    'Write what you hear': 'Write what you hear',
    'Wrong username/password': 'Wrong username/password',
    'Year/date/title': 'Year/date/title',
    'Yes': 'Yes',
    'Yes, archive this group': 'Yes, archive this class',
    'Yes, delete this assignment': 'Yes, delete this assignment',
    'Yes, delete this collection': 'Yes, delete this collection',
    'Yes, delete this feedback': 'Yes, delete this feedback',
    'Yes, delete this file': 'Yes, delete this file',
    'Yes, delete this folder': 'Yes, delete this folder',
    'Yes, delete this Geogebra element': 'Yes, delete this Geogebra element',
    'Yes, delete this group': 'Yes, delete this class',
    'Yes, delete this group information': 'Yes, delete this class information',
    'Yes, delete this post': 'Yes, delete this post',
    'Yes, delete this question': 'Yes, delete this question',
    'Yes, delete this quiz': 'Yes, delete this quiz',
    'Yes, delete this user': 'Yes, delete this user',
    'Yes, everyone can use this': 'Yes, everyone can use this',
    'Yes, turn in this questionnaire': 'Yes, turn in this questionnaire',
    'Yes, turn in this quiz': 'Yes, turn in this quiz',
    'Yes, remove this course': 'Yes, remove this course',
    'Yes, remove this learning element': 'Yes, remove this learning element',
    'Yes, remove this member': 'Yes, remove this member',
    'Yes, remove this material': 'Yes, remove this lesson',
    'Yes, start video call': 'Yes, start video call',
    'Your assignment plan': 'Your assignment plan',
    'Your assignment turnin': 'Your assignment submission',
    'Your class has multiple sub-groups. Choose which one to view for.': 'Your class has multiple sub-groups. Choose which one to view for.',
    'Your files have been uploaded and matched.': 'Your files have been uploaded and matched with feedback.',
    'Your streak': 'Your streak',
    'Youtube': 'Youtube',
    'Youtube video': 'Youtube video',
    'YouTube video ID': 'YouTube video ID',
    'Youtube video id': 'Youtube video id',
    'You answered correct': 'You answered correct',
    'You are currently in exam mode. All communication suspended.': 'You are currently in exam mode. All communication suspended.',
    'You are in a waiting room': 'You are in a waiting room',
    'You are in training mode. Your answers are not recorded, and you get instant feedback. Start the quiz to complete it.': 'You are in training mode. Your answers are not recorded, and you get instant feedback. Start the quiz to complete it.',
    'You are up!': 'You are up!',
    'You are using Internet Explorer to browse. We do not guarantee support for this, and some areas of the page may not work. We encourage you to switch to any other browser.': 'You are using Internet Explorer to browse. We do not guarantee support for this, and some areas of the page may not work. We encourage you to switch to any other browser.',
    'You can not access your assignments here in this group. This will serve as an overview only.': 'You can not access your assignments here in this class. This will serve as an overview only.',
    'You can find the course in your group': 'You can find the course in your class',
    'You can find the element on the material': 'You can find the element on the lesson',
    'You can find the material on the course': 'You can find the lesson on the course',
    'You can not enter the program this way.': 'You can not enter the program this way.',
    'You can not have a larger question pool than number of questions attached': 'You can not have a larger question pool than number of questions attached',
    'You can only add admins here': 'You can only add admins here',
    'You can reject the assignment and force the student to hand in again': 'You can reject the assignment and force the student to hand in again',
    'You can see this guide to get started': 'You can see this guide to get started',
    'You did not choose all the correct answers': 'You did not choose all the correct answers',
    'You do not have much time left for this assignment': 'You do not have much time left for this assignment',
    'You have a good amount of time left for this assignment': 'You have a good amount of time left for this assignment',
    'You have already handed this assignment in.': 'You have already handed this assignment in.',
    'You have answered all the questions in this topic. Keep training or go to the next topic.': 'You have answered all the questions in this topic. Keep training or go to the next topic.',
    'You have assigned duplicate rows to represent the same value': 'You have assigned duplicate rows to represent the same value',
    'You have missed the due date, or the assignment has been rejected': 'You have missed the due date, or the assignment has been rejected',
    'You have to have atleast one question for your quiz': 'You have to have atleast one question for your quiz',
    'You must add either a group or a person to the conversation': 'You must add either a class or a person to the conversation',
    'You must choose minimum one answer': 'You must choose minimum one answer',
    'You must choose more than one answer': 'You must choose more than one answer',
    'You must choose only one answer': 'You must choose only one answer',
    'You only need to choose one answer': 'You only need to choose one answer',
    'You will automatically be added as admin in the group upon creation': 'You will automatically be added as admin in the class upon creation',
    'Your answer': 'Your answer',
    'Your answer(s)': 'Your answer(s)',
    'Your answer percentage': 'Your answer percentage',
    'Your answer was not correct': 'Your answer was not correct',
    'Your assignment answer': 'Your assignment answer',
    'Your groups': 'Your classes',
    'Your level': 'Your level',
    'Your nickname': 'Your nickname',
    'Your token is not valid or expired. Try again.': 'Your link is not valid or expired. Try again.',
    'wants to join': 'wants to join',
    'wants to join video chat': 'wants to join video chat',
    'was archived by': 'was archived by',
    'Waiting list': 'Waiting list',
    'Waiting for others to arrive': 'Waiting for others to arrive',
    'Watch out': 'Watch out',
    'We could not log you in via uni-login. This was either because you are not in the system yet, or because the school does not have access to the system.': 'We could not log you in via uni-login. This was either because you are not in the system yet, or because the school does not have access to the system.',
    'What element would you like want to add?': 'What element would you like want to add?',
    'Wiris settings': 'Wiris settings',
    'Word': 'Word',
    'Words': 'Words',
    'Word list': 'Word list',
    'Wrong password': 'Wrong password',

}
