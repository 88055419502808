import Vue from 'vue'
import videoConferenceAPI from '@/api/videoConferenceAPI'

export const videoConferenceStore = {
    // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,

    state: {

    },


    getters: {

    },


    actions : {

        async getVideoConferenceToken({ commit, state }, { video_conference_id }) {
            return await videoConferenceAPI.getVideoConferenceToken({
                video_conference_id : video_conference_id
            })
        },

    },


    mutations: {

      // SET_GROUP_MATERIALS: (state, { group_id, materials }) => {
      //     Vue.set(state.materials, group_id, materials)
      // }
    }
}
