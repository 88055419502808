import Vue from 'vue'
import quizApi from '@/api/quizAPI'

export const quizStore = {
  // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
  namespaced: true,

  state: {
      queriedQuestions: [],
      questions : {},
      questions_synced : {},
      questions_object : {},
      quiz_questions: {},
      quizzes : {},
      quizzes_synced : {},
      quizzes_object : {},
      quizzes_test_data : {}
  },


  getters: {

      // getLoggedInUserInfo: state => {
      //   return state.user
      // }
  },


    actions : {

        clearQuizStore({ commit, state }, { group_id }) {
            commit('CLEAR_STORE', {
                group_id : group_id
            })
            return 1
        },

        /**
         * Function for saving and edit on a existing quiz
         */
        async createQuestion({ commit, state }, { group_id, question_data }) {
            const response = await quizApi.createQuestion(question_data)
            commit('ADD_NEW_QUESTION', {
                group_id : group_id,
                question_data : response.data
            })
            return response.data
        },

        /**
         * Function for saving and edit on a existing quiz
         */
        async createQuiz({ commit, state }, { group_id, quiz_data }) {

            const response = await quizApi.createQuiz(quiz_data)

            const quiz_id = response.data.quiz_data['quiz_id']
            const new_quiz_data = response.data.quiz_data
            const questions = response.data.questions

            commit('ADD_NEW_QUIZ', {
                group_id : group_id,
                quiz_data : new_quiz_data
            })
            commit('SET_QUIZ_QUESTIONS', {
                quiz_id : quiz_id,
                questions : questions
            })

            commit('CLEAR_QUIZ_TEST_DATA')

            return quiz_id

        },

        /**
         * Function for saving and edit on a existing quiz
         */
        async editQuestion({ commit, state }, { question_id, group_id, question_data }) {

            const response = await quizApi.saveQuestion(question_id, question_data)
            const new_question_data = response.data

            commit('SET_QUESTION_DATA', {
                question_id : question_id,
                group_id : group_id,
                question_data : new_question_data
            })

            // tank quiz test data when updating question
            commit('CLEAR_QUIZ_TEST_DATA')

            return new_question_data

        },

        /**
         * Function for saving and edit on a existing quiz
         */
        async editQuiz({ commit, state }, { quiz_id, group_id, quiz_data }) {

            const response = await quizApi.saveQuiz(quiz_id, quiz_data)

            const new_quiz_data = response.data.quiz_data
            const questions = response.data.questions

            commit('SET_QUIZ_DATA', {
                quiz_id : quiz_id,
                group_id : group_id,
                quiz_data : new_quiz_data
            })
            commit('SET_QUIZ_QUESTIONS', {
                quiz_id : quiz_id,
                questions : questions
            })
            commit('CLEAR_QUIZ_TEST_DATA')

        },

        /**
        * Function for fetching quizzes for group
        */
        async fetchAllGroupQuizzes({ commit, state }, group_id) {

            if (state.quizzes_synced[group_id]) {
                return state.quizzes[group_id]
            } else {

                const response = await quizApi.fetchAllGroupQuizzes(group_id)
                const quizzes = response.data

                commit('SET_GROUP_QUIZZES', {
                    group_id : group_id,
                    quizzes : quizzes
                })
                commit('SET_QUIZZES_SYNCED', {
                    group_id : group_id,
                    flag: 1
                })

            }

        },

        /**
        * Function for fetching quizzes for group
        */
        async fetchAllGroupQuestions({ commit, state }, group_id) {

            if (state.questions_synced[group_id]) {
                return state.questions[group_id]
            } else {
                const response = await quizApi.getAllGroupQuestions(group_id)
                commit('SET_GROUP_QUESTIONS', {
                    group_id : group_id,
                    questions : response.data
                })
                commit('SET_QUESTIONS_SYNCED', {
                    group_id : group_id,
                    flag: 1
                })
                return response.data
            }

        },


        /**
         * Function for fetching a single question with answers and all
         */
        async fetchQuestion({ commit, state }, { question_id, group_id }) {
            const response = await quizApi.fetchQuestion(question_id)

            const questionData = response.data

            commit('SET_QUESTION_DATA', {
                question_id: parseInt(questionData.question_id),
                group_id: parseInt(group_id),
                question_data: questionData,
            })

            return questionData
        },


        /**
        * Function for fetching quizzes for group
        */
        async fetchQuizQuestions({ commit, state }, { quiz_id }) {

            if (state.questions_object[quiz_id] && state.questions_object[quiz_id]) {
                return state.quiz_questions[quiz_id]
            } else {
                const response = await quizApi.fetchQuizQuestions({
                    quiz_id : quiz_id
                })
                commit('SET_QUIZ_QUESTIONS', {
                    quiz_id : quiz_id,
                    questions : response.data
                })
                return response.data
            }

        },

        /**
         * Function for getting statistics data for a quiz
         * @param util
         * @param data
         * @returns {Promise<void>}
         */
        async fetchQuizStatisticsData(util, data) {

            const { commit, state } = util
            const { quiz_id } = data

            // if (state.questions_object[quiz_id] && state.questions_object[quiz_id]) {
            //     return state.quiz_questions[quiz_id]
            // } else {
                const response = await quizApi.fetchQuizStatisticsData({
                    quiz_id : quiz_id
                })
                // commit('SET_QUIZ_QUESTIONS', {
                //     quiz_id : quiz_id,
                //     questions : response.data
                // })
                return response.data
            // }

        },

        async fetchQuestionStatisticsData(util, data) {

            const { commit, state } = util
            const { question_id } = data

            const response = await quizApi.fetchQuestionStatisticsData({
                question_id : question_id
            })

            return response.data


        },

        /**
         * Function for fetching a single question with question markup for use in testing
         */
        async fetchQuestionForTest({ commit, state }, question_id) {
            return await quizApi.fetchQuestionForTest(question_id)
        },

        async fetchQuiz(vx, data) {
            const { commit } = vx
            const {
                editMode = false,
                groupId,
                quizId,
                forceRefresh = false,
            } = data

            let quizData

            if (forceRefresh) {
                const response = await quizApi.fetchQuiz({
                    editMode: !!editMode,
                    quizId: parseInt(quizId),
                })

                quizData = response.data

                commit('SET_QUIZ', {
                    groupId: parseInt(groupId),
                    quizId: parseInt(quizId),
                    quizData,
                })
            }

            return quizData
        },

        /**
         * Function for fetching quiz data for use in testing
         */
        async fetchQuizData({ commit, state }, quiz_id, store_data = false) {
            const response = await quizApi.fetchQuizData(quiz_id)
            if (store_data) {
                let data = { ...response.data }
                data['quiz_data'] = { ...response.data }
                data['questions'] = []
                commit('SET_QUIZ_TEST_DATA', {
                    quiz_id : quiz_id,
                    quiz_data : data
                })
            }
            return response
        },

        async fetchQuizDataForAdaptiveTest({ commit, state }, { quiz_id }) {
            return await quizApi.fetchQuizDataForAdaptiveTest({
                quiz_id : quiz_id
            })
        },

        /**
         * Function for fetching quiz data for use in testing
         */
        async fetchQuizDataForTest({ commit, state }, { quiz_id, force_refresh }) {

            if (!force_refresh && state.quizzes_test_data[quiz_id]) {
                return state.quizzes_test_data[quiz_id]
            } else {
                const response = await quizApi.fetchQuizDataForTest(quiz_id)
                commit('SET_QUIZ_TEST_DATA', {
                    quiz_id : quiz_id,
                    quiz_data : response.data
                })
                return response.data
            }
        },

        async getNextAdaptiveQuestion({ commit, state }, { quiz_id, difficulty }) {

            return await quizApi.getNextAdaptiveQuestion({
                quiz_id : quiz_id,
                difficulty : difficulty
            })
        },

        async getAdaptiveTestData({ commit, state }, { quiz_id }) {

            return await quizApi.getAdaptiveTestData({
                quiz_id : quiz_id
            })

        },

        /**
         * @desc Register a quiz has ended
         * @param {Object} vx - Vuex specific fuctions
         * @param {Object} data - Quiz data
         * @param {number} data.quiz_id
         * @return {Promise} response
         */
        async registerQuizEnded(vx, data) {
          const { commit, rootState, state } = vx
          const { quiz_id } = data

          // Check if user is a mock student
          const isMockStudent = rootState.groupStore.isMockStudent || false

          // Register ended quiz
          const response = await quizApi.registerQuizEnded({
            quiz_id,
          }, isMockStudent)

          // Pull end time
          const end_time = response.data.quiz_ended

          // Update quiz state
          commit('REGISTER_QUIZ_ENDED', {
            quiz_id,
            end_time,
          })

          return response
        },


        /**
         * @desc Register a quiz has started
         * @param {Object} vx - Vuex specific fuctions
         * @param {Object} data - Quiz data
         * @param {number} data.quiz_id
         * @return {Promise} response
         */
        async registerQuizStarted(vx, data) {
          const { commit, rootState, state } = vx
          const { quiz_id } = data

          // Check if user is a mock student
          const isMockStudent = rootState.groupStore.isMockStudent || false

          // Register started quiz
          const response = await quizApi.registerQuizStarted({
            quiz_id,
          }, isMockStudent)

          // Pull start time
          const start_time = response.data.quiz_started

          // Update quiz state
          commit('REGISTER_QUIZ_STARTED', {
            quiz_id,
            start_time,
          })

          return response
        },

        /**
         * Function for removing answer feedback from question when in practice to not trigger feedback after
         */
        async removeAnswerFeedback({ commit, state }, { quiz_id, question_index }) {
            commit('REMOVE_ANSWER_FEEDBACK', {
                quiz_id : quiz_id,
                question_index : question_index
            })
            return 1
        },

        /**
         * Function for fetching quizzes for group
         */
        async removeQuiz({ commit, state }, { quiz_id, group_id }) {

            return await quizApi.removeQuiz(quiz_id).then((response) => {
                commit('REMOVE_QUIZ', { quiz_id, group_id })
            })

        },

        /**
         * Function for fetching quizzes for group
         */
        async removeQuestion ({ commit, state }, { question_id, group_id }) {

            return await quizApi.removeQuestion(question_id).then((response) => {
                commit('REMOVE_QUESTION', { question_id, group_id })
            })

        },

        /**
         * Function for fetching quizzes for group
         */
        async repeatQuizForUser({ commit, state }, { quiz_id, practice_again }) {
            return await quizApi.repeatQuizForUser({
                quiz_id : quiz_id,
                practice_again : practice_again
            })
        },

        async saveAndValidateAnswer({ commit, state }, { quiz_id, question_index, question_data }) {
            const response = await quizApi.saveAndValidateAnswer(question_data)
            commit('SET_QUESTION_TEST_DATA', {
                quiz_id : quiz_id,
                question_index : question_index,
                question_data : response.data.new_question_info
            })
            return response
        },

        async saveUserAnswer({ commit, state }, { quiz_id, question_index, answer_data }) {
            commit('SET_USER_ANSWER', {
                quiz_id : quiz_id,
                question_index : question_index,
                answer_data : answer_data
            })
            return 1
        },

        async searchQuestions(vx, data) {
            const { commit } = vx
            const {
                exclude_question_ids = [],
                group_id = 0,
                limit = 0,
                page = 0,
                query = ''
            } = data

            const response = await quizApi.searchQuestions({
                exclude_question_ids,
                group_id: parseInt(group_id),
                limit: parseInt(limit),
                page: parseInt(page),
                query,
            })

            const responseData = response.data

            commit('SET_QUERIED_QUESTIONS', {
                page: responseData.page,
                questions: responseData.questions,
            })

            return responseData
        },

        async setQuizTestData({ commit, state }, { quiz_id, quiz_data }) {
            commit('SET_QUIZ_TEST_DATA', { quiz_id : quiz_id, quiz_data : quiz_data })
            return 1
        },

        async toggleChosenAnswer({ commit, state }, { quiz_id, question_index, answer_id }) {
            commit('TOGGLE_CHOSEN_ANSWER', { quiz_id, question_index, answer_id })
            return 1
        },

    },


  mutations: {

        ADD_NEW_QUESTION: (state, { group_id, question_data }) => {
            if (state.questions[group_id]) {
                state.questions[group_id].push(question_data)
            }
            Vue.set(state.quizzes_object, question_data['question_id'], question_data)
        },

        ADD_NEW_QUIZ: (state, { group_id, quiz_data }) => {
            if (!state.quizzes[group_id]) {
                Vue.set(state.quizzes, group_id, [])
            }

            state.quizzes[group_id].push(quiz_data)

            Vue.set(state.quizzes_object, quiz_data['quiz_id'], quiz_data)
        },

        CLEAR_QUIZ_TEST_DATA: (state) => {
            Vue.set(state, 'quizzes_test_data', {})
        },

        CLEAR_STORE: (state, { group_id }) => {
            Vue.set(state.quizzes, group_id, [])
            Vue.set(state.questions, group_id, [])
            Vue.set(state.questions_synced, group_id, 0)
            Vue.set(state.quizzes_synced, group_id, 0)
        },

        REGISTER_QUIZ_ENDED: (state, { quiz_id, end_time }) => {
            if (state.quizzes_test_data[quiz_id] && state.quizzes_test_data[quiz_id]['quiz_data']) {
                Vue.set(state.quizzes_test_data[quiz_id]['quiz_data'], 'quiz_ended', end_time)
            }
        },

        REGISTER_QUIZ_STARTED: (state, { quiz_id, start_time }) => {
            if (state.quizzes_test_data[quiz_id] && state.quizzes_test_data[quiz_id]['quiz_data']) {
                Vue.set(state.quizzes_test_data[quiz_id]['quiz_data'], 'quiz_started', start_time)
            }
        },

        REMOVE_ANSWER_FEEDBACK: (state, { quiz_id, question_index }) => {

          Vue.set(state.quizzes_test_data[quiz_id]['questions'][question_index], 'feedback', {})

        },

        REMOVE_QUIZ: (state, { quiz_id, group_id }) => {
            let quizzes = state.quizzes[group_id]
            if (quizzes) {
                for (let quiz of quizzes) {
                    if (parseInt(quiz['quiz_id']) === parseInt(quiz_id)) {
                        let index = quizzes.indexOf(quiz)
                        Vue.delete(state.quizzes[group_id], index)
                    }
                }
            }
            Vue.delete(state.quizzes_object, quiz_id)
        },

        REMOVE_QUESTION: (state, { question_id, group_id }) => {
            let questions = state.questions[group_id]
            if (questions) {
                for (let question of questions) {
                    if (parseInt(question['question_id']) === parseInt(question_id)) {
                        let index = questions.indexOf(question)
                        Vue.delete(state.questions[group_id], index)
                    }
                }
            }

            Vue.delete(state.questions_object, question_id)
        },

        SET_GROUP_QUESTIONS: (state, { group_id, questions }) => {
            Vue.set(state.questions, group_id, questions)
            for (let question of questions) {
                Vue.set(state.questions_object, question['question_id'], question)
            }
        },

        SET_GROUP_QUIZZES: (state, { group_id, quizzes }) => {
            Vue.set(state.quizzes, group_id, quizzes)
            for (let quiz of quizzes) {
                Vue.set(state.quizzes_object, quiz['quiz_id'], quiz)
            }
        },

        SET_QUERIED_QUESTIONS: (state, data) => {
            const { page, questions } = data

            if (!parseInt(page)) {
                Vue.set(state, 'queriedQuestions', questions)
            } else {
                state.queriedQuestions.push(...questions)
            }
        },

        SET_QUESTIONS_SYNCED: (state, { group_id, flag }) => {
            Vue.set(state.questions_synced, group_id, flag)
        },

        SET_QUIZZES_SYNCED: (state, { group_id, flag }) => {
            Vue.set(state.quizzes_synced, group_id, flag)
        },

        SET_QUESTION_DATA: (state, { question_id, group_id, question_data }) => {
            let questions = state.questions[group_id] || []

            if (!!questions.length) {
                const questionIndex = questions.findIndex(question => {
                    return parseInt(question.question_id) === parseInt(question_id)
                })

                if (questionIndex !== -1) {
                    Vue.set(state.questions[group_id], questionIndex, question_data)
                }
            }

            Vue.set(state.questions_object, question_id, question_data)
        },


        SET_QUESTION_TEST_DATA: (state, { quiz_id, question_index, question_data } ) => {
            try {
                Vue.set(state.quizzes_test_data[quiz_id]['questions'], question_index, question_data)
            } catch(e) {}
        },

        SET_USER_ANSWER: (state, { quiz_id, question_index, answer_data } ) => {
            if (answer_data['answers']) {
                // create reference object
                // let answer_list = {}
                let answer_list = answer_data['answer_list'].reduce( ( total, current ) => {
                    total[ current ] = 1
                    total[ "wq"+current ] = 1
                    return total
                }, {})
                // answer_data['answer_list'].map( (answer) => {
                //     answer_list[answer.answer_id] = 1
                //     answer_list["wq"+answer.answer_id] = 1
                // })
                answer_data['answers'].map((answer, index) => {
                    // let found = 0

                    if (answer_list[answer.answer_id] || answer_list["wq"+answer.answer_id]) {
                        answer_data['answers'][index]['is_chosen'] = 1
                    } else {
                        answer_data['answers'][index]['is_chosen'] = 0
                    }
                })
                if (state.quizzes_test_data[quiz_id] && state.quizzes_test_data[quiz_id]['questions'] && state.quizzes_test_data[quiz_id]['questions'][question_index]) {
                    Vue.set(state.quizzes_test_data[quiz_id]['questions'][question_index], 'answers', answer_data['answers'])
                }
            }

            if (state.quizzes_test_data[quiz_id] && state.quizzes_test_data[quiz_id]['questions'] && state.quizzes_test_data[quiz_id]['questions'][question_index]) {
                Vue.set(state.quizzes_test_data[quiz_id]['questions'][question_index], 'user_answer', [])
                Vue.set(state.quizzes_test_data[quiz_id]['questions'][question_index]['user_answer'], 0, {
                    answer_id : answer_data['answer_id'],
                    title : answer_data['text_answer'],
                    cached_settings : answer_data['cached_settings'],
                    wiris_instance : answer_data['wiris_instance']
                })
            }
        },

        SET_QUIZ: (state, data) => {
            const { groupId, quizId, quizData } = data

            Vue.set(state.quizzes_object, quizId, quizData)

            let groupQuizzes = state.quizzes[groupId] || []

            if (!groupQuizzes.length) {
                Vue.set(state.quizzes, groupId, [])
            }

            const quizIndex = groupQuizzes.findIndex(quiz => {
                return parseInt(quiz.quiz_id) === parseInt(quizId)
            })

            if (quizIndex === -1) {
                state.quizzes[groupId].push(quizData)
            } else {
                Vue.set(state.quizzes[groupId], quizIndex, quizData)
            }
        },


        SET_QUIZ_DATA: (state, { quiz_id, group_id, quiz_data }) => {
            const quizzes = state.quizzes[group_id]

            if (!quizzes.length) {
                Vue.set(state.quizzes, group_id, [])
            }

            const quizIndex = quizzes.findIndex(quiz => {
                return parseInt(quiz.quiz_id) === parseInt(quiz_id)
            })

            if (quizIndex === -1) {
                state.quizzes[group_id].push(quiz_data)
            } else {
                Vue.set(state.quizzes[group_id], quizIndex, quiz_data)
            }

            Vue.set(state.quizzes_object, quiz_id, quiz_data)
        },

        SET_QUIZ_TEST_DATA: (state, { quiz_id, quiz_data }) => {
            Vue.set(state.quizzes_test_data, quiz_id, quiz_data)
        },

        SET_QUIZ_QUESTIONS: (state, { quiz_id, questions }) => {
            if (!state.quiz_questions[quiz_id]) {
                Vue.set(state.quiz_questions, quiz_id, {})
            }

            Vue.set(state.quiz_questions[quiz_id], 'questions', questions)
        },

        TOGGLE_CHOSEN_ANSWER: (state, { quiz_id, question_index, answer_id }) => {

            if (state.quizzes_test_data[quiz_id] && state.quizzes_test_data[quiz_id]['questions'] && state.quizzes_test_data[quiz_id]['questions'][question_index] && state.quizzes_test_data[quiz_id]['questions'][question_index]['answers']) {
                const answers = state.quizzes_test_data[quiz_id]['questions'][question_index]['answers']
                answers.map( (answer, index) => {
                    if (String(answer_id) === String(answer.answer_id)) {
                        let check = parseInt(answer.is_chosen) ? 0 : 1
                        Vue.set(answers[index], 'is_chosen', check)
                    }
                })
            }
        },


    }
}
