import axios from 'axios'

export default {

    getVideoConferenceToken({ video_conference_id }) {
        return axios.get('/video-conference/token/'+video_conference_id)
    },


}
