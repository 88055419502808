import axios from 'axios'

export default {


    async createAssignmentsInBulkForGroup({ group_id, start_date, num_assignments, recurring_week_day }) {
        return await axios.post('/institution/group/assignments/bulk', {
            group_id : group_id,
            start_date : start_date,
            num_assignments : num_assignments,
            recurring_week_day : recurring_week_day,
        })
    },

    async createGroupAssigmment({ title, type, due_date, group_id }) {
        return await axios.post('/institution/group/assignment', {
            title : title,
            type : type,
            due_date : due_date,
            group_id : group_id
        })
    },

    async createInstitutionHoliday({ title, start, end }) {
        return await axios.post('/institution/holiday', {
            title : title,
            start : start,
            end : end
        })
    },

    async createInstitutionPermissions({ data }) {
        return await axios.post('/institution/permissions/group', data)
    },

    async createInstitutionUser({ institution_id, firstname, lastname, email, password, permission_group_id }) {
        return await axios.post(`/institution/users`, {
            institution_id : institution_id,
            firstname : firstname,
            lastname : lastname,
            email : email,
            password : password,
            permission_group_id : permission_group_id
        })
    },

    async fetchInstitutionHolidays() {
        return await axios.get('/institution/holidays')
    },

    async getExamGroups() {
        return await axios.get('/institution/groups/exams')
    },

    async getInstitutionSettings() {
        return await axios.get('/institution/settings')
    },

    async getInstitutionPermissionGroups({ institution_id }) {
        return await axios.get(`/institution/${institution_id}/permissions/groups`)
    },

    async getInstitutionPermissions() {
        return await axios.get('/institution/permissions')
    },

    async getUserAssignmentPlan({ user_id, group_id }) {
        return await axios.get(`/assignments/group/${group_id}/assignment-plan/${user_id}`)
    },

    async logInAs({ user_id, log_back_to_previous=0 }) {
        return await axios.post('/users/log-in-as', {
            user_id : user_id,
            log_back_to_previous : log_back_to_previous
        })
    },

    async markStudentAsSelfStudy({ user_id, group_id }) {
        return await axios.put(`/user/selfstudy/${user_id}`, {
            group_id : group_id
        })
    },

    async removeInstitutionHoliday ({ schedule_id }) {
        return await axios.delete(`/institution/holiday/${schedule_id}`)
    },

    async saveInstitutionHoliday({ schedule_id, title, start, end }) {
        return await axios.put(`/institution/holiday/${schedule_id}`, {
            title : title,
            start : start,
            end : end
        })
    },

    async saveInstitutionPermissions({ permission_group_id, data }) {
        return await axios.put('/institution/permissions/group/'+permission_group_id, data)
    },

    async saveInstitutionSettings(settings) {
        return await axios.post('/institution/settings', settings)
    },

    async getInstitutionStatisticsAssignmentsBehind() {
        return await axios.get(`/statistics/institution/assignments-behind`)
    },

    async getInstitutionStatisticsMissingAssignments() {
        return await axios.get(`/statistics/institution/missing-assignments`)
    },

    async recalculateAssignmentsPlanForUser({ group_id, user_id }) {
        return await axios.post(`/institution/assignments/plan/user`, {
            group_id : group_id,
            user_id : user_id
        })
    },

    async recalculateAssignmentsPlansForGroup({ group_id }) {
        return await axios.post(`/institution/assignments/plans/group`, {
            group_id : group_id
        })
    },

    async saveMoodleRelation({ moodle_course_id, replacement_group_id, create_sub_group, is_main_group }) {
        return await axios.put(`/institution/moodle/relation/${moodle_course_id}`, {
            replacement_group_id : replacement_group_id,
            create_sub_group : create_sub_group,
            is_main_group : is_main_group
        })
    },

    async saveUMSRelation({ uni_group_id, replacement_group_id }) {
        return await axios.put(`/institution/ums/relation/${uni_group_id}`, {
            replacement_group_id : replacement_group_id
        })
    },

    async searchMoodleGroups({ search_query }) {
        return await axios.post('/institution/moodle/groups/search', {
            search_query : search_query
        })
    },

    async searchUMSGroups({ search_query }) {
        return await axios.post('/institution/ums/groups/search', {
            search_query : search_query
        })
    },

}
