<template>
    <v-app>
        <dialogue></dialogue>
        <heartbeat></heartbeat>
        <video-chat-notification></video-chat-notification>
        <navbar
            v-if="!isLoading"
            @logout="logout"
            @setLanguage="lang => setLanguage(lang)"
        ></navbar>
        <v-content>
            <loading-spinner
                v-if="isLoading"
                :offsetTop="250"
            ></loading-spinner>
            <div
                v-if="!isLoading"
                class="baseColor main-container pa-0 full-page-wrapper"
                >
                <v-slide-y-transition v-if="!isLoading" mode="out-in">
                    <router-view
                        v-if="!isLoading"
                    ></router-view>
                </v-slide-y-transition>
            </div>
            <vue-snotify></vue-snotify>
        </v-content>
    </v-app>
</template>

<script>

    import Vue from 'vue'
    import { mapGetters, mapState, mapActions } from 'vuex'

    import auth from '../src/auth'

    const heartbeat = () =>  import('@/components/misc/heartbeat')
    const dialogue = () =>  import('@/components/misc/dialogue')

    const loadingSpinner = () =>  import("@/components/misc/loadingSpinner")
    const navbarMain = () =>  import("@/components/navbar/navbarMain")
    const videoChatNotification = () => import('@/components/chat/videoChatNotification')

    export default {
        components: {
            'navbar' : navbarMain,
            'loading-spinner' : loadingSpinner,
            'heartbeat': heartbeat,
            'dialogue': dialogue,
            'video-chat-notification': videoChatNotification
        },
        data () {
            return {
                isLoading : false,
                timeSpentInAppDataCollectionInterval: null,
                timeSpentInAppTimestamp: 0,
                timeSpentInAppDataEntity: null,
                timeSpentInAppDataEntities: [],
                timeSpentInAppMaxDataEntities: 3,
                timeSpentInAppDataCollectionPeriod: 60000
            }
        },
        methods: {
            ...mapActions('userStore', [
                'pullLoggedInUserInfo',
                'clearLoggedInUserInfo'
            ]),
            ...mapActions('groupStore', [
                'setUserGroups',
                'setInstitutionData',
                'setInstitutionsSettings'
            ]),
            async logout() {
                this.isLoading = true
                await auth.logout()
                await this.clearLoggedInUserInfo()
                // do not use router, because we need to page refresh
                document.location.href = '/'
            },
            async pullAppInfo() {
                let error = false
                this.isLoading = true
                const response = await this.pullLoggedInUserInfo(this.userStore.user.token)
                .catch(err => {
                    error = true
                })

                if (error || !response.data || !response.data.user || !response.data.user.user_id) {
                    return this.logout()
                }

                await this.setUserGroups({
                    groups : response.data.groups
                })
                await this.setInstitutionsSettings({
                    settings : response.data.institution_settings
                })
                await this.setInstitutionData({
                    data : response.data.institution_data
                })
                this.setLanguage()

                this.setColors()

                this.isLoading = false
            },

            setColors() {

                if (this.groupStore.institution_data && this.groupStore.institution_data.primary_color && this.groupStore.institution_data.primary_color.trim().length) {
                    Vue.set(this.$vuetify.theme, 'primary', this.groupStore.institution_data.primary_color)
                }

                if (this.groupStore.institution_data && this.groupStore.institution_data.accent_color && this.groupStore.institution_data.accent_color.trim().length) {
                    Vue.set(this.$vuetify.theme, 'accent', this.groupStore.institution_data.accent_color)
                }

                if (this.groupStore.institution_data && this.groupStore.institution_data.primary_color && this.groupStore.institution_data.primary_complementary.trim().length) {
                    Vue.set(this.$vuetify.theme, 'primaryComplementary', this.groupStore.institution_data.primary_complementary)
                }

                if (this.groupStore.institution_data && this.groupStore.institution_data.accent_color && this.groupStore.institution_data.accent_complementary.trim().length) {
                    Vue.set(this.$vuetify.theme, 'accentComplementary', this.groupStore.institution_data.accent_complementary)
                }

            },

            setLanguage(lang = '') {
                if (lang.length) {
                    return Vue.set(this.$i18n, 'locale', lang)
                }
                // bind language to user language from store
                if (this.userStore.user_settings.language && this.userStore.user_settings.language.length) {
                    Vue.set(this.$i18n, 'locale', this.userStore.user_settings.language)
                } else if (this.groupStore.institution_settings['institution-settings-language'] && this.groupStore.institution_settings['institution-settings-language'].length) {
                    // if user pref not set, use institution default
                    Vue.set(this.$i18n, 'locale', this.groupStore.institution_settings['institution-settings-language'])
                } else {
                    //if all else fails - go to danish (will be english in the future)
                    Vue.set(this.$i18n, 'locale', 'da')
                }

            },

            startTimeSpentInAppDataCollection() {
                this.stopTimeSpentInAppDataCollection()

                this.timeSpentInAppDataEntity = this.$dataCollector.new('time-spent-in-app', {
                    userId: +this.userStore.user.user_id,
                    firstName: this.userStore.user.firstname || '',
                    lastName: this.userStore.user.lastname || '',
                    timeSpent: 0
                })
                this.timeSpentInAppTimestamp = Date.now();

                this.timeSpentInAppDataCollectionInterval = setInterval(() => {
                    if (this.timeSpentInAppDataEntities.length >= this.timeSpentInAppMaxDataEntities) {
                        this.$dataCollector.push(this.timeSpentInAppDataEntities)
                        this.timeSpentInAppDataEntities = []
                    }

                    this.timeSpentInAppDataEntity.set(
                        'timeSpent',
                        (Date.now() - this.timeSpentInAppTimestamp) / 1000
                    );
                    this.timeSpentInAppDataEntities.push(
                        this.timeSpentInAppDataEntity
                    )

                    this.timeSpentInAppDataEntity = this.$dataCollector.new('time-spent-in-app', {
                        userId: this.userStore.user.user_id,
                        firstName: this.userStore.user.firstname || '',
                        lastName: this.userStore.user.lastname || '',
                        timeSpent: 0
                    })
                    this.timeSpentInAppTimestamp = Date.now();
                }, this.timeSpentInAppDataCollectionPeriod)

                window.addEventListener('beforeunload', () => {
                    this.stopTimeSpentInAppDataCollection()
                })
            },

            stopTimeSpentInAppDataCollection() {
                clearInterval(this.timeSpentInAppDataCollectionInterval)
                if (this.timeSpentInAppDataEntity) {
                    this.timeSpentInAppDataEntity.set(
                        'timeSpent',
                        (Date.now() - this.timeSpentInAppTimestamp) / 1000
                    );
                    this.timeSpentInAppDataEntities.push(
                        this.timeSpentInAppDataEntity
                    )

                    this.timeSpentInAppDataEntity = null
                    this.timeSpentInAppTimestamp = 0
                }

                if (this.timeSpentInAppDataEntities.length > 0) {
                    this.$dataCollector.push(this.timeSpentInAppDataEntities)
                    this.timeSpentInAppDataEntities = []
                }
            }
        },
        computed : {
            ...mapState([
                'groupStore',
                'userStore'
            ]),
            isLoggedIn() {
                return this.userStore.isLoggedIn
            }
        },
        watch: {
            isLoggedIn(val, oldVal) {
                val === 1
                    ? this.startTimeSpentInAppDataCollection()
                    : this.stopTimeSpentInAppDataCollection()

                if (val === 1 && !this.isLoading) {
                    this.pullAppInfo()
                }
            }
        },
        created() {
            if (auth.checkAuth()) {
                this.pullAppInfo()
            }
        },
        mounted() {
            if (this.isLoggedIn === 1) {
                this.startTimeSpentInAppDataCollection()
            }
        }

    }
</script>

<style lang="stylus">
    @import './stylus/main.styl';
</style>

<style lang="stylus">

    body {
        --z-index-layer-max: 16777271;
        --z-index-layer-max-1: 16777270;
        --z-index-layer-min: 16777271;
    }

    .full-page-wrapper
        height:calc(100vh - 64px );
        overflow-y: auto;

    /*.full-page-wrapper::-webkit-scrollbar*/
    /*    display:none;*/

    .selected-chip {
        height: 32px !important;
        border: 2px solid var(--v-primary-base) !important;
    }

</style>

<style lang="css">

    /*
     *  IE FIX
    */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .container {
            max-width: 1300px !important;
        }
    }

    a {
        color: #333 !important;
        text-decoration: none;
    }

    .activity-sticky-items-td {
        position: sticky;
        left: 0;
        z-index: 2;
        background: white;
    }

    .badge-correct {
        display: block !important;
        position: static !important;
    }

    body .fc {
        overflow:auto;
    }

    .border--rounded {
        border-radius: 5px !important;
    }

    .button-icon {
        margin-left: 5px;
    }

    /*
     * CKEDITOR
    */
    .cke_dialog_ui_button_ok {
        background: var(--v-accent-base) !important;
        color: #fff !important;
        border: 1px solid var(--v-accent-base) !important;
    }

    .cke_dialog_ui_input_text:focus, input.cke_dialog_ui_input_password:focus, textarea.cke_dialog_ui_input_textarea:focus {
        border: none !important;
        border-bottom: 1px solid var(--v-accent-base) !important;
    }

    input.cke_dialog_ui_input_text:hover, input.cke_dialog_ui_input_password:hover, textarea.cke_dialog_ui_input_textarea:hover {
        border: none !important;
        border-bottom: 1px solid var(--v-accent-base) !important;
    }

    a.cke_dialog_tab:focus {
        border: 1px solid #d1d1d1 !important;
        border-bottom-color: #fff !important;
    }

    select.cke_dialog_ui_input_select:focus {
        border: 1px solid #bcbcbc !important;
    }

    /*
     *  CKEDITOR END
    */

    .v-chip.pointer > .v-chip__content {
        cursor: pointer!important;
    }

    .clearfix:before {
        clear: both;
    }

    .cloze-answer-div del {
        color: red !important;
    }

    .cloze-answer-div ins {
        color: green !important;
    }

    .correct-answers__answer del {
        color: #F44336 !important;
    }

    .correct-answers__answer ins {
        color: #4CAF50 !important;
    }

    .cloze-input {
        border: 1px solid #333 !important;
        padding: 5px;
    }

    .cloze-input--correct {
        border-radius: 6px;
        border-width: 5px !important;
        border-color: #4CAF50 !important;
    }

    .cloze-input--wrong {
        border-radius: 6px;
        border-width: 5px !important;
        border-color: #F44336 !important;
    }

    .expanded-stepper-2-step .v-timeline {
        padding-top: 0;
    }

    .expanded-stepper-2-step .v-timeline:before {
        height: 82% !important;
    }

    .expanded-stepper-3-step .v-timeline:before, .expanded-stepper-5-step .v-timeline:before {
        height: 97% !important;
    }

    .material-element-tile > .v-list__tile {
        height: 100% !important;
        min-height: 72px !important;
        padding: 16px 0 !important;
    }

    .material-element-tile:first-of-type .group-material-element-pipe {
        bottom: -1px;
        height: 50%;
        top: auto;
    }

    .material-element-tile:last-of-type .group-material-element-pipe {
        height: 50%;
    }

    .material-view__top-toolbar .v-toolbar__content {
        padding: 0 !important;
    }

    .v-toolbar--no-padding .v-toolbar__content {
        padding: 0 !important;
    }

    .default-cursor {
        cursor: default !important;
    }

    .dialog__content.dialog__content__active {
        z-index: 1001 !important;
    }

    .force-inline-flex, .force-inline-flex > div {
        display: inline-flex;
    }

    .fsp-picker__brand-container {
        display:none !important;
    }

    .full-height {
        height: 100% !important;
    }

    .full-width {
        width: 100% !important;
    }


    @media only screen and (max-width: 992px) {
        .full-width-mobile {
            width:100% !important;
        }
    }

    .greyed-out {
        color: rgba(0,0,0,0.40) !important;
    }

    .greyed-out-bg {
        background-color: rgba(0,0,0,0.01) !important;
    }


    .grey-thick-border {
        border: 2px solid rgba(0, 0, 0, 0.5) !important;
        border-radius: 5px !important;
    }

    .dark-greyed-out-bg {
        background-color: rgba(0,0,0,0.05) !important;
    }

    .half-hr-element {
        width: 130px;
        margin-left: 10px;
        margin-right: 10px;
        border: 0.2px solid grey;

    }

    .header-app-icon .v-badge--overlap .v-badge__badge {
        top: -3px !important;
    }

    .high-tile > .v-list__tile {
        height: 60px;
    }

    .hover-link:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    /* Remove outermost scrollbar that would sometimes come forward */
    html::-webkit-scrollbar {
        display: none;
    }

    img.circle {
        border-radius: 50%;
    }


    #intercom-container .intercom-home-screen .intercom-link {
        opacity: 0 !important;
    }

    .list-icon {
        font-size: 20px;
    }

    .list-tile__active {
      background: rgba(0,0,0,0.04);
    }

    .v-list__group__header__prepend-icon {
        color: #333 !important;
    }

    .menuable__content__active {
        /* z-index: 1003 !important; */
    }

    /* FIREFOX HACKS */
    @-moz-document url-prefix() {

        .badge-correct .badge__badge > span {
            margin-top: -6px;
        }


        #navbar-logged-in .v-btn__content .v-badge__badge span {
            margin-top: -17px;
        }

        .awareness-card-avatar {
            max-width: 100%;
            height: 200px;
        }

        /* Error in firefox with froala audio element */
        div[fr-original-class="fr-audio-click-catcher"] {
            display: none !important;
        }

    }

    /* EDGE HACKS */
    @supports (-ms-ime-align:auto) {

    }

    .modal-side-button .v-btn {
        height: 100% !important;
    }

    .moveable {
        cursor: move
    }

    .multiple-choice-content p {
        margin-bottom: 0;
    }

    .multiple-choice__answer-checkbox .v-input--selection-controls__input {
        margin-right: 0 !important;
    }

    .main-navigation-drawer {
        z-index: 100 !important;
    }

    /* Used in draggable components to enable empty list and easy the dropping */
    .mr-draggable {
        min-height: 20px !important;
        width: 100%;
    }

    /*.main-navigation-drawer > .list .list__tile:hover {*/
        /*background: rgba(0,0,0,0.12) !important;*/
    /*}*/

    nav .brand-logo {
        position: absolute;
        color: #fff;
        display: inline-block;
        font-size: 2.1rem;
        padding: 0;
        white-space: nowrap;
    }

    .no-border {
        border: none
    }

    .no-hover {
        pointer-events: none;
    }

    .no-top-margin {
        margin-top: 0 !important;
    }

    .no-bottom-margin {
        margin-bottom: 0 !important;
    }

    .object-icon-status.assignment-node {
        background-image: url('https://images.edaptio.com/material-element-icons/edaptio_material_icon-assignment_greenyellow.svg?v=1');
    }

    .object-icon-status.file-node {
        background-image: url('https://images.edaptio.com/material-element-icons/edaptio_material_icon-file_greenyellow.svg');
    }

    .object-icon-status.flashcards-node {
        background-image: url('https://images.edaptio.com/material-element-icons/edaptio_material_icon-flashcards_greenyellow.svg');
    }

    .object-icon-status.interactive-node {
        background-image: url('https://images.edaptio.com/material-element-icons/edaptio_material_icon-interactive_greenyellow.svg');
    }

    .object-icon-status.link-node {
        background-image: url('https://images.edaptio.com/material-element-icons/edaptio_material_icon-link_greenyellow.svg');
    }

    .object-icon-status.questionnaire-node {
        background-image: url('https://images.edaptio.com/material-element-icons/edaptio_material_icon-questionnaire_greenyellow.svg');
    }

    .object-icon-status.quiz-node {
        background-image: url('https://images.edaptio.com/material-element-icons/edaptio_material_icon-quiz_greenyellow.svg');
    }

    .object-icon-status.text-node {
        background-image: url('https://images.edaptio.com/material-element-icons/edaptio_material_icon-book_open_greenyellow.svg?v=1');
    }

    .object-icon-status.timeline-node {
        background-image: url('https://images.edaptio.com/material-element-icons/edaptio_material_icon-timeline_greenyellow.svg');
    }

    .object-icon-status.video-node {
        background-image: url('https://images.edaptio.com/material-element-icons/edaptio_material_icon-video_greenyellow.svg');
    }

    .object-icon-status.board-node {
        background-image: url('https://images.edaptio.com/material-element-icons/edaptio_material_icon-wall_greenyellow.svg');
    }

    .pa0-toolbar > .v-toolbar__content {
        padding: 0 !important;
    }

    .pointer {
        cursor: pointer
    }

    .post-content p {
        margin-bottom: 2px;
    }

    /*
     * <RAW TEXT ELEMENT STYLES>
    */

    .raw-text-element h1 {
        font-size: 2.5em !important;
    }

    .raw-text-element h2 {
        font-size: 2em !important;
    }

    .raw-text-element h3 {
        font-size: 1.67em !important;
    }

    .raw-text-element h4 {
        font-size: 1.5em !important;
    }

    .raw-text-element p {
        margin-bottom: 10px !important;
    }

    .raw-text-element table {
        margin-bottom: 20px;
        border-collapse: collapse;
        max-width: 100%;
        display: block;
    }

    .raw-text-element table tr {
        border-bottom: 1px solid #333 !important;
    }

    .raw-text-element table tr th, .raw-text-element table tr td {
        border-left: 1px solid #333 !important;
        padding: 5px !important;
    }

    .raw-text-element table tr th:last-of-type, .raw-text-element table tr td:last-of-type {
        border-right: 1px solid #333 !important;
    }

    .raw-text-element table tr:first-of-type {
        border-top: 1px solid #333 !important;
    }

    .raw-text-element ul, .raw-text-element ol {
        padding-left: 40px !important;
        margin-bottom: 20px !important;
    }

    .raw-text-element ul li {
        list-style-type: circle !important;
    }

    .raw-text-element hr {
        color: #333;
        opacity: 0.5;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .raw-text-element a {
        font-weight: bold !important;
        color: #0336FF !important;
    }

    .raw-text-element a:hover {
        color: #616161 !important;
        text-decoration: underline !important;
    }

    .raw-text-element blockquote {
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 10px 10px 10px 20px;
        border-left: solid 5px var(--v-primary-base);
    }

    .raw-text-element .edaptio-media-video-element {
        max-height: 500px;
    }

    .raw-text-element table.clean-table tr, .raw-text-element table.clean-table td {
        border: none !important;
    }

    .raw-text-element img, .raw-text-element iframe {
        max-width: 100% !important;
    }

    /*
     * </RAW TEXT ELEMENT STYLES>
    */

    .sheet-corners {
        border-radius: 2px;
    }

    .small-font-textfield .v-label, .small-font-textfield input{
        font-size: 14px !important;
    }

    .small-table th, .small-table td {
        padding: 0 5px !important;
    }

    .tab--active {
        opacity: 1 !important;
        background-color: var(--v-accent-base) !important;
        color: #FFFFFF !important;
    }

    /*
        ---------------- start ----------------
        LIST GROUP OVERRIDES FOR TREE-LIKE VIEW
     */

    /* Remove border lines */
    #tree-list .v-list__group:before,
    #tree-list .v-list__group:after {
      height: 0 !important;
    }

    #tree-list .v-list__tile--link:hover {
      background: none !important;
    }

    #tree-list .v-list__group__header--active .v-list__group__header__prepend-icon .v-icon {
      transform: rotate(45deg) !important;
    }

    #tree-list .loading > .v-list__group__header .v-list__group__header__prepend-icon .v-icon {
      animation: spin 2s linear infinite !important;
    }

    #tree-list .v-list__group__header__prepend-icon {
      padding: 0 !important;
      min-width: 0 !important;
      margin-right: 4px !important;
      margin-left: 4px !important;
    }

    #tree-list .v-list__group__items--no-action .v-list__tile {
      padding-left: 56px !important;
    }

    #tree-list .v-list__tile {
      padding: 0 4px 0 0 !important;
    }

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }

    /*
        LIST GROUP OVERRIDES FOR TREE-LIKE VIEW
        ----------------- end -----------------
     */

    .text-truncate {
        /* FIX: Default line-height 1 causing titles to be cropped */
        line-height: 1.25 !important;
    }

    .theme--light.v-icon {
        color: rgba(0,0,0,1);
    }

    .video-js {
        width: 100% !important;
        min-height: 100px!important;
    }

    .v-btn.accent > .v-btn__content, .v-btn.accent > .v-btn__content > i {
        color: var(--v-accentComplementary-base) !important;
    }

    .v-icon, .theme--light.v-icon {
        color: #333;
    }

    .v--modal-overlay {
        cursor: pointer;
    }

    .v--modal {
        cursor: default;
    }

    .v-date-picker-table {
        height:auto !important;
    }

    .v-sheet, .sheet-corners {
        border-radius: 5px;
    }

    .v-sheet-no-corners {
        border-radius: 0px !important;
    }

    @media only screen and (max-width: 960px) {
        .expanded-stepper .v-timeline--dense .v-timeline-item__body {
            max-width: calc(100% - 45px) !important;
        }
    }

    .v-toolbar, .no-box-shadow {
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
    }

    .VueCarousel-navigation-prev, .VueCarousel-navigation-next {
        padding: 0!important;
        font-size: 20px;
    }

    .VueCarousel-navigation--disabled {
        display: none;
    }

    .VueCarousel-dot {
        margin-top: 0 !important;
    }


    .wrs_linksContainer {
        display: none !important;
    }

    .wirisinputcontrolslist input[type="radio"], .wirissyntaxvalues input {
        position: static !important;
        opacity: 1 !important;
        margin-right: 10px !important;
    }

    .wirisinputcontrolslist label, .wirissyntaxlabel label {
        cursor: pointer !important;
    }

    .wirissyntaxchar {
        margin-right: 20px !important;
    }

    .wirissyntaxvalues select {
        display: block !important;
    }


    input.wirisembeddedmathinput,
    input.wirisembeddedauthoringfield {
        height: 16px !important;
        /*
        Set dinamically.
        width: 100px;
        background-position: 105px 3px;*/
        background-position-x: 83px !important;
    }

    img.wirisembeddedmathinput {
        background-position-x: 103px !important;
    }


</style>
