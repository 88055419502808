import axios from 'axios'

export default {

    async createDocumentExport(data) {
        return await axios.post('/media/directedit', data)
    },

    async getCourseVideos(group_id) {
        return await axios.get(`/media/videos/courses/${group_id}`)
    },

    async getDirectEditLink(media_id) {
        return await axios.get(`/media/directedit/${media_id}`)
    },

    async loadVideoAudioProgression({ media_id }) {
        return await axios.get(`/media/progression/${media_id}`)
    },

    async loadVimeoVideoProgression({ video_id }) {
        return await axios.get(`/media/vimeo/progression/${video_id}`)
    },

    async loadYoutubeVideoProgression({ video_id }) {
        return await axios.get(`/media/youtube/progression/${video_id}`)
    },

    async moveFileToFolder({ folder_id, file_id }) {
        return await axios.put(`/media/move/${file_id}`, {
            folder_id : folder_id
        })
    },

    /**
     * @desc Remove file
     * @param {number} media_id 
     * @param {number} material_element_type - Material element adaptive_object_type_id
     */
    async removeFile(media_id, material_element_type = 0) {
        return await axios.delete(`/media/${media_id}/${material_element_type}`)
    },

    async saveMedia({ media_id, data }) {
        return await axios.put(`/media/${media_id}`, data)
    },

    async saveVideoAudioProgression({ media_id, latest_watch_time, duration, max_watch_time, total_watch_time }) {
        return await axios.put(`/media/progression/${media_id}`, {
            duration : duration,
            max_watch_time : max_watch_time,
            latest_watch_time : latest_watch_time,
            total_watch_time : total_watch_time
        })
    },

    async saveVimeoVideoProgression({ video_id, latest_watch_time, duration, max_watch_time, total_watch_time }) {
        return await axios.put(`/media/vimeo/progression/${video_id}`, {
            duration : duration,
            max_watch_time : max_watch_time,
            latest_watch_time : latest_watch_time,
            total_watch_time : total_watch_time
        })
    },

    async saveYoutubeVideoProgression({ video_id, latest_watch_time, duration, max_watch_time, total_watch_time }) {
        return await axios.put(`/media/youtube/progression/${video_id}`, {
            duration : duration,
            max_watch_time : max_watch_time,
            latest_watch_time : latest_watch_time,
            total_watch_time : total_watch_time
        })
    },

    async storeNewFile(file) {
        return await axios.post('/media', file)
    }


}
